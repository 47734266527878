import { useGetUserTypeListQuery } from "../../redux/UserTypesStore/userTypesStoreAPI";
import { GetUserTypeDataReqObj, UsertypeOpt } from "../../types/user";

type RetVal = {
  arr: UsertypeOpt[];
  isLoading: boolean;
};

const useUsertypeOptions = (
  arg?: GetUserTypeDataReqObj,
  opt?: { skip?: boolean }
): RetVal => {
  const { data, isLoading, isFetching } = useGetUserTypeListQuery(arg || {}, {
    skip: opt?.skip,
  });

  return {
    arr:
      data?.map((val) => {
        return {
          label: val.name,
          value: val.id,
          ...val,
        };
      }) || [],
    isLoading: isLoading || isFetching,
  };
};

export default useUsertypeOptions;
