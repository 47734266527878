import { ButtonBase, makeStyles } from "@material-ui/core";
import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { createUseStyles } from "react-jss";
import { Card, CardBody } from "reactstrap";
import { COLORS } from "../../../assets/theme";
import useTagButtonStyles from "../../../components/atoms/TagButton/useTagButtonStyles";
import useChannelColorExtractor from "../../../hooks/channels/useChannelColorExtractor";
import { AChannelWithColor } from "../../../types/channel";
import { AUserData, KnownUsertype } from "../../../types/user";

type Props = {
  user: AUserData;
  onClick?: () => void;
};

const customCardStyle = makeStyles({
  root: {
    width: "100%",
    overflow: "hidden",
    border: `1px solid ${COLORS.gradientGreen_4}`,
    borderRadius: "8px",
    display: "block",
    marginBottom: 16,
    padding: 8,
    background: COLORS.gradientGreen_4,
    "&.inactive": {
      background: COLORS.white,
      border: `1px solid ${COLORS.gray_1}`,
    },
  },
  cardContainer: {
    border: "none",
  },
  cardBodyContainer: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    paddingRight: "20px",
    paddingLeft: "20px",
    textAlign: "left",
    background: COLORS.gradientGreen_4,
    "&.inactive": {
      background: COLORS.white,
    },
  },
});

const useUserCardStyles = createUseStyles({
  textTitle: {
    color: COLORS.black_1,
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: 16,
  },
  editButton: {
    backgroundColor: "transparent",
    border: 0,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    color: COLORS.blue_1,
    fontFamily: "Raleway",
    fontSize: "13px",
    fontWeight: 600,
  },
  carouselContainer: {
    display: "flex",
    overflowX: "auto",
    overflowY: "hidden",
    height: "10vh",
    paddingBottom: "5vh",
    alignItems: "center",
  },
  chipsContainer: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    height: 28,
    marginRight: "1rem",
    borderRadius: "15px",
    fontFamily: "Raleway",
    fontSize: "8px",
    "&.pick": {
      backgroundColor: COLORS.gradientBlue_1,
      border: 0,
      color: "white",
    },
    "&.unpick": {
      backgroundColor: "transparent",
      border: 1,
      borderStyle: "solid",
      borderColor: COLORS.gray,
      color: "black",
    },
  },
  textFormTitle: {
    fontFamily: "Raleway",
    fontSize: "12px",
  },
  cardContainer: {
    display: "flex",
    background: ({ active }: { active: boolean }) =>
      active === false ? COLORS.gradientGray_1 : COLORS.gradientGreen_4,
    borderColor: ({ active }: { active: boolean }) =>
      active === false ? COLORS.gradientGray_1 : COLORS.green_1,
    borderRadius: "8px",
    marginBottom: "16px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  cardBodyContainer: {},
});

const UserCard: React.FunctionComponent<Props> = ({ user, onClick }) => {
  const findChannelWithId = useChannelColorExtractor();
  const usertype = user.usertype.name as KnownUsertype;
  const classes = useUserCardStyles({ active: user.active });
  const tagClasses = useTagButtonStyles();
  const cardClasses = customCardStyle();

  let userTitle = "";
  switch (usertype) {
    case "approver":
      userTitle = "Approver";
      break;
    case "officer":
      userTitle = "Officer";
      break;
    case "user":
      userTitle = `${user.vendor?.name}`;
      break;
    case "superadmin":
      userTitle = "Admin";
      break;
    case "securityWarehouse":
      userTitle = "Security Warehouse";
      break;
    case "securityArea":
      userTitle = "Security Area";
      break;
    default:
      userTitle = usertype;
      break;
  }

  const channelList = React.useMemo<AChannelWithColor[]>(() => {
    let list: AChannelWithColor[] = [];
    switch (usertype) {
      case "approver":
        let approverRelationChannel = user.relationapproverchannel;
        if (!approverRelationChannel) {
          return [];
        }
        approverRelationChannel.forEach(({ channel }) => {
          if (channel) {
            let { color } = findChannelWithId(channel.id) ?? { color: "wheat" };

            list.push({
              ...channel,
              color,
            });
          }
        });
        return list;
      case "user":
        let channel = user.channel;
        if (!channel) {
          return [];
        }
        let { color } = findChannelWithId(channel.id) || { color: "wheat" };
        list.push({
          ...channel,
          color,
        });
        return list;

      default:
        return [];
    }
  }, [findChannelWithId, user, usertype]);

  return (
    <ButtonBase
      onClick={onClick}
      className={[cardClasses.root, !user.active ? "inactive" : ""].join(" ")}
    >
      <Card className={cardClasses.cardContainer}>
        <CardBody
          className={[
            cardClasses.cardBodyContainer,
            !user.active ? "inactive" : "",
          ].join(" ")}
        >
          <div style={{ flex: 1 }}>
            <p
              style={{
                fontSize: "1.3rem",
                fontWeight: 700,
                color: user.active === false ? COLORS.gray_2 : COLORS.black_1,
              }}
            >
              {userTitle}
            </p>
            <p
              style={{
                fontSize: "1.2rem",
                fontWeight: 600,
                color: user.active === false ? COLORS.gray_2 : COLORS.black_1,
              }}
            >
              Username: {user.username}
            </p>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                flexWrap: "wrap",
                gap: "0.4rem",
              }}
            >
              {channelList.map((channel) => {
                return (
                  <button
                    key={channel.id}
                    className={tagClasses.tag}
                    style={{
                      background:
                        user.active === false ? COLORS.gray_1 : channel.color,
                      color:
                        user.active === false ? COLORS.white : COLORS.white,
                    }}
                  >
                    {channel.description}
                  </button>
                );
              })}
            </div>
          </div>
          <button
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexBasis: "30px",
              backgroundColor: "transparent",
              border: 0,
            }}
          >
            <IoIosArrowForward size={12} />
          </button>
        </CardBody>
      </Card>
    </ButtonBase>
  );
};

export default UserCard;
