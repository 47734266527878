import { format, parse } from "date-fns";
import React, {
  ChangeEvent,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import id from "date-fns/locale/id";
import { createUseStyles } from "react-jss";
import { FormGroup, Input, Label, ModalBody, ModalHeader } from "reactstrap";
import { COLORS } from "../../../assets/theme";
import useMaterialOptions from "../../../hooks/materials/useMaterialOptions";
import useButtonStyles from "../../../hooks/styles/useButtonStyles";
import useModalStyles from "../../../hooks/styles/useModalStyles";
import {
  useAddPhotoReservationMutation,
  useUpdateReservationMutation,
} from "../../../redux/ReservationStore/reservationstoreAPI";
import { useGetAvailableQuotaQuery } from "../../../redux/ScheduleStore/schedulestoreAPI";
import { normalDateFormat, shortDateFormat } from "../../../types/dateformat";
import { AMaterialOpt } from "../../../types/material";
import { AReservation } from "../../../types/reservation";
import { AScheduleOpt } from "../../../types/schedule";
import PureInputSelect from "../PureInputSelect";
import CustomModal from "./CustomModal";
import * as yup from "yup";
import { toast } from "react-toastify";
import useSpecificChannel from "../../../hooks/channels/useSpecificChannel";
import useSchemaUpdateReservation from "../../../hooks/channels/useSchemaUpdateReservation";
import { ErrorMessageBackendDataShape } from "../../../types";
import ImageViewer from "../FileInput/ImageViewer";
import { BiPlus } from "react-icons/bi";
import useSchemaManagePhotoReservation from "../../../hooks/channels/useSchemaManagePhotoReservation";
import { AVendorOpt } from "../../../types/vendor";
import { SingleValue } from "react-select";
import { useGetVendorsQuery } from "../../../redux/VendorStore/vendorstoreAPI";
import useUsertype from "../../../hooks/auth/useUsertype";
import CustomDatePicker from "../../inputs/CustomDatePicker";
import {
  replaceInputTextContainer,
  replaceInputTextLicensePlate,
  replaceInputTextToNumberOnly,
} from "../../../tools";
import ReactSimpleImageViewer from "react-simple-image-viewer";

interface Props {
  ticket: AReservation;
  isOpen: boolean;
  toggle: () => void;
  onSuccess?: () => void;
}

const useStyles = createUseStyles({
  datePickerInput: {
    width: "100%",
    padding: "1.1rem",
    borderRadius: "0.4rem",
    border: "0.5px solid #C4CDE0",
    "&:hover": {
      borderColor: "hsl(0, 0%, 70%)",
    },
  },
  formInput: {
    padding: "1.1rem",
    borderRadius: "0.4rem",
    border: "0.5px solid #C4CDE0",
  },
  textFormTitle: {
    fontFamily: "Raleway",
    fontSize: 12,
    position: "relative",
    fontWeight: 600,
  },
  mandatoryStar: {
    color: "red",
    fontSize: 24,
    verticalAlign: "middle",
    height: 0,
    position: "absolute",
    top: -8,
    margin: 0,
  },
  uploadPhotosButton: {
    padding: "1.2rem 1.8rem",
    background: "transparent",
    border: `1px solid ${COLORS.black_1}`,
    borderRadius: "0.65rem",
    transition: "all .2s",
    "&:hover": {
      background: COLORS.black_1,
      color: COLORS.white,
    },
  },
});

const EditReservationModal: React.FunctionComponent<Props> = ({
  ticket,
  isOpen,
  toggle,
  onSuccess,
}) => {
  const modal_classes = useModalStyles();
  const btn_classes = useButtonStyles();
  const classes = useStyles();
  const uploadPhotosRef = useRef<HTMLInputElement>(null);
  const btn = useButtonStyles();
  const usertype = useUsertype();
  const [showDateModal, setShowDateModal] = useState<boolean>(false);
  const { arr: materialOpts, isLoading: isLoadingMaterials } =
    useMaterialOptions({ args: { channel: ticket.channel.channel } });
  const [isEditPhoto, setIsEditPhoto] = useState<boolean>(false);
  const { schema: validationSchema, isLoading: isLoadingSchema } =
    useSchemaUpdateReservation(ticket.channelId);

  const { schema: uploadPhotoValidationSchema } =
    useSchemaManagePhotoReservation(ticket.channelId, ticket.photo);

  const { channel: ticketChannel } = useSpecificChannel(ticket.channel.id);
  const [selectedVendor, setSelectedVendor] =
    React.useState<SingleValue<AVendorOpt> | null>(
      ticket.vendor
        ? {
            value: ticket.vendor?.vendcode ?? 0,
            label: ticket.vendor?.name ?? "",
            ...ticket.vendor,
          }
        : null
    );
  const [selectedDate, setSelectedDate] = React.useState<Date>(
    new Date(ticket.date)
  );
  const [selectedSchedule, setSelectedSchedule] =
    React.useState<AScheduleOpt | null>();

  const [selectedNopol, setSelectedNopol] = React.useState<string>(
    ticket?.nopol
  );
  const [selectedDriverName, setSelectedDriverName] = React.useState<string>(
    ticket.drivername
  );
  /**
   * @todo tambahkan default value
   */
  const [selectedDriverNumber, setSelectedDriverNumber] =
    React.useState<string>(ticket.driverPhoneNumber ?? "");
  const [selectedPO, setSelectedPO] = React.useState<string>(
    ticket.ponum?.toString() ?? ""
  );
  const [selectedPOLine, setSelectedPOLine] = React.useState<string>(
    ticket.poline?.toString() ?? ""
  );
  const [selectedDeliveryNote, setSelectedDeliveryNote] =
    React.useState<string>(ticket.deliveryNote ?? "");

  const [selectedShipmentNo, setSelectedShipmentNo] = React.useState<string>(
    ticket.shipmentNumber ?? ""
  );
  const [selectedContainer, setSelectedContainer] = React.useState<string>(
    ticket.container?.container ?? ""
  );
  const [showImagesCarousel, setShowImagesCarousel] =
    React.useState<boolean>(false);
  const [currentImage, setCurrentImage] = React.useState<number>(0);
  const {
    data: vendors,
    isFetching: isFetchingVendors,
    isLoading: isLoadingVendors,
  } = useGetVendorsQuery({});

  const vendorOpts = React.useMemo<AVendorOpt[]>(() => {
    if (!vendors || vendors.length < 1) {
      return [];
    }
    return vendors.map((val) => {
      return {
        ...val,
        label: val.name,
        value: val.vendcode,
      };
    });
  }, [vendors]);
  const [selectedMaterial, setSelectedMaterial] = React.useState<Omit<
    AMaterialOpt,
    "channelDetail"
  > | null>(
    ticket.material
      ? {
          value: ticket.material.id,
          label: ticket.material.description,
          ...ticket.material,
        }
      : null
  );
  const [photos, setPhotos] = useState<File[]>([]);
  const handleChangeImage = (event: ChangeEvent<HTMLInputElement>) => {
    let { files } = event.target;
    if (files) {
      setIsEditPhoto(true);
      let fileArr: File[] = [];
      for (let i = 0; i < files.length; i++) {
        const el = files[i];
        if (el.size < 1024000) {
          fileArr.push(el);
        } else {
          toast.error("Gagal Unggah Gambar! Ukuran file lebih dari 1024 kb.");
        }
      }
      setPhotos((prev) => {
        let newarr = prev.concat(fileArr);
        return newarr;
      });
    }
  };

  useEffect(() => {
    if (isOpen === false && photos.length > 0) {
      setPhotos([]);
    }
  }, [photos, isOpen]);

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const {
    data: quota,
    isLoading: isLoadingQuota,
    isFetching: isFetchingQuota,
  } = useGetAvailableQuotaQuery({
    date: format(selectedDate, shortDateFormat),
    channelId: ticket.channelId,
  });

  const quotaOpts = React.useMemo<AScheduleOpt[]>(() => {
    return (
      quota?.map((val) => {
        let maxDate = parse(val.from, "HH:mm:ss", selectedDate);
        let isPassedDate = new Date().getTime() > maxDate.getTime();

        return {
          value: val.id,
          label: `${val.from} - ${val.to}`,
          isDisabled: val.quota === 0 || isPassedDate,
          ...val,
        };
      }) ?? []
    );
  }, [quota, selectedDate]);

  const [updateReservation] = useUpdateReservationMutation();
  const [addPhotoReservation] = useAddPhotoReservationMutation();
  const onSubmitEdit: React.FormEventHandler<HTMLFormElement> =
    React.useCallback(
      (e) => {
        e.preventDefault();
        const fields = validationSchema.fields;
        let data: {
          [key in keyof typeof fields]?: any;
        } = {
          scheduleId: selectedSchedule?.id,
          channelId: ticketChannel?.id,
          date: format(selectedDate, shortDateFormat),
          nopol: selectedNopol,
        };
        ticketChannel?.statusVendor.active &&
          Object.assign(data, { vendcode: selectedVendor?.value });
        ticketChannel?.statusDriverName.active &&
          Object.assign(data, { drivername: selectedDriverName });
        ticketChannel?.statusDriverPhoneNumber?.active &&
          Object.assign(data, { driverPhoneNumber: selectedDriverNumber });
        ticketChannel?.statusMaterial.active &&
          Object.assign(data, { materialId: selectedMaterial?.value });
        ticketChannel?.statusContainer.active &&
          Object.assign(data, { container: selectedContainer });
        ticketChannel?.statusDeliveryNote.active &&
          Object.assign(data, { deliveryNote: selectedDeliveryNote });
        ticketChannel?.statusPo.active &&
          Object.assign(data, {
            ponum: selectedPO,
            poline: Number(selectedPOLine),
          });
        ticketChannel?.statusPhoto.active &&
          Object.assign(data, {
            images: isEditPhoto ? photos : ticket.photo,
          }); // for validation only.
        ticketChannel?.statusShipmentNumber.active &&
          Object.assign(data, { shipmentNumber: selectedShipmentNo });
        validationSchema
          .validate(data)
          .then((val) => {
            setIsSubmitting(true);
            delete val.images; // images will be uploaded in different endpoint
            updateReservation({ ...val, id: ticket.id })
              .unwrap()
              .then(async () => {
                if (isEditPhoto) {
                  const formData = new FormData();
                  for (let i = 0; i < photos.length; i++) {
                    formData.append("images[]", photos![i]);
                  }
                  await addPhotoReservation({
                    id: ticket.id,
                    images: formData,
                  })
                    .unwrap()
                    .then(() => {
                      if (photos.length > 0) {
                        toast.success("Berhasil mengubah gambar.");
                      }
                      setPhotos([]);
                      setIsEditPhoto(false);
                    })
                    .catch((e: ErrorMessageBackendDataShape) => {
                      toast.error(
                        "Gagal mengubah gambar reservasi. " + e.data.message
                      );
                      setIsEditPhoto(false);
                    });
                }
                toast.success("Berhasil mengubah reservasi!");
                toggle();
                setTimeout(() => {
                  onSuccess && onSuccess();
                }, 300);
                setIsSubmitting(false);
              })
              .catch((e: ErrorMessageBackendDataShape) => {
                setIsSubmitting(false);
                setIsEditPhoto(false);
                toast.error(`Gagal mengubah reservasi! ${e.data.message}`);
              });
          })
          .catch((e: yup.ValidationError) => {
            toast.error(`Gagal mengubah reservasi! ${e.errors.join(", ")}`);
            setIsEditPhoto(false);
          });
      },
      [
        validationSchema,
        selectedNopol,
        selectedPO,
        selectedDate,
        selectedSchedule,
        selectedDriverName,
        selectedMaterial,
        ticket.id,
        onSuccess,
        toggle,
        ticketChannel,
        updateReservation,
        photos,
        selectedVendor,
        selectedDriverNumber,
      ]
    );

  return (
    <CustomModal
      className={modal_classes.modal}
      isOpen={isOpen}
      toggle={toggle}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {showImagesCarousel && (
        <ReactSimpleImageViewer
          src={ticket.photo.map(
            (item) => `${process.env.REACT_APP_BASIC_URL}${item}`
          )}
          currentIndex={currentImage}
          onClose={() => setShowImagesCarousel(false)}
          disableScroll
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside
        />
      )}
      <ModalHeader className={modal_classes.modalHeader} toggle={toggle}>
        UBAH RESERVASI
      </ModalHeader>
      <div style={{ marginTop: 16, marginLeft: 8, marginBottom: 8 }}>
        <span style={{ color: "red", position: "relative", left: 12 }}>
          <span
            style={{
              fontSize: 24,
              position: "absolute",
              top: -6,
              left: -12,
            }}
          >
            *
          </span>{" "}
          Wajib Diisi
        </span>
      </div>
      <ModalBody>
        <form
          id="editreservation"
          style={{ display: "flex", flexDirection: "column", gap: "1.6rem" }}
          onSubmit={onSubmitEdit}
          encType="multipart/form-data"
        >
          <FormGroup>
            <Label className={classes.textFormTitle}>Tanggal</Label>
            <CustomDatePicker
              setSelectedDate={setSelectedDate}
              setShowDateModal={setShowDateModal}
              selectedDate={selectedDate}
              showDateModal={showDateModal}
              disablePast
              handleChange={() => setSelectedSchedule(null)}
              locale={id}
            />
            <Input
              id="date"
              className={classes.formInput}
              name="date"
              disabled={isSubmitting}
              type="text"
              placeholder="Pilih Tanggal"
              value={format(selectedDate, normalDateFormat, {
                locale: id,
              }).toString()}
              onClick={() => setShowDateModal(!showDateModal)}
            />
          </FormGroup>
          <FormGroup>
            <Label className={classes.textFormTitle}>Pukul</Label>
            <PureInputSelect
              options={quotaOpts}
              value={selectedSchedule}
              isClearable={false}
              isDisabled={isSubmitting}
              onChange={(val) => {
                setSelectedSchedule(val);
              }}
              isLoading={isLoadingQuota || isFetchingQuota}
              styles={{
                control: (prev, { isDisabled }) => {
                  return {
                    ...prev,
                    backgroundColor: isDisabled
                      ? "#F2F2F2"
                      : "rgba(255, 255, 255, 1)",
                    borderRadius: "4px",
                    border: "0.5px solid #C4CDE0",
                    // backdropFilter: "blur(10)",
                  };
                },
                option: (prev, { isDisabled }) => {
                  return {
                    ...prev,
                    cursor: isDisabled ? "not-allowed" : "pointer",
                    backgroundColor: "white",
                    color: isDisabled ? "#687484" : "#000000",
                    "&:hover": {
                      backgroundColor: !isDisabled
                        ? COLORS.blue_1
                        : COLORS.light_gray_1,
                      color: !isDisabled ? "white" : "#687484",
                    },
                  };
                },
              }}
            />
          </FormGroup>
          {ticketChannel?.statusVendor.active && (
            <FormGroup>
              <Label className={classes.textFormTitle}>
                Vendor{" "}
                {!ticketChannel?.statusVendor.optional && (
                  <span className={classes.mandatoryStar}>*</span>
                )}
              </Label>
              <PureInputSelect
                isDisabled={isSubmitting || usertype === "user"}
                isLoading={isLoadingVendors || isFetchingVendors}
                options={vendorOpts}
                placeholder="Pilih Vendor"
                isSearchable
                isClearable
                isLargeDataList
                value={selectedVendor}
                onChange={(val) => {
                  setSelectedVendor(val);
                }}
              />
            </FormGroup>
          )}
          <FormGroup>
            <Label className={classes.textFormTitle}>
              No. Kendaraan <span className={classes.mandatoryStar}>*</span>
            </Label>
            <Input
              id="nopol"
              className={classes.formInput}
              name="nopol"
              disabled={isSubmitting}
              required
              placeholder="No. Kendaraan"
              value={selectedNopol}
              onChange={(e) => {
                setSelectedNopol(e.target.value);
              }}
              onInput={replaceInputTextLicensePlate}
            />
          </FormGroup>
          {ticketChannel?.statusDriverName.active && (
            <FormGroup>
              <Label className={classes.textFormTitle}>
                Pengemudi{" "}
                {!ticketChannel?.statusDriverName.optional && (
                  <span className={classes.mandatoryStar}>*</span>
                )}
              </Label>
              <Input
                id="driver"
                className={classes.formInput}
                name="driver"
                disabled={isSubmitting}
                required={!ticketChannel?.statusDriverName.optional}
                placeholder="Nama Pengemudi"
                value={selectedDriverName}
                onChange={(e) => {
                  setSelectedDriverName(e.target.value);
                }}
              />
            </FormGroup>
          )}
          {ticketChannel?.statusDriverPhoneNumber?.active && (
            <FormGroup>
              <Label className={classes.textFormTitle}>
                Nomor Pengemudi{" "}
                {!ticketChannel?.statusDriverPhoneNumber.optional && (
                  <span className={classes.mandatoryStar}>*</span>
                )}
              </Label>
              <Input
                id="driver_no"
                className={classes.formInput}
                name="driver number"
                disabled={isSubmitting}
                required={!ticketChannel?.statusDriverPhoneNumber.optional}
                placeholder="Nomor Pengemudi"
                value={selectedDriverNumber}
                onInput={replaceInputTextToNumberOnly}
                onChange={(e) => {
                  setSelectedDriverNumber(e.target.value);
                }}
              />
            </FormGroup>
          )}
          {ticketChannel?.statusMaterial.active && (
            <FormGroup>
              <Label className={classes.textFormTitle}>
                Jenis Barang{" "}
                {!ticketChannel?.statusMaterial.optional && (
                  <span className={classes.mandatoryStar}>*</span>
                )}
              </Label>
              <PureInputSelect
                options={materialOpts}
                value={selectedMaterial}
                onChange={(val) => {
                  setSelectedMaterial(val);
                }}
                isDisabled={isSubmitting}
                isLoading={isLoadingMaterials}
                placeholder="Pilih Jenis Barang"
                styles={{
                  control: (prev, { isDisabled }) => {
                    return {
                      ...prev,
                      backgroundColor: isDisabled
                        ? "#F2F2F2"
                        : "rgba(255, 255, 255, 1)",
                      borderRadius: "4px",
                      border: "0.5px solid #C4CDE0",
                      // backdropFilter: "blur(10)",
                    };
                  },
                  option: (prev, { isDisabled }) => {
                    return {
                      ...prev,
                      cursor: isDisabled ? "not-allowed" : "pointer",
                      backgroundColor: "white",
                      color: isDisabled ? "#687484" : "#000000",
                      "&:hover": {
                        backgroundColor: !isDisabled
                          ? COLORS.blue_1
                          : COLORS.light_gray_1,
                        color: !isDisabled ? "white" : "#687484",
                      },
                    };
                  },
                }}
              />
            </FormGroup>
          )}
          {ticketChannel?.statusPo.active && (
            <div
              style={{
                display: "flex",
                gap: 8,
                alignItems: "center",
                width: "100%",
              }}
            >
              <div style={{ flex: 1 }}>
                <FormGroup>
                  <Label className={classes.textFormTitle}>
                    Nomor PO{" "}
                    {!ticketChannel?.statusPo.optional && (
                      <span className={classes.mandatoryStar}>*</span>
                    )}
                  </Label>
                  <Input
                    id="nopo"
                    className={classes.formInput}
                    name="nopo"
                    disabled={isSubmitting}
                    required={!ticketChannel?.statusPo.optional}
                    type="number"
                    placeholder="Nomor PO"
                    value={selectedPO}
                    onChange={(e) => {
                      setSelectedPO(e.target.value);
                    }}
                  />
                </FormGroup>
              </div>
              <div style={{ flex: "0 0 50%" }}>
                <FormGroup>
                  <Label className={classes.textFormTitle}>
                    Line{" "}
                    {!ticketChannel?.statusPo.optional && (
                      <span className={classes.mandatoryStar}>*</span>
                    )}
                  </Label>
                  <Input
                    id="poline"
                    className={classes.formInput}
                    name="poline"
                    disabled={isSubmitting}
                    placeholder="Line"
                    value={selectedPOLine}
                    type="number"
                    onChange={(e) => {
                      setSelectedPOLine(e.target.value);
                    }}
                  />
                </FormGroup>
              </div>
            </div>
          )}

          {ticketChannel?.statusDeliveryNote.active && (
            <FormGroup>
              <Label className={classes.textFormTitle}>
                Delivery Note{" "}
                {!ticketChannel?.statusDeliveryNote.optional && (
                  <span className={classes.mandatoryStar}>*</span>
                )}
              </Label>
              <Input
                id="delivery_note"
                className={classes.formInput}
                name="delivery_note"
                disabled={isSubmitting}
                placeholder="Delivery Note"
                required={!ticketChannel?.statusDeliveryNote.optional}
                value={selectedDeliveryNote}
                onChange={(e) => {
                  setSelectedDeliveryNote(e.target.value);
                }}
              />
            </FormGroup>
          )}
          {ticketChannel?.statusShipmentNumber.active && (
            <FormGroup>
              <Label className={classes.textFormTitle}>
                Shipment No.{" "}
                {!ticketChannel?.statusShipmentNumber.optional && (
                  <span className={classes.mandatoryStar}>*</span>
                )}
              </Label>
              <Input
                id="shipment_no"
                className={classes.formInput}
                name="shipment_no"
                disabled={isSubmitting}
                placeholder="Shipment No."
                required={!ticketChannel?.statusShipmentNumber.optional}
                value={selectedShipmentNo}
                onChange={(e) => {
                  setSelectedShipmentNo(e.target.value);
                }}
              />
            </FormGroup>
          )}
          {ticketChannel?.statusContainer.active && (
            <FormGroup>
              <Label className={classes.textFormTitle}>
                No Container{" "}
                {!ticketChannel?.statusContainer.optional && (
                  <span className={classes.mandatoryStar}>*</span>
                )}
              </Label>
              <Input
                id="container"
                className={classes.formInput}
                name="container"
                disabled={isSubmitting}
                placeholder="No Container"
                value={selectedContainer}
                required={!ticketChannel?.statusContainer.optional}
                onChange={(e) => {
                  setSelectedContainer(e.target.value);
                }}
                onInput={replaceInputTextContainer}
              />
            </FormGroup>
          )}
          {ticketChannel?.statusPhoto.active && (
            <div style={{ marginBottom: 16 }}>
              <FormGroup>
                <Label className={classes.textFormTitle}>
                  Foto
                  {!ticketChannel?.statusPhoto.optional && (
                    <span className={classes.mandatoryStar}>*</span>
                  )}{" "}
                  {ticket.photo && ticket.photo.length > 0 ? (
                    <span>
                      - Jumlah: {ticket.photo.length} foto{" "}
                      <span
                        style={{
                          textDecoration: "underline",
                          color: COLORS.blue_1,
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          setShowImagesCarousel(!showImagesCarousel)
                        }
                      >
                        (Lihat Foto)
                      </span>
                    </span>
                  ) : (
                    ""
                  )}
                </Label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 8,
                    alignItems: "center",
                  }}
                >
                  <button
                    className={btn.uploadPhotosButton}
                    onClick={() => uploadPhotosRef.current?.click()}
                    type="button"
                    style={{ width: "100%" }}
                  >
                    <BiPlus
                      size={14}
                      style={{ marginBottom: 2, marginRight: 4 }}
                    />
                    <span style={{ fontWeight: 600, fontSize: 13 }}>
                      Unggah Gambar
                    </span>
                  </button>
                  <input
                    id="image"
                    type="file"
                    name="image"
                    ref={uploadPhotosRef}
                    accept="image/*"
                    multiple
                    hidden
                    disabled={isSubmitting}
                    onChange={handleChangeImage}
                  />
                  <span
                    style={{
                      fontSize: 10,
                      marginBottom: 0,
                      fontStyle: "italic",
                    }}
                  >
                    Keterangan: Format (.jpg /.png), max 1024 kb
                  </span>
                </div>
                {!!photos.length && (
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 10,
                      overflowX: "auto",
                    }}
                  >
                    {photos.map((val, idx) => {
                      return (
                        <ImageViewer
                          key={idx}
                          file={val}
                          containerStyle={{
                            height: 100,
                            width: 100,
                          }}
                          withDeleteIcon
                          onDelete={() => {
                            let newList = photos.slice();
                            newList.splice(idx, 1);
                            setPhotos(newList);
                          }}
                        />
                      );
                    })}
                  </div>
                )}
              </FormGroup>
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <button
              type="button"
              disabled={isSubmitting}
              onClick={() => toggle()}
              className={[btn_classes.button].join(" ")}
            >
              Batal
            </button>
            <button
              form="editreservation"
              type="submit"
              disabled={isSubmitting || isLoadingSchema}
              className={[
                btn_classes.button,
                isSubmitting || isLoadingSchema ? "disabled" : "primary",
              ].join(" ")}
            >
              {isSubmitting || isLoadingSchema ? "Loading..." : "Simpan"}
            </button>
          </div>
        </form>
      </ModalBody>
    </CustomModal>
  );
};

export default EditReservationModal;
