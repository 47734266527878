import React from "react";
import { createUseStyles } from "react-jss";
import { Col, Button } from "reactstrap";
import PageContainer from "../components/PageContainer/PageContainer";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { format, startOfDay } from "date-fns";
import id from "date-fns/locale/id";
import { COLORS } from "../assets/theme";
import useUsertype from "../hooks/auth/useUsertype";
import {
  useGetWIReservationListQuery,
  useApproveReservationMutationMutation,
  useRejectReservationMutationMutation,
} from "../redux/ReservationStore/walkinReservationStoreAPI";
import { normalDateFormat } from "../types/dateformat";
import LoadingItem from "../components/atoms/LoadingContainer/LoadingItem";
import { useAppSelector } from "../redux";
import { small } from "../assets/breakpoints";
import ReactSimpleImageViewer from "react-simple-image-viewer";
import { ErrorMessageBackendDataShape } from "../types";
import Header from "../components/atoms/Header";
interface Props {}

const useStyles = createUseStyles(() => {
  return {
    container: {
      justifyContent: "center",
      alignItems: "center",
      paddingBottom: "200px",
    },
    titleContainer: {
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
      display: "flex",
    },
    carouselContainer: {
      display: "flex",
      overflowY: "auto",
      height: "40px",
      marginBottom: "15px",
      marginLeft: "15px",
    },
    btnContainer: {
      borderRadius: 8,
      "&:focus": {
        boxShadow: "none",
      },
      height: 48,
      justifyContent: "center",
      alignItems: "center",
      marginTop: 32,
      fontFamily: "Raleway",
      fontWeight: 700,
      letterSpacing: 0.5,
      fontSize: 12,
      width: "100%",
      borderWidth: 0,
      color: "white",
      "&.approve": {
        background: COLORS.gradientGreen_3,
      },
      "&.reject": {
        background: COLORS.gradientRed_1,
      },
    },
    buttonApproveContainer: {
      backgroundColor: "#27AE60",
      borderRadius: "5px",
      height: "40px",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "30px",
      fontFamily: "Raleway",
      fontWeight: 600,
      fontSize: "10px",
      width: "100%",
      borderWidth: 0,
    },
    buttonRejectContainer: {
      backgroundColor: "#FC5A5A",
      borderRadius: "5px",
      height: "40px",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "30px",
      fontFamily: "Raleway",
      fontWeight: 600,
      fontSize: "10px",
      width: "100%",
      borderWidth: 0,
    },
    detailReservationContainer: {
      display: "flex",
      flexDirection: "column",
    },
    table: {
      borderCollapse: "separate",
      borderSpacing: "0.8rem 0.2rem",
      marginTop: 16,
      "& tbody > tr > td": {
        fontSize: 13,
        paddingBottom: 8,
        fontWeight: 600,
        verticalAlign: "text-top",
      },
      "& tbody > tr > td:last-child": {
        wordBreak: "break-word",
      },
      "& tbody > tr > td:first-child": {
        width: 120,
        wordBreak: "normal",
      },
    },
    textTitle: {
      color: "black",
      fontFamily: "Raleway",
      fontWeight: 600,
      marginBottom: 0,
      fontSize: "1.4rem",
      [small]: {
        fontSize: "1.2rem",
      },
    },
    textListDetail: {
      color: COLORS.black_1,
      fontSize: 13,
    },
  };
});

const OnSiteApprovalPage: React.FunctionComponent<Props> = () => {
  const classes = useStyles();
  const user = useAppSelector((state) => state.userstore.userData);
  const navigate = useNavigate();
  const usertype = useUsertype();
  const params = useParams();
  const ticketId = Number(params?.ticket);
  const [showImagesCarousel, setShowImagesCarousel] =
    React.useState<boolean>(false);
  const [createApprove, { isLoading: isLoadingApprove }] =
    useApproveReservationMutationMutation();
  const [createReject, { isLoading: isLoadingReject }] =
    useRejectReservationMutationMutation();
  const { data: reservationList, isFetching: isFetchingReservation } =
    useGetWIReservationListQuery({
      id: ticketId,
      // isHistory: false,
    });
  const reservationData =
    reservationList && reservationList.entities
      ? reservationList.entities[0]
      : undefined;
  const qrId = reservationData?.qrid;
  const vendor = reservationData?.vendor;
  const vendorCode = vendor?.vendcode;
  const vendorName = vendor?.name;
  const ticketDate: string = reservationData?.date ?? "";
  const plateNumber = reservationData?.nopol ?? "";
  const driverName = reservationData?.drivername ?? "";
  const material = reservationData?.material?.description;
  const statusApproval = reservationData?.status;

  let isPast = (date: Date | number) =>
    new Date(date).getTime() < startOfDay(new Date()).getTime();

  const disableApproverButtons = () => {
    if (!reservationData) return;
    let channelRelation = user?.relationapproverchannel?.find(
      ({ channelId }) => {
        return reservationData.channelId === channelId;
      }
    );
    if (
      Number(channelRelation?.position) !==
        reservationData.approverPosition + 1 ||
      reservationData?.status !== "pending"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onApprove = () => {
    if (disableApproverButtons()) return;
    createApprove({
      id: ticketId,
    })
      .unwrap()
      .then(() => {
        toast.success("Reservasi berhasil disetujui.");
      })
      .catch((e: ErrorMessageBackendDataShape) => {
        if (e) {
          toast.error(`Gagal menyetujui reservasi. ${e.data.message}`);
        } else {
          toast.error(`Gagal menyetujui reservasi`);
        }
      });
  };

  const onReject = () => {
    if (disableApproverButtons()) return;
    createReject({
      id: ticketId,
    })
      .unwrap()
      .then(() => {
        toast.success("Reservasi berhasil ditolak");
      })
      .catch((e: ErrorMessageBackendDataShape) => {
        if (e) {
          toast.error(`Gagal menolak reservasi. ${e.data.message}`);
        } else {
          toast.error(`Gagal menolak reservasi.`);
        }
      });
  };

  const isLoading =
    isLoadingApprove || isLoadingReject || isFetchingReservation;

  return (
    <PageContainer
      withBackground
      headerNavigationContent={
        <div style={{ minHeight: "100vh", width: "100%", padding: "0, 16px" }}>
          <LoadingItem isLoading={isLoading} />
          <Header
            showBackButton={true}
            backButtonOnClick={() => {
              navigate(-1);
            }}
            title={`Ticket No. ${qrId}`}
          />
          {showImagesCarousel && reservationData && (
            <ReactSimpleImageViewer
              src={reservationData.photo.map(
                (item) => `${process.env.REACT_APP_BASIC_URL}${item}`
              )}
              currentIndex={0}
              onClose={() => setShowImagesCarousel(false)}
              disableScroll
              backgroundStyle={{
                backgroundColor: "rgba(0,0,0,0.9)",
              }}
              closeOnClickOutside
            />
          )}
          <div className={classes.detailReservationContainer}>
            <table className={classes.table}>
              <colgroup>
                <col />
                <col />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <td>Vendor</td>
                  <td>:</td>
                  <td>
                    {vendorCode} - {vendorName}
                  </td>
                </tr>
                <tr>
                  <td>Tanggal</td>
                  <td>:</td>
                  <td>
                    {reservationData?.date
                      ? format(new Date(ticketDate), normalDateFormat, {
                          locale: id,
                        })
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td>Channel</td>
                  <td>:</td>
                  <td>{reservationData?.channel.description}</td>
                </tr>
                <tr>
                  <td>No.Kendaraan</td>
                  <td>:</td>
                  <td>{plateNumber}</td>
                </tr>
                <tr>
                  <td>Pengemudi</td>
                  <td>:</td>
                  <td>{driverName}</td>
                </tr>
                {reservationData?.channel.statusMaterial.active && (
                  <tr>
                    <td>Jenis Barang</td>
                    <td>:</td>
                    <td>{material}</td>
                  </tr>
                )}
                <tr>
                  <td>Status</td>
                  <td>:</td>
                  <td>
                    {statusApproval === "pending"
                      ? reservationData &&
                        isPast(new Date(reservationData.date))
                        ? "Kadaluarsa"
                        : `Menunggu persetujuan dari Approver level ${
                            reservationData &&
                            reservationData?.approverPosition + 1
                          }`
                      : statusApproval === "rejected"
                      ? "Ditolak"
                      : "Disetujui"}
                  </td>
                </tr>
                {reservationData?.channel.statusContainer.active && (
                  <tr>
                    <td>Container</td>
                    <td>:</td>
                    <td>{reservationData?.container?.container ?? "-"}</td>
                  </tr>
                )}
                {reservationData?.channel.statusPo.active && (
                  <tr>
                    <td>No. PO</td>
                    <td>:</td>
                    <td>{reservationData?.ponum ?? "-"}</td>
                  </tr>
                )}
                {reservationData?.channel.statusShipmentNumber.active && (
                  <tr>
                    <td>Shipment No.</td>
                    <td>:</td>
                    <td>{reservationData?.shipmentNumber ?? "-"}</td>
                  </tr>
                )}
                <tr>
                  <td>Keterangan</td>
                  <td>:</td>
                  <td>{reservationData?.description ?? "-"}</td>
                </tr>
                {reservationData?.channel.statusPhoto.active && (
                  <tr>
                    <td>Foto</td>
                    <td>:</td>
                    <td>
                      {reservationData.photo &&
                      reservationData.photo.length > 0 ? (
                        <span>
                          {reservationData.photo.length} foto{" "}
                          <span
                            style={{
                              textDecoration: "underline",
                              color: COLORS.blue_1,
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setShowImagesCarousel(!showImagesCarousel)
                            }
                          >
                            (Lihat Foto)
                          </span>
                        </span>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {usertype === "approver" &&
            !isPast(new Date(reservationData?.date ?? "")) ? (
              <Col
                style={{
                  padding: 8,
                }}
              >
                <div>
                  <Button
                    style={{
                      background:
                        disableApproverButtons() ||
                        reservationData?.status !== "pending"
                          ? COLORS.gradientGray_1
                          : COLORS.gradientGreen_3,
                    }}
                    className={[classes.btnContainer, "approve"].join(" ")}
                    disabled={isLoadingApprove}
                    onClick={onApprove}
                  >
                    MENYETUJUI
                  </Button>
                </div>
                <div>
                  <Button
                    style={{
                      background:
                        disableApproverButtons() ||
                        reservationData?.status !== "pending"
                          ? COLORS.gradientGray_1
                          : COLORS.gradientRed_1,
                      marginTop: 16,
                    }}
                    className={[classes.btnContainer, "reject"].join(" ")}
                    disabled={isLoadingReject}
                    onClick={onReject}
                  >
                    MENOLAK
                  </Button>
                </div>
              </Col>
            ) : (
              <></>
            )}
          </div>
        </div>
      }
    ></PageContainer>
  );
};

export default OnSiteApprovalPage;
