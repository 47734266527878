import React, { Fragment, useCallback } from "react";
import { createUseStyles } from "react-jss";
import { COLORS } from "../../assets/theme";
import PageContainer from "../../components/PageContainer/PageContainer";
import Header from "../../components/atoms/Header";
import { useNavigate, useParams } from "react-router-dom";
import LoadingItem from "../../components/atoms/LoadingContainer/LoadingItem";
import { format, startOfDay } from "date-fns";
import { normalDateFormat } from "../../types/dateformat";
import id from "date-fns/locale/id";
import { PDFDownloadLink } from "@react-pdf/renderer";
import TicketPDFDoc from "../../components/atoms/TicketPDFDoc/TicketPDFDoc";
import qrcode from "qrcode";
import { useGetWIReservationListQuery } from "../../redux/ReservationStore/walkinReservationStoreAPI";
import useSpecificChannel from "../../hooks/channels/useSpecificChannel";
import { ReservationStatus } from "../../types/reservation";
import useUsertype from "../../hooks/auth/useUsertype";
import { BaseIcon } from "../../assets/Icons";
import { shareIcon_SVG } from "../../assets/Icons/svg";
import EditWalkinReservationModal from "../../components/atoms/Modals/forms/EditWalkInReservationModal";
import ReactSimpleImageViewer from "react-simple-image-viewer";
interface Props {}

const useStyles = createUseStyles(() => {
  return {
    container: {
      height: "100vh",
      display: "flex",
      flexDirection: "column",
    },
    buttonContainer: {},
    detailReservationContainer: {
      marginTop: 32,
    },
    table: {
      borderCollapse: "separate",
      borderSpacing: "0.8rem 0.5rem",
      "& tbody > tr > td": {
        fontSize: 15,
        paddingBottom: 8,
        fontWeight: 600,
        verticalAlign: "text-top",
      },
      "& tbody > tr > td:last-child": {
        wordBreak: "break-word",
      },
      "& tbody > tr > td:first-child": {
        width: 120,
        wordBreak: "normal",
      },
    },

    qrImage: {
      marginBottom: 0,
    },
    textReservationDetail: {
      color: "black",
      fontFamily: "Raleway",
      fontSize: "12px",
    },
    dropdownToggleBtn: {
      border: 0,
      backgroundColor: "transparent",
      color: COLORS.black_1,
      "&:hover": {
        color: COLORS.white,
      },
      "&:focus": {},
    },
    dropdownMenuItem: {
      "& button": {
        border: 0,
        backgroundColor: "white",
        "&:hover": {
          opacity: 0.7,
          backgroundColor: "red",
          color: "white",
        },
      },
    },
    primaryButton: {
      textDecoration: "none",
      fontWeight: 600,
      width: "100%",
      boxSizing: "border-box",
      border: 0,
      background: COLORS.gradientBlueGreen_1,
      borderRadius: ".8rem",
      color: "white",
      textAlign: "center",
      fontSize: "1.5rem",
      padding: "1.8rem 0",
      letterSpacing: "0.02rem",
      textTransform: "uppercase",
      "&:hover": {
        opacity: 0.7,
        color: "white",
      },
    },
    secondaryButton: {
      display: "block",
      cursor: "pointer",
      border: `3px solid ${COLORS.blue_1}`,
      textDecoration: "none",
      fontWeight: 600,
      width: "100%",
      textAlign: "center",
      fontSize: "1.2rem",
      padding: "1.6rem 0",
      letterSpacing: "0.02rem",
      textTransform: "uppercase",
      color: COLORS.blue_1,
      borderRadius: ".8rem",
      backgroundColor: "white",
      "&:disabled": {
        backgroundColor: COLORS.gray_1,
        color: COLORS.gray_2,
        "& > svg": {
          fill: COLORS.gray_2,
        },
      },
      "&:hover": {
        backgroundColor: COLORS.blue_1,
        color: COLORS.white,
        "& > svg": {
          fill: COLORS.white,
        },
      },
    },
    bottomBtnContainer: {
      backgroundColor: "white",
      position: "relative",
      left: 0,
      right: 0,
      padding: "1.2rem 1rem",
      display: "flex",
      flexDirection: "column",
      gap: "1.6rem",
    },
    savePDFbtnContainer: {
      position: "relative",

      margin: "0 auto",
      color: COLORS.blue_1,
      "&>a": {
        display: "block",
        cursor: "pointer",
        border: `2px solid ${COLORS.blue_1}`,
        textDecoration: "none",
        fontWeight: 600,
        width: "100%",
        textAlign: "center",
        fontSize: "1.5rem",
        padding: "1.4rem 0",
        letterSpacing: "0.02rem",
        color: COLORS.blue_1,
        borderRadius: ".8rem",
        backgroundColor: "white",
        "&:hover": {
          opacity: 0.7,
        },
      },
    },
  };
});

const WalkinDetailPage: React.FunctionComponent<Props> = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const usertype = useUsertype();
  const { ticketId } = useParams<"ticketId">();
  const [qr, setQr] = React.useState<string>();
  const [isLoadingShareButtonLink, setIsLoadingShareButtonLink] =
    React.useState<boolean>(false);
  const [showImagesCarousel, setShowImagesCarousel] =
    React.useState<boolean>(false);
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const {
    data,
    isLoading: isLoadingList,
    isFetching: isFetchingList,
    refetch,
  } = useGetWIReservationListQuery({});

  const pickedTicket = React.useMemo(() => {
    if (data && ticketId) {
      return data.entities?.find(({ id }) => id === parseInt(ticketId));
    }
    return undefined;
  }, [data, ticketId]);

  const handleShareDetail = useCallback(async () => {
    setIsLoadingShareButtonLink(true);
    if (navigator.share) {
      await setTimeout(async () => {
        try {
          await navigator
            .share({
              title: "Detail Walk-in Reservasi",
              url: `${
                process.env.REACT_APP_BASIC_URL ?? ""
              }#/view/walkin-reservation/${pickedTicket?.qrid ?? ""}`,
            })
            .then(() => {
              setIsLoadingShareButtonLink(false);
            })
            .catch(() => {
              setIsLoadingShareButtonLink(false);
            });
        } catch (error) {
          console.error(error);
        }
      }, 300);
    }
  }, [ticketId, pickedTicket]);

  let isPast =
    pickedTicket &&
    new Date(pickedTicket?.date).getTime() < startOfDay(new Date()).getTime();

  const { channel } = useSpecificChannel(pickedTicket!?.channelId, {
    skip: typeof pickedTicket === "undefined",
  });

  const isLoading = isLoadingList || isFetchingList;

  React.useEffect(() => {
    if (pickedTicket) {
      let qr = pickedTicket.qrid;
      if (qr) {
        qrcode.toDataURL(qr, { width: 300 }).then((val) => {
          setQr(val);
        });
      } else {
        setQr(undefined);
      }
    }
  }, [pickedTicket]);

  const handleWalkinDetailStatus = (status: ReservationStatus) => {
    if (!pickedTicket) return;
    switch (status) {
      case "approved":
        return "Disetujui";
      case "rejected":
        return "Ditolak";
      case "pending":
        return isPast
          ? "Kadaluarsa"
          : `Menunggu Persetujuan - Approver ${
              pickedTicket.approverPosition + 1
            }`;
      default:
        break;
    }
  };

  return (
    <PageContainer
      headerNavigationContent={
        <div style={{ minHeight: "100vh", width: "100%" }}>
          <Header
            backButtonOnClick={() => navigate(-1)}
            showBackButton={true}
            title={`Ticket No. ${pickedTicket?.qrid}`}
          />
          <LoadingItem isLoading={isLoading} />
          {pickedTicket ? (
            <>
              <div className={classes.detailReservationContainer}>
                {showImagesCarousel && (
                  <ReactSimpleImageViewer
                    src={pickedTicket.photo.map(
                      (item) => `${process.env.REACT_APP_BASIC_URL}${item}`
                    )}
                    currentIndex={0}
                    onClose={() => setShowImagesCarousel(false)}
                    disableScroll
                    backgroundStyle={{
                      backgroundColor: "rgba(0,0,0,0.9)",
                    }}
                    closeOnClickOutside
                  />
                )}

                <table
                  className={classes.table}
                  style={{
                    fontSize: 13,
                  }}
                >
                  <colgroup>
                    <col />
                    <col />
                    <col />
                  </colgroup>
                  <tbody>
                    {
                      <tr>
                        <td>Vendor</td>
                        <td>:</td>
                        <td>
                          {pickedTicket.vendor && pickedTicket.vendcode
                            ? `${pickedTicket.vendcode} - ${pickedTicket.vendor?.name}`
                            : "-"}
                        </td>
                      </tr>
                    }
                    <tr>
                      <td>Tanggal</td>
                      <td>:</td>
                      <td>
                        {format(new Date(pickedTicket.date), normalDateFormat, {
                          locale: id,
                        })}
                      </td>
                    </tr>
                    <tr>
                      <td>Channel</td>
                      <td>:</td>
                      <td>{pickedTicket.channel.description ?? "-"}</td>
                    </tr>
                    <tr>
                      <td>No Kendaraan</td>
                      <td>:</td>
                      <td>{pickedTicket?.nopol}</td>
                    </tr>
                    {channel?.statusDriverName.active && (
                      <tr>
                        <td>Pengemudi</td>
                        <td>:</td>
                        <td>{pickedTicket?.drivername ?? "-"}</td>
                      </tr>
                    )}
                    {channel?.statusDriverPhoneNumber?.active && (
                      <tr>
                        <td>No. Pengemudi</td>
                        <td>:</td>
                        <td>{pickedTicket?.driverPhoneNumber ?? "-"}</td>
                      </tr>
                    )}
                    {channel?.statusMaterial.active && (
                      <tr>
                        <td>Jenis Barang</td>
                        <td>:</td>
                        <td>{pickedTicket.material?.description ?? "-"}</td>
                      </tr>
                    )}
                    {channel?.statusPo.active && (
                      <>
                        <tr>
                          <td>PO</td>
                          <td>:</td>
                          <td>{pickedTicket?.ponum ?? "-"}</td>
                        </tr>
                        <tr>
                          <td>Line</td>
                          <td>:</td>
                          <td>{pickedTicket?.poline ?? "-"}</td>
                        </tr>
                      </>
                    )}

                    {channel?.statusDeliveryNote.active && (
                      <tr>
                        <td>Delivery Note</td>
                        <td>:</td>
                        <td>{pickedTicket.deliveryNote ?? "-"}</td>
                      </tr>
                    )}
                    {channel?.statusShipmentNumber.active && (
                      <tr>
                        <td>Shipment No.</td>
                        <td>:</td>
                        <td>{pickedTicket.shipmentNumber ?? "-"}</td>
                      </tr>
                    )}
                    {channel?.statusContainer.active && (
                      <tr>
                        <td>No Container</td>
                        <td>:</td>
                        <td>{pickedTicket.container?.container ?? "-"}</td>
                      </tr>
                    )}
                    <tr>
                      <td>Status</td>
                      <td>:</td>
                      <td>{handleWalkinDetailStatus(pickedTicket.status)}</td>
                    </tr>
                    <tr>
                      <td>Keterangan</td>
                      <td>:</td>
                      <td>{pickedTicket.description}</td>
                    </tr>
                    {channel?.statusPhoto.active && (
                      <tr>
                        <td>Foto</td>
                        <td>:</td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 8,
                            }}
                          >
                            {pickedTicket.photo &&
                            pickedTicket.photo.length > 0 ? (
                              <span>
                                {pickedTicket.photo.length} foto{" "}
                                <span
                                  style={{
                                    textDecoration: "underline",
                                    color: COLORS.blue_1,
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    setShowImagesCarousel(!showImagesCarousel)
                                  }
                                >
                                  (Lihat Foto)
                                </span>
                              </span>
                            ) : (
                              "-"
                            )}
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {pickedTicket.status === "approved" ? (
                  <div
                    style={{
                      marginBottom: "15vh",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {qr ? (
                      <img className={classes.qrImage} src={qr} alt="qrcode" />
                    ) : (
                      <p style={{ margin: "3rem 0" }}>Gagal memuat QR Code</p>
                    )}
                  </div>
                ) : (
                  <div style={{ marginTop: "40vh" }} />
                )}
              </div>
              {qr && channel && (
                <Fragment>
                  <div className={classes.bottomBtnContainer}>
                    <div
                      style={{
                        display: "flex",
                        gap: 16,
                        justifyContent: "space-between",
                      }}
                    >
                      {!isPast && pickedTicket.status === "pending" && (
                        <button
                          className={classes.secondaryButton}
                          onClick={() => setShowEditModal(true)}
                        >
                          Ubah
                        </button>
                      )}
                      <button
                        onClick={handleShareDetail}
                        className={classes.secondaryButton}
                        style={{
                          cursor: isLoading ? "not-allowed" : "pointer",
                        }}
                        disabled={isLoadingShareButtonLink}
                      >
                        {!isLoadingShareButtonLink ? (
                          <>
                            <BaseIcon
                              src={shareIcon_SVG}
                              width={16}
                              title="shareIcon"
                              height={16}
                              fill={COLORS.blue_1}
                            />
                            <span style={{ marginLeft: 16 }}>Bagikan</span>
                          </>
                        ) : (
                          <span style={{ marginLeft: 16 }}>Loading...</span>
                        )}
                      </button>
                    </div>
                    <PDFDownloadLink
                      fileName="Ticket.pdf"
                      className={classes.primaryButton}
                      document={
                        <TicketPDFDoc
                          channel={channel}
                          date={pickedTicket.date}
                          driverName={pickedTicket.drivername}
                          driverPhoneNumber={
                            pickedTicket?.driverPhoneNumber ?? "-"
                          }
                          materialName={
                            pickedTicket.material?.description ?? "-"
                          }
                          poLine={pickedTicket?.poline ?? undefined}
                          approverPosition={pickedTicket.approverPosition}
                          vendor={pickedTicket?.vendor ?? undefined}
                          description={pickedTicket?.description ?? "-"}
                          nopol={pickedTicket?.nopol ?? "-"}
                          poNum={pickedTicket?.ponum ?? "-"}
                          qr={qr}
                          isWalkin
                          statusWalkin={pickedTicket?.status}
                          deliveryNote={pickedTicket?.deliveryNote ?? "-"}
                          shipmentNumber={pickedTicket?.shipmentNumber ?? "-"}
                          container={pickedTicket?.container?.container ?? "-"}
                          photo={pickedTicket?.photo}
                        />
                      }
                    >
                      {({ loading }) => {
                        return loading ? "Loading..." : "SIMPAN SEBAGAI PDF";
                      }}
                    </PDFDownloadLink>
                  </div>
                </Fragment>
              )}
            </>
          ) : !isLoading ? (
            <p>Reservasi tidak ditemukan</p>
          ) : (
            []
          )}
          {/* EDIT MODAL */}
          {pickedTicket && (
            <EditWalkinReservationModal
              toggle={() => setShowEditModal((prev) => !prev)}
              isOpen={showEditModal}
              ticket={pickedTicket}
              onSuccess={() => {
                refetch();
              }}
            />
          )}
        </div>
      }
      className={classes.container}
      withBackground
    ></PageContainer>
  );
};

export default WalkinDetailPage;
