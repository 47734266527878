import { useMemo } from "react";
import * as yup from "yup";

const useChangePasswordValidationSchema = () => {
  return useMemo(() => {
    let newPassword = yup.string();
    let retypeNewPassword = yup.string();
    let schema = yup.object().shape({
      newPassword: newPassword.min(6, "Error! Password minimal 6 karakter."),
      retypeNewPassword: retypeNewPassword
        .min(6, "Error! Ketik Password Ulang Password minimal 6 karakter.")
        .oneOf(
          [yup.ref("newPassword")],
          "Error! Ketik Password Ulang harus sama dengan Password Baru"
        ),
    });
    return {
      schema,
    };
  }, []);
};

export default useChangePasswordValidationSchema;
