import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { convertToEncodedURL } from "../../tools";
import CookieMonster from "../../tools/CookieMonster";
import {
  APaginationEntity,
  BackendDataPageShape,
  BackendDataShape,
  BackendPaginationRequestObject,
} from "../../types";
import {
  AReservation,
  AReservationResultObj,
  ReservationRequestObj,
} from "../../types/reservation";
import { BASIC_URL } from "../basicurl";

export const reservationstoreAPI = createApi({
  reducerPath: "reservationstoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASIC_URL}/reservation`,
    prepareHeaders: (headers) => {
      const cookie = new CookieMonster();
      let token = cookie.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["ReservationList"],
  endpoints: (builder) => {
    return {
      getReservationList: builder.query<
        APaginationEntity<AReservation[]>,
        BackendPaginationRequestObject<ReservationRequestObj>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          return {
            url: "/find" + params,
            method: "GET",
          };
        },
        providesTags: () => ["ReservationList"],
        transformResponse: (res: BackendDataPageShape<AReservation[]>) => {
          return res.data;
        },
      }),
      createAReservation: builder.mutation<
        AReservationResultObj,
        ReservationRequestObj
      >({
        query: (obj) => {
          return {
            url: "create",
            method: "POST",
            body: obj,
          };
        },
        invalidatesTags: ["ReservationList"],
        transformResponse: (res: BackendDataShape<AReservationResultObj>) =>
          res.data,
      }),

      cancelReservation: builder.mutation<unknown, { id: number }>({
        query: ({ id }) => {
          return {
            url: `/cancel/${id}`,
            method: "PATCH",
          };
        },
        invalidatesTags: ["ReservationList"],
      }),
      updateReservation: builder.mutation<
        unknown,
        {
          id: number;
          truckId?: number;
          scheduleId?: number;
          materialId?: number;
          drivername?: string;
          date?: string;
        }
      >({
        query: ({ id, ...rest }) => {
          return {
            url: `/update/${id}`,
            method: "PATCH",
            body: rest,
          };
        },
        invalidatesTags: ["ReservationList"],
      }),
      addPhotoReservation: builder.mutation<
        unknown,
        {
          id: number;
          images: any;
        }
      >({
        query: ({ id, images }) => {
          return {
            url: `/addphoto/${id}`,
            method: "PATCH",
            body: images,
          };
        },
        invalidatesTags: ["ReservationList"],
      }),
    };
  },
});

export const {
  useGetReservationListQuery,
  useCreateAReservationMutation,
  useAddPhotoReservationMutation,
  useCancelReservationMutation,
  useUpdateReservationMutation,

  util: { resetApiState: resetReservationstoreAPI },
} = reservationstoreAPI;
