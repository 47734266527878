import React, { ChangeEvent } from "react";
import { BiHistory, BiTask } from "react-icons/bi";
import {
  add,
  endOfDay,
  format,
  isToday,
  isYesterday,
  startOfDay,
} from "date-fns";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BsCalendar2Range, BsThreeDotsVertical } from "react-icons/bs";
import { COLORS } from "../../assets/theme";
import PageContainer from "../../components/PageContainer/PageContainer";
import Header from "../../components/atoms/Header";
import Ticket from "../../components/atoms/Ticket";
import { ROUTES } from "../../config/nav";
import LoadingItem from "../../components/atoms/LoadingContainer/LoadingItem";
import useChannelOptions from "../../hooks/channels/useChannelOptions";
import useListContainerStyles from "../../hooks/styles/useListContainerStyles";
import { useGetWIReservationListQuery } from "../../redux/ReservationStore/walkinReservationStoreAPI";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import {
  MobileDateRangePicker,
  LocalizationProvider,
  DateRange,
} from "@material-ui/pickers";
import _ from "lodash";
import { ButtonBase, createTheme, ThemeProvider } from "@material-ui/core";
import enLocale from "date-fns/locale/en-US";
import { IoIosClose, IoMdCloseCircle, IoMdSearch } from "react-icons/io";
import { isPast } from "date-fns";
import { dateIsInRange } from "../../tools";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
} from "reactstrap";
import useLastElementInfiniteScroll from "../../hooks/useLastElementInfiniteScroll";
const { orderBy } = _;

interface Props {}

const datePickertheme = createTheme({
  typography: {
    fontFamily: "Raleway",
    fontSize: 18,
  },
});

const WalkInReservationPage: React.FunctionComponent<Props> = () => {
  const [params, setParams] = useSearchParams();
  const currentDate = new Date();
  const tommorowDate = add(currentDate, { days: 1 });
  const [showSearchText, setShowSearchText] = React.useState<boolean>(false);
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [dropdownIsOpen, setDropdownIsOpen] = React.useState<boolean>(false);
  const optId = React.useMemo(() => {
    const id = params.get("code");
    return id ? parseInt(id) : undefined;
  }, [params]);
  const [selectedDateRangeDate, setSelectedDateRangeDate] = React.useState<
    DateRange<Date>
  >([currentDate, tommorowDate]);

  const setOpenDropdown = () => {
    setDropdownIsOpen(!dropdownIsOpen);
  };

  const handleDateRangeChange = (date: DateRange<Date>) => {
    const [startDate, endDate] = date;
    params.set(
      "startDate",
      format(startDate !== null ? startDate : new Date(), "yyyy-MM-dd")
    );
    params.set(
      "endDate",
      format(endDate !== null ? endDate : new Date(), "yyyy-MM-dd")
    );
    setParams(params);
    setSelectedDateRangeDate(date);
  };

  const isHistory: boolean = params.get("history") === "true";
  const startDate: string | null = params.get("startDate");
  const endDate: string | null = params.get("endDate");
  const [limit, setLimit] = React.useState<number>(10);
  const navigate = useNavigate();
  const classes = useListContainerStyles();
  const [datePickerOpen, setDatePickerOpen] = React.useState<boolean>(false);
  const { arr } = useChannelOptions();
  const {
    data,
    isLoading: isLoadingReserveList,
    isFetching: isFetchingReserveList,
  } = useGetWIReservationListQuery(
    startDate && endDate
      ? {
          aftertime: format(new Date(startDate), "yyyy-MM-dd hh:mm aaaaa'm"),
          beforetime: format(new Date(endDate), "yyyy-MM-dd hh:mm aaaaa'm"),
          page: 1,
          take: limit,
          channelId: optId,
          nopol: searchInput ? searchInput : undefined,
        }
      : isHistory
      ? {
          page: 1,
          take: limit,
          channelId: optId,
          isHistory: true,
          nopol: searchInput ? searchInput : undefined,
        }
      : {
          page: 1,
          take: limit,
          channelId: optId,
          isList: true,
          nopol: searchInput ? searchInput : undefined,
        }
  );

  const lastElementRef = useLastElementInfiniteScroll({
    isFetching: isFetchingReserveList,
    page: 1,
    meta: data?.meta,
    setLimit,
    limit,
  });

  const reserveList = React.useMemo(() => {
    if (!data || !data.entities) return [];
    let dataList = [...data.entities];
    return dataList;
  }, [data]);

  const isLoading = isLoadingReserveList || isFetchingReserveList;

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setTimeout(() => {
      setSearchInput(e.target.value);
      setLimit(10);
    }, 1000);
  };

  return (
    <PageContainer
      headerNavigationContent={
        <>
          {!showSearchText ? (
            <Header
              backButtonOnClick={() => navigate(`${ROUTES.officer}`)}
              showBackButton={true}
              title={`${
                isHistory ? "Riwayat" : "List"
              } Reservasi Walk-in Approval`}
              rightButton={
                <>
                  {isHistory && (
                    <>
                      <BsCalendar2Range
                        cursor={"pointer"}
                        color={COLORS.black_1}
                        size={16}
                        onClick={() => setDatePickerOpen(!datePickerOpen)}
                      />
                      <LocalizationProvider
                        dateAdapter={DateFnsAdapter}
                        locale={enLocale}
                      >
                        <ThemeProvider theme={datePickertheme}>
                          <MobileDateRangePicker
                            value={selectedDateRangeDate}
                            onChange={handleDateRangeChange}
                            open={datePickerOpen}
                            showToolbar={false}
                            renderInput={(props) => <></>}
                            onClose={() => {
                              setDatePickerOpen(!datePickerOpen);
                            }}
                          />
                        </ThemeProvider>
                      </LocalizationProvider>
                    </>
                  )}
                  <Dropdown
                    style={{ marginLeft: 8 }}
                    isOpen={dropdownIsOpen}
                    toggle={setOpenDropdown}
                  >
                    <DropdownToggle
                      tag="span"
                      onClick={setOpenDropdown}
                      data-toggle="dropdown"
                      aria-expanded={dropdownIsOpen}
                    >
                      <BsThreeDotsVertical
                        size={16}
                        cursor="pointer"
                        color={COLORS.black_1}
                      />
                    </DropdownToggle>
                    <DropdownMenu style={{ marginTop: 6 }} end>
                      <DropdownItem
                        style={{
                          padding: 8,
                          display: "inline-flex",
                          alignItems: "center",
                          gap: 8,
                        }}
                        onClick={() => setShowSearchText(!showSearchText)}
                      >
                        <IoMdSearch size={16} color={COLORS.black_1} />
                        <span>Cari Reservasi</span>
                      </DropdownItem>
                      <DropdownItem
                        style={{
                          padding: 8,
                          display: "inline-flex",
                          alignItems: "center",
                          gap: 8,
                        }}
                        onClick={() => {
                          let current = params.get("history") === "true";
                          params.set("history", "" + !current);
                          setParams(params);
                        }}
                      >
                        {!isHistory ? (
                          <>
                            <BiHistory size={16} color={COLORS.black_1} />
                            <span>Riwayat Reservasi</span>
                          </>
                        ) : (
                          <>
                            <BiTask size={16} color={COLORS.black_1} />
                            <span>List Reservasi</span>
                          </>
                        )}
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </>
              }
            />
          ) : (
            <div className={classes.searchWrapper} style={{ width: "100%" }}>
              <Input
                id="search-reservation"
                name="search-reservation"
                placeholder="Cari tiket berdasarkan nomor kendaraan..."
                type="text"
                onChange={handleSearch}
                style={{
                  height: "40px",
                  width: "100%",
                  border: "none",
                  borderBottom: `1px solid ${COLORS.gray_1}`,
                  boxShadow: "none",
                  borderRadius: 0,
                }}
              />
              <IoIosClose
                size={24}
                cursor="pointer"
                color={COLORS.black_1}
                onClick={() => {
                  setSearchInput("");
                  setShowSearchText(!showSearchText);
                }}
              />
            </div>
          )}
        </>
      }
      headerContent={
        <div className={classes.tagContainer}>
          <ButtonBase
            className={[classes.tag, !optId ? "active" : undefined].join(" ")}
            onClick={() => {
              params.delete("code");
              setParams(params);
            }}
          >
            Semua
          </ButtonBase>
          {arr
            ?.filter((item) => item.active === true)
            .sort((a, b) => a.description.localeCompare(b.description))
            .map((val) => {
              return (
                <ButtonBase
                  key={val.id}
                  className={[
                    classes.tag,
                    val.value === optId ? "active" : undefined,
                  ].join(" ")}
                  onClick={() => {
                    params.set("code", "" + val.id);
                    setParams(params);
                  }}
                >
                  {val.label}
                </ButtonBase>
              );
            })}
        </div>
      }
      className={classes.container}
      withBackground
    >
      <div className={classes.listContainer} style={{ minHeight: "100vh" }}>
        {startDate && endDate && (
          <button
            className={classes.filteredButton}
            onClick={() => {
              params.delete("startDate");
              params.delete("endDate");
              setParams(params);
            }}
          >
            Filtered by Date <IoMdCloseCircle size={16} />
          </button>
        )}
        <LoadingItem isLoading={isLoading} />
        {isLoading
          ? "Loading..."
          : reserveList &&
            reserveList.map((ticket) => {
              const { color } =
                arr.find((val) => {
                  return val.id === ticket.channelId;
                }) ?? {};
              let tags = [];
              ticket.channel !== null &&
                tags.push({
                  desc: ticket.channel.description,
                  color: color ?? COLORS.gray,
                });
              ticket.nopol &&
                tags.push({
                  desc: ticket.nopol,
                  color: COLORS.black_1,
                });
              ticket.statusTruck !== "reserved" &&
                ticket.statusTruck !== "checkedout" &&
                tags.push({
                  desc: "Dalam Proses",
                  color: COLORS.blue_5_rgb,
                });
              switch (ticket.status) {
                case "approved":
                  tags.push({
                    color: COLORS.green_2,
                    desc: "Disetujui",
                  });
                  break;
                case "rejected":
                  tags.push({
                    color: COLORS.orange_2,
                    desc: "Ditolak",
                  });
                  break;
                case "pending":
                default:
                  isHistory
                    ? tags.push({
                        color: isPast(new Date(ticket.date))
                          ? COLORS.gray_3
                          : COLORS.red_1,
                        desc: isPast(new Date(ticket.date))
                          ? "Kadaluarsa"
                          : `Menunggu Persetujuan - Approver ${
                              ticket.approverPosition + 1
                            }`,
                      })
                    : tags.push({
                        color: COLORS.red_1,
                        desc: `Menunggu Persetujuan - Approver ${
                          ticket.approverPosition + 1
                        }`,
                      });
                  break;
              }

              return (
                <div ref={lastElementRef} key={ticket.id}>
                  <Ticket
                    ticketDate={ticket.date}
                    ticketLabel={ticket.qrid?.toString() ?? ""}
                    tags={tags}
                    isHistory={isHistory}
                    onClickTicket={() => {
                      navigate(`${ROUTES.walkinTicket}/${ticket.id}`);
                    }}
                  />
                </div>
              );
            })}

        {reserveList?.length === 0 ? (
          <div style={{ textAlign: "center", width: "100%" }}>
            <span>Reservasi tidak ditemukan</span>
          </div>
        ) : (
          isHistory && (
            <div style={{ textAlign: "center", width: "100%" }}>
              <span style={{ fontStyle: "italic", color: COLORS.gray }}>
                Riwayat Reservasi Walk-In yang ditampilkan adalah 7 hari
                terakhir.
              </span>
            </div>
          )
        )}
      </div>
    </PageContainer>
  );
};

export default WalkInReservationPage;
