import { ButtonBase } from "@material-ui/core";
import { AxiosResponse } from "axios";
import { add } from "date-fns";
import { FormEventHandler, useCallback, useEffect, useState } from "react";
import { BiChevronLeftCircle, BiChevronRightCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { sendUsernameResetPassword } from "../api/user/resetPassword";
import { BaseIcon, FajarPaperSVG, VerifiedIconSVG } from "../assets/Icons";
import { verifiedIcon_SVG } from "../assets/Icons/svg";
import { COLORS } from "../assets/theme";
import Truck from "../components/atoms/Truck";
import PageContainer from "../components/PageContainer/PageContainer";
import { useCountdown } from "../hooks/useCountdown";
import { ErrorMessageBackendDataShape } from "../types";
import useLoginStyles from "./LoginPage/useLoginStyles";

type Props = {};

const ForgotPasswordSendUsernamePage = (props: Props) => {
  const classes = useLoginStyles();
  const [username, setUsername] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const currentDate = new Date();
  const [interval, setInterval] = useState<Date>(
    add(currentDate, { minutes: 1 })
  );
  const { seconds } = useCountdown(interval);
  const [isAlreadySubmitted, setIsAlreadySubmitted] = useState<boolean>(false);
  const handleSubmitSendEmailVerification: FormEventHandler<HTMLFormElement> =
    useCallback(
      (e) => {
        e.preventDefault();
        if (!username) return;
        setIsLoading(true);
        sendUsernameResetPassword(username)
          .then((res: AxiosResponse) => {
            toast.success(
              "Username berhasil terkirim. Silahkan periksa email anda."
            );
            setIsLoading(false);
            setIsAlreadySubmitted(true);
            setInterval(add(currentDate, { minutes: 1 }));
            setUsername("");
          })
          .catch((error: ErrorMessageBackendDataShape) => {
            toast.error(
              "Username tidak valid, Pastikan username sudah terdaftar di sistem"
            );
            setIsLoading(false);
          });
      },
      [username]
    );

  useEffect(() => {
    if (seconds <= 0) {
      setIsAlreadySubmitted(false);
    }
  }, [seconds]);

  return (
    <PageContainer>
      <Form
        className={classes.formContainer}
        id="send_username_forgotpassword"
        onSubmit={handleSubmitSendEmailVerification}
        style={{ maxWidth: 320, marginTop: 120 }}
      >
        <div
          style={{
            marginBottom: 32,
            marginTop: 24,
            display: "inline-flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => navigate("/login")}
        >
          <BiChevronLeftCircle size={20} color={COLORS.blue_1} />
          <h5
            style={{
              fontWeight: 600,
              color: COLORS.blue_1,
              marginBottom: 0,
              marginLeft: 6,
            }}
          >
            Kembali ke Login
          </h5>
        </div>
        <div style={{ textAlign: "center", marginBottom: 32, marginTop: 24 }}>
          <h3 style={{ marginBottom: 12, fontWeight: 600 }}>Lupa Password</h3>
          <span>
            Masukkan Username untuk mendapatkan Link Penggantian Password
          </span>
        </div>
        <FormGroup className="mb-5">
          <Label for="username" className={classes.labelInput}>
            Username
          </Label>
          <Input
            className={classes.input}
            name="username"
            placeholder="Username..."
            type="text"
            value={username}
            disabled={isAlreadySubmitted}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
        </FormGroup>

        <button
          type="submit"
          disabled={isLoading || isAlreadySubmitted}
          form="send_username_forgotpassword"
          className={classes.loginBtn}
        >
          {isLoading
            ? "LOADING..."
            : isAlreadySubmitted && seconds >= 0
            ? `Submit kembali setelah ${seconds} detik`
            : "KIRIM"}
        </button>
      </Form>
      <Truck className={classes.truck} />
    </PageContainer>
  );
};

export default ForgotPasswordSendUsernamePage;
