import React from "react";
import { ScaleLoader } from "react-spinners";

type Props = {
  isLoading?: boolean;
};

/**
 * Make sure the parent has position relative
 * @returns
 */
const LoadingItem: React.FunctionComponent<Props> = ({ isLoading }: Props) => {
  return isLoading ? (
    <div
      style={{
        position: "absolute",
        top: "calc(50% - 20px)",
        bottom: "calc(50% - 20px)",
        left: "calc(50% - 60px)",
        right: "calc(50% - 60px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 2,
      }}
    >
      <ScaleLoader height={30} color="#1486DC" loading={!!isLoading} />
    </div>
  ) : null;
};

export default LoadingItem;
