import React from "react";
import { createUseStyles } from "react-jss";
import { Card, UncontrolledCarousel } from "reactstrap";
import { mq } from "../../assets/breakpoints";
import { Carousel_1, Carousel_2, Carousel_3 } from "../../assets/images";

const useStyles = createUseStyles(() => {
  return {
    mainWrapper: {
      border: "none",
    },
    carouselWrapper: {
      borderRadius: "16px",
      overflow: "hidden",
      "& .carousel-inner": {
        [mq[0]]: {
          maxHeight: 200,
        },
        [mq[1]]: {
          maxHeight: 220,
        },
        [mq[1]]: {
          maxHeight: 240,
        },
        [mq[3]]: {
          maxHeight: 280,
        },
        "& .carousel-item": {
          aspectRatio: 9 / 16,
          objectFit: "cover",
        },
        "& .carousel-item > img": {
          aspectRatio: 1 / 1,
          objectFit: "cover",
        },
      },
    },
  };
});

type Props = {
  containerClassName?: string;
};

const Carousel: React.FC<Props> = (props) => {
  const { containerClassName } = props;
  const classes = useStyles();

  return (
    <Card className={[containerClassName, classes.mainWrapper].join(" ")}>
      <UncontrolledCarousel
        className={classes.carouselWrapper}
        items={[
          {
            altText: "Slide 1",
            key: 1,
            src: Carousel_1,
            caption: "",
          },
          {
            altText: "Slide 2",
            key: 2,
            src: Carousel_2,
            caption: "",
          },
          {
            altText: "Slide 3",
            key: 3,
            src: Carousel_3,
            caption: "",
          },
        ]}
      />
    </Card>
  );
};

export default Carousel;
