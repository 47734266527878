import React from "react";
import { Link } from "react-router-dom";
import { GetLost } from "../assets/images";
import { useDefaultRoute } from "../components/HOC/Redirector";
import useLoginStyles from "./LoginPage/useLoginStyles";

interface Props {}

const NoMatch: React.FunctionComponent<Props> = (props) => {
  const defaultRoute = useDefaultRoute();
  const classes = useLoginStyles();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        flexDirection: "column",
      }}
    >
      {/* <h2>Lost?</h2> */}
      <img src={GetLost} alt="lost" style={{ maxWidth: 280 }} />
      <Link to={defaultRoute}>
        {" "}
        <button
          type="button"
          className={classes.loginBtn}
          style={{ padding: "12px 24px" }}
        >
          Kembali ke Halaman Awal
        </button>
      </Link>
    </div>
  );
};

export default NoMatch;
