import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { COLORS } from "../../assets/theme";
import { convertToEncodedURL } from "../../tools";
import CookieMonster from "../../tools/CookieMonster";
import { BackendDataShape } from "../../types";
import {
  AChannel,
  AChannelWithColor,
  ChannelCreateObj,
  ChannelReqObj,
  ChannelUpdateObj,
} from "../../types/channel";
import { BASIC_URL } from "../basicurl";

export const channelstoreAPI = createApi({
  reducerPath: "channelstoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASIC_URL}/channel`,
    prepareHeaders: (headers) => {
      const cookie = new CookieMonster();
      let token = cookie.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["ChannelList", "WarehouseList", "DockList"],
  endpoints: (builder) => {
    return {
      getChannels: builder.query<AChannelWithColor[], ChannelReqObj>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          return {
            url: "/find" + params,
            method: "GET",
          };
        },
        providesTags: ["ChannelList"],
        transformResponse: (res: BackendDataShape<AChannel[]>) => {
          const colors = [
            COLORS.orange_1,
            COLORS.purple,
            COLORS.green,
            COLORS.brown,
            COLORS.green_2,
            COLORS.yellow_1_rgb,
            COLORS.blue_2_rgb,
            COLORS.blue_3_rgb,
            COLORS.green_3,
            COLORS.purple_1_rgb,
            COLORS.green_3_rgb,
          ];
          return res.data.map((val, idx) => {
            return {
              ...val,
              color: colors[idx % colors.length],
            };
          });
        },
      }),
      createChannel: builder.mutation<unknown, ChannelCreateObj>({
        query: (obj) => {
          return {
            url: "/create",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["ChannelList"],
      }),
      updateChannel: builder.mutation<unknown, ChannelUpdateObj>({
        query: ({ id, ...rest }) => {
          return {
            url: `/update/${id}`,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["ChannelList"],
      }),
    };
  },
});

export const {
  useGetChannelsQuery,
  useCreateChannelMutation,
  useUpdateChannelMutation,
  util: { resetApiState: resetChannelstoreAPI },
} = channelstoreAPI;
