import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ASchedule } from "../../types/schedule";

export interface ScheduleState {
  scheduleArr: ASchedule[];
}

const initialState: ScheduleState = {
  scheduleArr: [],
};

export const scheduleSlice = createSlice({
  name: "SCHEDULE_SLICE",
  initialState,
  reducers: {
    storeScheduleData: (state, payload: PayloadAction<ASchedule[]>) => {
      state.scheduleArr = payload.payload;
    },
    //   setChannelColors: (state, action: PayloadAction<AChannel[]>) => {
    //     const { payload } = action;
    //     state.channelData = payload.map((val) => {
    //       return {
    //         ...val,
    //         color: "blue",
    //       };
    //     });
    //   },
  },
});

export default scheduleSlice.reducer;
export const { storeScheduleData } = scheduleSlice.actions;
