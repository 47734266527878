import { createUseStyles } from "react-jss";
import { COLORS } from "../../assets/theme";

const useLoginStyles = createUseStyles({
  container: {
    display: "flex",
    minHeight: "100vh",
  },
  formContainer: {
    flex: 1,
    margin: "6rem auto auto auto",
    display: "flex",
    flexDirection: "column",
    maxHeight: "600px",
    maxWidth: "300px",
    justifyContent: "space-evenly",
  },
  labelInput: { fontWeight: 600, fontSize: 12 },
  input: {
    padding: "1.4rem 1.6rem",
    borderRadius: "0.4rem",
  },

  passwordEye: {
    cursor: "pointer",

    // opacity: 0.1,
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "0 16px",
    top: 0,
    right: 0,
    bottom: 0,
    color: COLORS.gray,
    "& svg": {},
  },
  inputContainer: {
    position: "relative",
  },
  loginBtn: {
    border: 0,
    background: COLORS.gradientBlueGreen_1,
    color: COLORS.white,
    padding: "1.5rem 0",
    fontWeight: 700,
    letterSpacing: 0.75,
    fontSize: 12,
    borderRadius: "0.75rem",
    "&:disabled": {
      opacity: 0.7,
    },
    zIndex: 999,
  },
  truck: {
    position: "fixed",
    left: "0.5rem",
    right: "0.5rem",
    top: "70vh",
    margin: "0 auto",
    maxWidth: "70vw",
  },
});

export default useLoginStyles;
