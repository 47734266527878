import React, { Fragment } from "react";
import { createUseStyles } from "react-jss";
import { useNavigate, useParams } from "react-router-dom";
import { format, startOfDay } from "date-fns";
import id from "date-fns/locale/id";
import qrcode from "qrcode";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Header from "../../components/atoms/Header";
import LoadingItem from "../../components/atoms/LoadingContainer/LoadingItem";
import { normalDateFormat } from "../../types/dateformat";
import TicketPDFDoc from "../../components/atoms/TicketPDFDoc/TicketPDFDoc";
import PhotosCarousel from "../../components/atoms/Modals/PhotosCarousel";
import { COLORS } from "../../assets/theme";
import PageContainer from "../../components/PageContainer/PageContainer";
import { useGetGuestReservationByIdQuery } from "../../redux/ReservationStore/guestReservationAPI";
import ReactSimpleImageViewer from "react-simple-image-viewer";

interface Props {}

const useStyles = createUseStyles(() => {
  return {
    container: {
      height: "100vh",
      display: "flex",
      flexDirection: "column",
    },
    dropdownToggleBtn: {
      border: 0,
      backgroundColor: "transparent",
      color: COLORS.black_1,
      "&:hover": {
        color: COLORS.white,
      },
      "&:focus": {},
    },
    dropdownMenuItem: {
      "& button": {
        border: 0,
        backgroundColor: "white",
        "&:hover": {
          opacity: 0.7,
          backgroundColor: "red",
          color: "white",
        },
      },
    },

    buttonContainer: {},
    detailReservationContainer: {
      display: "flex",
      flexDirection: "column",
    },
    table: {
      borderCollapse: "separate",
      borderSpacing: "0.8rem 0.2rem",
      marginTop: 16,
      "& tbody > tr > td": {
        fontSize: 13,
        paddingBottom: 8,
        fontWeight: 600,
        verticalAlign: "text-top",
      },
      "& tbody > tr > td:last-child": {
        wordBreak: "break-word",
      },
      "& tbody > tr > td:first-child": {
        width: 120,
        wordBreak: "normal",
      },
    },

    qrImage: {},
    textReservationDetail: {
      color: "black",
      fontFamily: "Raleway",
      fontSize: "12px",
    },
    primaryButton: {
      textDecoration: "none",
      fontWeight: 600,
      width: "100%",
      boxSizing: "border-box",
      border: 0,
      background: COLORS.gradientBlueGreen_1,
      borderRadius: ".8rem",
      color: "white",
      textAlign: "center",
      fontSize: "1.5rem",
      padding: "1.8rem 0",
      letterSpacing: "0.02rem",
      textTransform: "uppercase",
      "&:hover": {
        opacity: 0.7,
        color: "white",
      },
    },
    secondaryButton: {
      display: "block",
      cursor: "pointer",
      border: `3px solid ${COLORS.blue_1}`,
      textDecoration: "none",
      fontWeight: 600,
      width: "100%",
      textAlign: "center",
      fontSize: "1.2rem",
      padding: "1.6rem 0",
      letterSpacing: "0.02rem",
      textTransform: "uppercase",
      color: COLORS.blue_1,
      borderRadius: ".8rem",
      backgroundColor: "white",
      "&:disabled": {
        backgroundColor: COLORS.gray_1,
        color: COLORS.gray_2,
        "& > svg": {
          fill: COLORS.gray_2,
        },
      },
      "&:hover": {
        backgroundColor: COLORS.blue_1,
        color: COLORS.white,
        "& > svg": {
          fill: COLORS.white,
        },
      },
    },
    bottomBtnContainer: {
      backgroundColor: "white",
      position: "relative",
      left: 0,
      right: 0,
      padding: "1.2rem 1rem",
      display: "flex",
      flexDirection: "column",
      gap: "1.6rem",
    },
    modal: {
      "&>*": {
        padding: "2rem",
      },
    },
    modalHeader: {
      borderBottom: 0,
      "&>h5": {
        lineHeight: "14.09px",
        fontWeight: 600,
        fontSize: "1.4rem",
      },
    },
  };
});

const DetailGuestReservationPage: React.FunctionComponent<Props> = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { qrid } = useParams<"qrid">();
  const [isLoadingQR, setIsLoadingQR] = React.useState<boolean>(false);
  const [showImagesCarousel, setShowImagesCarousel] =
    React.useState<boolean>(false);
  const [qr, setQr] = React.useState<string>();
  const {
    data,
    isLoading: isLoadingList,
    isFetching: isFetchingList,
  } = useGetGuestReservationByIdQuery({ qrid });

  const pickedTicket = React.useMemo(() => {
    if (data && data.length && qrid) {
      return data[0];
    }
    return undefined;
  }, [data, qrid]);

  let isPast =
    pickedTicket &&
    new Date(pickedTicket?.date).getTime() < startOfDay(new Date()).getTime();

  const isLoading = isLoadingList || isFetchingList;

  React.useEffect(() => {
    if (pickedTicket) {
      setIsLoadingQR(true);
      let qr = pickedTicket.qrid;
      if (qr) {
        qrcode
          .toDataURL(qr, { width: 300 })
          .then((val) => {
            setQr(val);
          })
          .finally(() => {
            setIsLoadingQR(false);
          });
      } else {
        setIsLoadingQR(false);
        setQr(undefined);
      }
    }
  }, [pickedTicket]);

  const channel = pickedTicket?.channel;

  return (
    <PageContainer
      headerNavigationContent={
        <div style={{ minHeight: "100vh", width: "100%", padding: "0, 16px" }}>
          <Header
            backButtonOnClick={() => navigate("/")}
            showBackButton={true}
            title={`Reservasi`}
          />
          {isLoading ? (
            ""
          ) : isPast ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "90vh",
                fontSize: 14,
              }}
            >
              <span>Reservasi telah lewat.</span>
            </div>
          ) : !pickedTicket?.active ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "90vh",
                fontSize: 14,
              }}
            >
              <span>Reservasi telah dibatalkan.</span>
            </div>
          ) : !!pickedTicket ? (
            <>
              <div className={classes.detailReservationContainer}>
                {showImagesCarousel && (
                  <ReactSimpleImageViewer
                    src={pickedTicket.photo.map(
                      (item) => `${process.env.REACT_APP_BASIC_URL}${item}`
                    )}
                    currentIndex={0}
                    onClose={() => setShowImagesCarousel(false)}
                    disableScroll
                    backgroundStyle={{
                      backgroundColor: "rgba(0,0,0,0.9)",
                    }}
                    closeOnClickOutside
                  />
                )}
                {/* <PhotosCarousel
                  items={
                    pickedTicket.photo.map((item, index) => {
                      return {
                        altText: index.toString(),
                        key: index,
                        src: `${process.env.REACT_APP_BASIC_URL}${item}`,
                        caption: "",
                      };
                    }) as any[]
                  }
                  isOpen={showImagesCarousel}
                  toggle={() => setShowImagesCarousel(!showImagesCarousel)}
                /> */}
                <table className={classes.table}>
                  <colgroup>
                    <col />
                    <col />
                    <col />
                  </colgroup>
                  <tbody>
                    {
                      <tr>
                        <td>Vendor</td>
                        <td>:</td>
                        <td>
                          {pickedTicket.vendcode !== null &&
                          pickedTicket.vendor?.name !== undefined
                            ? `${pickedTicket.vendcode} - ${pickedTicket.vendor?.name}`
                            : "-"}
                        </td>
                      </tr>
                    }
                    <tr>
                      <td>Tanggal</td>
                      <td>:</td>
                      <td>
                        {format(new Date(pickedTicket.date), normalDateFormat, {
                          locale: id,
                        })}
                      </td>
                    </tr>
                    <tr>
                      <td>Pukul</td>
                      <td>:</td>
                      <td>{`${pickedTicket.schedule.from} - ${pickedTicket.schedule.to}`}</td>
                    </tr>
                    <tr>
                      <td>Channel</td>
                      <td>:</td>
                      <td>{pickedTicket.channel.description}</td>
                    </tr>
                    <tr>
                      <td>No Kendaraan</td>
                      <td>:</td>
                      <td>{pickedTicket?.nopol ?? "-"}</td>
                    </tr>
                    {channel?.statusDriverName.active && (
                      <tr>
                        <td>Pengemudi</td>
                        <td>:</td>
                        <td>
                          {pickedTicket.drivername
                            ? pickedTicket.drivername
                            : "-"}
                        </td>
                      </tr>
                    )}
                    {channel?.statusDriverPhoneNumber?.active && (
                      <tr>
                        <td>Nomor Pengemudi</td>
                        <td>:</td>
                        <td>{pickedTicket.driverPhoneNumber ?? "-"}</td>
                      </tr>
                    )}
                    {channel?.statusMaterial.active && (
                      <tr>
                        <td>Jenis Barang</td>
                        <td>:</td>
                        <td>{pickedTicket.material?.description ?? "-"}</td>
                      </tr>
                    )}
                    {channel?.statusPo.active && (
                      <>
                        <tr>
                          <td>PO</td>
                          <td>:</td>
                          <td>{pickedTicket?.ponum ?? "-"}</td>
                        </tr>
                        <tr>
                          <td>Line</td>
                          <td>:</td>
                          <td>{pickedTicket?.poline ?? "-"}</td>
                        </tr>
                      </>
                    )}
                    {channel?.statusDeliveryNote.active && (
                      <tr>
                        <td>Delivery Note</td>
                        <td>:</td>
                        <td>{pickedTicket.deliveryNote ?? "-"}</td>
                      </tr>
                    )}
                    {channel?.statusShipmentNumber.active && (
                      <tr>
                        <td>Shipment No.</td>
                        <td>:</td>
                        <td>{pickedTicket.shipmentNumber ?? "-"}</td>
                      </tr>
                    )}
                    {channel?.statusContainer.active && (
                      <tr>
                        <td>No Container</td>
                        <td>:</td>
                        <td>{pickedTicket.container?.container ?? "-"}</td>
                      </tr>
                    )}
                    {channel?.statusPhoto.active && (
                      <tr>
                        <td>Foto</td>
                        <td>:</td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 8,
                            }}
                          >
                            {pickedTicket.photo &&
                            pickedTicket.photo.length > 0 ? (
                              <span>
                                {pickedTicket.photo.length} foto{" "}
                                <span
                                  style={{
                                    textDecoration: "underline",
                                    color: COLORS.blue_1,
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    setShowImagesCarousel(!showImagesCarousel)
                                  }
                                >
                                  (Lihat Foto)
                                </span>
                              </span>
                            ) : (
                              "-"
                            )}
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {qr ? (
                    <img className={classes.qrImage} alt="qr-code" src={qr} />
                  ) : isLoadingQR ? (
                    <p style={{ margin: "3rem 0" }}>Sedang memuat QR...</p>
                  ) : (
                    <p style={{ margin: "3rem 0" }}>Gagal memuat QR Code</p>
                  )}
                </div>
              </div>
              {qr && (
                <Fragment>
                  <div className={classes.bottomBtnContainer}>
                    {channel && (
                      <PDFDownloadLink
                        fileName="Ticket.pdf"
                        className={classes.primaryButton}
                        document={
                          <TicketPDFDoc
                            channel={channel}
                            date={pickedTicket.date}
                            driverName={pickedTicket.drivername}
                            driverPhoneNumber={
                              pickedTicket?.driverPhoneNumber ?? "-"
                            }
                            materialName={
                              pickedTicket.material?.description ?? "-"
                            }
                            poLine={pickedTicket?.poline ?? undefined}
                            approverPosition={0}
                            vendor={pickedTicket?.vendor ?? undefined}
                            isWalkin={false}
                            deliveryNote={pickedTicket?.deliveryNote ?? "-"}
                            shipmentNumber={pickedTicket?.shipmentNumber ?? "-"}
                            container={
                              pickedTicket?.container?.container ?? "-"
                            }
                            nopol={pickedTicket?.nopol ?? "-"}
                            poNum={pickedTicket?.ponum ?? "-"}
                            qr={qr}
                            photo={pickedTicket?.photo}
                          />
                        }
                      >
                        {({ loading }) => {
                          return loading ? "Loading..." : "SIMPAN SEBAGAI PDF";
                        }}
                      </PDFDownloadLink>
                    )}
                  </div>
                </Fragment>
              )}
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "90vh",
                fontSize: 14,
              }}
            >
              <span>Reservasi tidak ditemukan.</span>
            </div>
          )}

          <LoadingItem isLoading={isLoading} />
        </div>
      }
      className={classes.container}
      withBackground
    ></PageContainer>
  );
};

export default DetailGuestReservationPage;
