import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AChannel } from "../../types/channel";

// export interface ChannelState {
//   channelsWithColor: ADummyChannelWithColor[];
// }

// const initialState: ChannelState = {
//   channelsWithColor: [],
// };
export interface ChannelState {
  channelData: AChannel[];
}

const initialState: ChannelState = {
  channelData: [],
};

export const channelSlice = createSlice({
  name: "CHANNEL_SLICE",
  initialState,
  reducers: {
    storeChannelData: (state, payload: PayloadAction<AChannel[]>) => {
      state.channelData = payload.payload;
    },
    //   setChannelColors: (state, action: PayloadAction<AChannel[]>) => {
    //     const { payload } = action;
    //     state.channelData = payload.map((val) => {
    //       return {
    //         ...val,
    //         color: "blue",
    //       };
    //     });
    //   },
  },
});

export default channelSlice.reducer;
export const { storeChannelData } = channelSlice.actions;
