import { makeStyles } from "@material-ui/core";
import { createUseStyles } from "react-jss";
import { COLORS } from "../../../assets/theme";

export const useAdminSchedulePageStyle = makeStyles({
  container: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  saveScheduleButton: {
    background: COLORS.gradientBlue_3,
    width: 120,
    borderRadius: "5px",
    height: "40px",
    border: 0,
    color: "#fff",
    fontFamily: "Raleway",
    fontSize: "12px",
    fontWeight: 600,
    "&:disabled": {
      backgroundColor: COLORS.gray_1,
    },
  },
  headerContainer: {
    flexDirection: "row",
    flexBasis: "40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "2.5rem 0",
  },
  headerSideContainer: {
    flexBasis: "20px",
    display: "flex",
  },
  headerTitleContainer: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    flex: 1,
  },
  modalHeaderContainer: {
    flexDirection: "row",
    justifyContent: "center",
    display: "flex",
    paddingTop: "10px",
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  modalSideHeader: {
    flexBasis: "20px",
    display: "flex",
  },
  modalHeaderTitle: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flex: 1,
    fontFamily: "Raleway",
    fontSize: "14px",
    fontWeight: 600,
  },
  addButtonContainer: {
    height: "50px",
    width: "50px",
    borderRadius: "25px",
    backgroundColor: COLORS.blue_1,
    color: "#fff",
    border: 0,
    position: "absolute",
    right: "30px",
    bottom: "20px",
    zIndex: 99,
  },
  backButton: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    border: 0,
    backgroundColor: "transparent",
  },
  secondaryButton: {
    display: "block",
    cursor: "pointer",
    border: `3px solid ${COLORS.blue_1}`,
    textDecoration: "none",
    fontWeight: 600,
    width: "100%",
    textAlign: "center",
    fontSize: "1.2rem",
    padding: "1.2rem 0",
    letterSpacing: "0.02rem",
    textTransform: "uppercase",
    color: COLORS.blue_1,
    borderRadius: ".8rem",
    backgroundColor: "white",
    "&:disabled": {
      backgroundColor: COLORS.gray_1,
      color: COLORS.gray_2,
      "& > svg": {
        fill: COLORS.gray_2,
      },
    },
    "&:hover": {
      backgroundColor: COLORS.blue_1,
      color: COLORS.white,
      "& > svg": {
        fill: COLORS.white,
      },
    },
  },
  editButton: {
    backgroundColor: "transparent",
    border: 0,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    color: COLORS.blue_1,
    fontFamily: "Raleway",
    fontSize: "13px",
    fontWeight: 600,
  },
  carouselContainer: {
    display: "flex",
    overflowX: "auto",
    flexWrap: "nowrap",
    backgroundColor: "transparent",
    overflowY: "hidden",
    width: "100%",
    marginBottom: "1.6rem",
    alignItems: "center",
    "&::-webkit-scrollbar": {
      width: 0,
      height: 0,
    },
  },
  chipsContainer: {
    paddingLeft: "1.2rem",
    paddingRight: "1.2rem",
    height: 28,
    backgroundColor: "transparent",
    border: 1,
    borderStyle: "solid",
    borderColor: COLORS.gray,
    color: COLORS.black_1,
    width: "fit-content",
    marginRight: "1rem",
    borderRadius: "15px",
    fontFamily: "Raleway",
    fontSize: "10px",
    fontWeight: 600,
    whiteSpace: "nowrap",
    "&.pick": {
      background: COLORS.gradientBlue_3,
      border: 0,
      color: "white",
    },
    "&.unpick": {
      backgroundColor: "transparent",
      border: 1,
      borderStyle: "solid",
      borderColor: COLORS.gray,
      color: "black",
    },
  },
  scheduleListContainer: {
    paddingBottom: "200px",
    marginTop: "3vh",
    overflowY: "auto",
    scrollbarWidth: "none",
  },
  scheduleCardContainer: {
    padding: 16,
    width: "100%",
    marginBottom: "2vh",
    alignItems: "center",
    fontFamily: "Raleway",
    fontSize: "11px",
    borderRadius: "1rem",
    background: COLORS.gradientGreen_4,
    border: "1px solid " + COLORS.gradientGreen_4,
    color: COLORS.black_1,
    display: "flex",
    justifyContent: "space-between",
    gap: 8,
    cursor: "pointer",
  },
  textTitle: {
    color: COLORS.black_1,
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: 16,
    letterSpacing: 0.5,
    margin: 0,
  },
});
