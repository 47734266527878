import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Form, FormGroup, Input, Label } from "reactstrap";
import {
  changePassword,
  checkJwtValidationChangePassword,
} from "../api/user/resetPassword";
import Truck from "../components/atoms/Truck";
import PageContainer from "../components/PageContainer/PageContainer";
import useChangePasswordValidationSchema from "../hooks/auth/useChangePasswordValidationSchema";
import { ErrorMessageBackendDataShape } from "../types";
import useLoginStyles from "./LoginPage/useLoginStyles";
import * as yup from "yup";
import { BiChevronLeftCircle } from "react-icons/bi";
import { COLORS } from "../assets/theme";

type Props = {};

const ForgotPasswordTokenRedirectorPage = (props: Props) => {
  const classes = useLoginStyles();
  const [newPassword, setNewPassword] = useState<string>("");
  const [retypeNewPassword, setRetypeNewPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>();
  const { schema: changePasswordValidationSchema } =
    useChangePasswordValidationSchema();
  const handleSubmitChangePassword: React.FormEventHandler<HTMLFormElement> =
    useCallback(
      (e) => {
        e.preventDefault();
        if (!newPassword && retypeNewPassword) return;
        if (!token) return;
        const data = {
          newPassword,
          retypeNewPassword,
        };
        setIsLoading(true);
        changePasswordValidationSchema
          .validate(data)
          .then(() => {
            changePassword(newPassword, token)
              .then(() => {
                toast.success("Password berhasil diubah.");
                setIsLoading(false);
                navigate("/login");
              })
              .catch((err: ErrorMessageBackendDataShape) => {
                if (err) {
                  toast.error(err.data.message);
                } else {
                  toast.error(
                    "Error saat mengubah password. Silahkan coba kembali."
                  );
                }
                setIsLoading(false);
                navigate("/login");
              });
          })
          .catch((errors: yup.ValidationError) => {
            if (errors) {
              toast.error(errors.errors.join(","));
            } else {
              toast.error(
                "Error! saat mengubah password. Silahkan coba kembali."
              );
            }
            setIsLoading(false);
          });
      },
      [newPassword, retypeNewPassword, token]
    );

  useEffect(() => {
    if (token) {
      checkJwtValidationChangePassword(token)
        .then((res) => {
          const validationJwt = res.data.data;
          if (!(!validationJwt.used && validationJwt.valid)) {
            toast.error("Error! Token sudah digunakan.");
            navigate("/login");
          }
        })
        .catch(() => {
          navigate("/forgotpassword/resendemailexpired");
          toast.error("Token sudah expired. Silahkan submit email kembali.");
        });
    }
  }, [token]);

  return (
    <PageContainer>
      <Form
        id="forgot_password_redirector"
        className={classes.formContainer}
        onSubmit={handleSubmitChangePassword}
      >
        <div
          style={{
            marginBottom: 32,
            marginTop: 24,
            display: "inline-flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => navigate("/login")}
        >
          <BiChevronLeftCircle size={20} color={COLORS.blue_1} />
          <h5
            style={{
              fontWeight: 600,
              color: COLORS.blue_1,
              marginBottom: 0,
              marginLeft: 6,
            }}
          >
            Kembali ke Login
          </h5>
        </div>
        <div style={{ textAlign: "center", marginBottom: 32, marginTop: 24 }}>
          <h3 style={{ marginBottom: 12, fontWeight: 600 }}>
            Atur Ulang Password
          </h3>
          <span>Masukkan Password baru Anda.</span>
        </div>
        <FormGroup className="mb-5">
          <Label for="new-password" className={classes.labelInput}>
            Password Baru
          </Label>
          <Input
            className={classes.input}
            name="new-password"
            placeholder="Password Baru..."
            type="password"
            value={newPassword}
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
          />
        </FormGroup>
        <FormGroup className="mb-5">
          <Label for="retype-new-password" className={classes.labelInput}>
            Ketik Ulang Password Baru
          </Label>
          <Input
            className={classes.input}
            name="retype-new-password"
            placeholder="Ketik Ulang Password Baru..."
            type="password"
            value={retypeNewPassword}
            onChange={(e) => {
              setRetypeNewPassword(e.target.value);
            }}
          />
        </FormGroup>
        <button
          type="submit"
          form="forgot_password_redirector"
          disabled={isLoading}
          className={classes.loginBtn}
        >
          {isLoading ? "LOADING..." : "ATUR ULANG KATA SANDI"}
        </button>
      </Form>
      <Truck className={classes.truck} />
    </PageContainer>
  );
};

export default ForgotPasswordTokenRedirectorPage;
