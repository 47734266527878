import React from "react";
import { BASIC_URL } from "../redux/basicurl";

export interface BasicSelectOpt<T = string> {
  label: string;
  value: T;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

export type RequireSome<T, K extends keyof T> = Required<Pick<T, K>> &
  Partial<Omit<T, K>>;

export type UnpackArray<T> = T extends (infer U)[] ? U : T;

export type ElementProps<T> = T extends React.ComponentType<infer Props>
  ? Props extends object
    ? Props
    : never
  : never;

export type Nullable<D> = D | null | undefined;

export interface BackendDataShape<D> {
  status: string;
  data: D;
}

export interface BackendDataShapeResponse<D, BackendMetaPagination> {
  status: string;
  data: {
    entities: D;
    meta: BackendMetaPagination;
  };
}

export interface ErrorMessageBackendDataShape {
  status: number;
  data: {
    status: string;
    message: any;
  };
}

export interface BackendMetaPagination {
  /**
   * current page
   */
  page: number;
  offset: number;
  /**
   * total items
   */
  itemCount: number;
  /**
   * total item count
   */
  pageCount: number;
}

export interface APaginationEntity<D> {
  entities: D;
  meta: BackendMetaPagination;
}

export interface BackendDataPageShape<D> {
  status: string;
  data: APaginationEntity<D>;
}

export type PaginationRequestObj = {
  page?: number;
  take?: number;
};

export type BackendPaginationRequestObject<D extends {}> = D &
  PaginationRequestObj;

export const CREDLINKS = {
  login: `${BASIC_URL}/user/login`,
  regist: `${BASIC_URL}/user/register`,
  update: `${BASIC_URL}/user/update`,
  sendUsernameForgotPassword: `${BASIC_URL}/user/resetpassword`,
  changePassword: `${BASIC_URL}/user/changepassword`,
  checkJwtValidation: `${BASIC_URL}/user/jwtvalidation`,
} as const;

export const USERLINKS = {
  user: (params: string) => `${BASIC_URL}/user/find/${params}`,
  types: `${BASIC_URL}/usertype/find`,
} as const;

export const CLIENTLINKS = {
  // create:
  find: `${BASIC_URL}/client/find`,
} as const;

export const SYNCLINKS = {
  vendor: `${BASIC_URL}/sync/vendor`,
  truck: `${BASIC_URL}/sync/truck`,
  truckblacklist: `${BASIC_URL}/sync/truckblacklist`,
  po: `${BASIC_URL}/sync/po`,
  container: `${BASIC_URL}/sync/container`,
};

export const CHANNELLINKS = {
  create: `${BASIC_URL}/channel/create`,
  get: `${BASIC_URL}/channel/find`,
  delete: `${BASIC_URL}/channel/delete`,
  update: `${BASIC_URL}/channel/update`,
} as const;

export const SCHEDULELINKS = {
  create: `${BASIC_URL}/schedule/create`,
  get: `${BASIC_URL}/schedule/find`,
  delete: `${BASIC_URL}/schedule/delete`,
  update: `${BASIC_URL}/schedule/manage`,
} as const;

export const RESERVATIONLINKS = {
  create: `${BASIC_URL}/reservation/create`,
  get: `${BASIC_URL}/reservation/find`,
  create_walkin: `${BASIC_URL}/reservationwalkin/create`,
  approve: `${BASIC_URL}/reservationwalkin/approve`,
  reject: `${BASIC_URL}/reservationwalkin/reject`,
} as const;

export const VENDORLINKS = {
  find: `${BASIC_URL}/vendor/find`,
} as const;
