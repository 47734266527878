import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import CookieMonster from "../../tools/CookieMonster";
import { BackendDataShape } from "../../types";
import { AVendor, AVendorOpt } from "../../types/vendor";
import { BASIC_URL } from "../basicurl";

export const vendorstoreAPI = createApi({
  reducerPath: "vendorstoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASIC_URL}/vendor`,
    prepareHeaders: (headers) => {
      const cookie = new CookieMonster();
      let token = cookie.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["VendorList"],
  endpoints: (builder) => {
    return {
      getVendors: builder.query<AVendor[], {}>({
        query: () => {
          // let params = "";
          // if (obj) {
          //   params = convertToEncodedURL(obj);
          //   params = params.length ? "?" + params : "";
          // }
          return {
            url: "/find",
            method: "GET",
          };
        },
        providesTags: () => ["VendorList"],
        transformResponse: (res: BackendDataShape<AVendor[]>) => {
          return res.data;
        },
      }),
      getVendorsOptions: builder.query<AVendorOpt[], {}>({
        query: () => {
          return {
            url: "/find",
            method: "GET",
          };
        },
        providesTags: () => ["VendorList"],
        transformResponse: (res: BackendDataShape<AVendorOpt[]>) => {
          return res.data.map((item) => {
            return {
              ...item,
              label: item.name,
              value: item.vendcode,
            };
          });
        },
      }),
    };
  },
});

export const {
  useGetVendorsQuery,
  useGetVendorsOptionsQuery,
  util: { resetApiState: resetVendorstoreAPI },
} = vendorstoreAPI;
