import React, { Dispatch, SetStateAction, useCallback } from "react";
import { IoIosEye, IoIosEyeOff, IoIosTrash } from "react-icons/io";
import { SingleValue } from "react-select";
import { toast } from "react-toastify";
import { FormGroup, Input, Label, ModalBody, ModalHeader } from "reactstrap";
import { COLORS } from "../../../../assets/theme";
import useSchemaCreateAccount from "../../../../hooks/auth/useSchemaCreateAccount";
import useUsertypeOptions from "../../../../hooks/auth/useUsertypeOptions";
import useChannelOptions from "../../../../hooks/channels/useChannelOptions";
import useModalStyles from "../../../../hooks/styles/useModalStyles";
import useVendorOptions from "../../../../hooks/vendor/useVendorOptions";
import { useAdminAccountPageStyle } from "../../../../pages/AdminPage/ManageAccount/style";
import {
  useCreateUserMutation,
  useManageApproverMutation,
  useUpdateUserMutation,
} from "../../../../redux/UserStore/userstoreAPI";
import { ErrorMessageBackendDataShape } from "../../../../types";
import { AChannel, ChannelOpt, sampleChannel } from "../../../../types/channel";
import { OptionType } from "../../../../types/other/optionType";
import {
  AUserData,
  RegistObj,
  UpdateUserObj,
  UsertypeOpt,
} from "../../../../types/user";
import PureInputSelect from "../../PureInputSelect";
import CustomModal from "../CustomModal";
import * as yup from "yup";
import useUsertype from "../../../../hooks/auth/useUsertype";

type Props = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  isEditing: boolean;
  selectedAccount?: AUserData;
  toggle: () => void;
  toggleSwitchModal: () => void;
  onSuccess: () => void;
};

export type AddChannelRelationApproverReqObj = {
  id: number;
  name: string;
  position: number;
  positionLabel?: string;
  channel: AChannel;
};

const AccountFormModal = (props: Props) => {
  const {
    showModal,
    setShowModal,
    isEditing,
    toggle,
    onSuccess,
    selectedAccount,
    toggleSwitchModal,
  } = props;

  const modal_classes = useModalStyles();
  const classes = useAdminAccountPageStyle();
  const [createUser] = useCreateUserMutation();
  const [updateUser] = useUpdateUserMutation();
  const [manageApprover] = useManageApproverMutation();
  const { arr: channelList } = useChannelOptions({ active: true });
  const [showPass, setShowPass] = React.useState<boolean>(false);
  const [accountType, setAccountType] = React.useState<
    SingleValue<UsertypeOpt>
  >(
    selectedAccount
      ? {
          label: selectedAccount.usertype.name,
          value: selectedAccount.usertype.id,
          ...selectedAccount.usertype,
        }
      : null
  );
  const [clientCode, setClientCode] = React.useState<number>(
    selectedAccount?.clientcode ?? 0
  );
  const [email, setEmail] = React.useState<string>(
    selectedAccount?.email ?? ""
  );
  const [subvendorName, setSubvendorName] = React.useState<string>(
    selectedAccount?.subvendor?.name ?? ""
  );
  const [vendorCode, setVendor] = React.useState<number>();
  const [channel, setChannel] = React.useState<SingleValue<ChannelOpt>>();
  const [username, setUsername] = React.useState<string>(
    selectedAccount?.username ?? ""
  );
  const [editAccountStatus, SetEditAccountStatus] = React.useState<boolean>(
    selectedAccount?.active ?? false
  );
  const [password, setPassword] = React.useState<string>("");

  const [channelApproverInputList, setChannelApproverInputList] =
    React.useState<Array<AddChannelRelationApproverReqObj>>([]);

  // if the user has `relationapproverchannel` i.e. an approver,
  // initially we will populate the list based on this user channels
  React.useEffect(() => {
    if (selectedAccount && selectedAccount.relationapproverchannel) {
      const list =
        selectedAccount.relationapproverchannel.map<AddChannelRelationApproverReqObj>(
          (val) => {
            return {
              id: val.id,
              channel: val.channel!,
              name: val.channel!.description ?? "",
              position: val.position,
              positionLabel: "" + val.position,
            };
          }
        );
      setChannelApproverInputList(list);
    }
  }, [selectedAccount, setChannelApproverInputList]);

  React.useEffect(() => {
    if (!showModal && !isEditing) {
      setEmail("");
      setPassword("");
      setVendor(0);
      setSubvendorName("");
      setUsername("");
      setChannel(null);
      setChannelApproverInputList([]);
    }
  }, [showModal, isEditing]);

  const handleChangeEditStatus = (e: string) => {
    if (e === "true") {
      SetEditAccountStatus(true);
    } else {
      SetEditAccountStatus(false);
    }
  };
  const { schema: basicAccountValidationSchema } = useSchemaCreateAccount();
  const populateApprovalCountOptions = useCallback((approvalCount: number) => {
    const approverPositions: OptionType[] = [];
    for (let i = 0; i < approvalCount; i++) {
      approverPositions.push({
        value: i + 1,
        label: (i + 1).toString(),
      });
    }
    return approverPositions;
  }, []);
  const handleSelectChannelApproverList = (
    { value, label, ...rest }: ChannelOpt,
    index: number
  ) => {
    if (!value && !label && !rest && label === undefined) return;
    const list = [...channelApproverInputList];

    list[index]["name"] = label;
    list[index]["id"] = value;
    list[index]["channel"] = rest;

    if (list[index]["channel"])
      populateApprovalCountOptions(rest.approvalCount);
    setChannelApproverInputList(list);
  };

  const handleSelectPositionApproverList = (
    item: SingleValue<OptionType>,
    index: number
  ) => {
    if (!item) return;
    const list = [...channelApproverInputList];
    list[index]["position"] = item.value;
    list[index]["positionLabel"] = item.label;
    setChannelApproverInputList(list);
  };
  const handleDeleteApproverRelationChannelList = (index: number) => {
    const list = [...channelApproverInputList];
    list.splice(index, 1);
    setChannelApproverInputList(list);
  };

  const approverChannelList = channelList.filter(({ id }) => {
    const idFound = channelApproverInputList.find((val) => {
      return val.id === id;
    });
    return !idFound;
  });
  const { arr: accountTypeOpts } = useUsertypeOptions();
  const { arr: vendorArr } = useVendorOptions();
  const vendorOpt = React.useMemo(() => {
    let data = vendorArr.map((item) => {
      let opt: OptionType = {
        label: item.name,
        value: item.vendcode,
      };
      return opt;
    });

    return data;
  }, [vendorArr]);

  React.useEffect(() => {
    if (selectedAccount) {
      setVendor(selectedAccount?.vendcode ?? 0);
      setClientCode(selectedAccount?.clientcode ?? 0);
      setAccountType({
        label: selectedAccount.usertype.name,
        value: selectedAccount.usertype.id,
        ...selectedAccount.usertype,
      });
      let selectedChannel = selectedAccount.channel as AChannel;
      if (selectedChannel !== null) {
        setChannel({
          label: selectedChannel.description,
          value: selectedChannel.id,
          color: "",
          ...selectedChannel,
        });
      }
      if (selectedAccount.relationapproverchannel) {
        setChannelApproverInputList(
          selectedAccount.relationapproverchannel.map((item) => {
            return {
              id: item.channel?.id ?? sampleChannel.id,
              name: item.channel?.description as string,
              position: item.position,
              positionLabel: item.position.toString(),
              channel: item.channel ?? sampleChannel,
            };
          })
        );
      }
    }
  }, [selectedAccount]);

  React.useEffect(() => {
    let data = vendorArr.find(({ vendcode }) => {
      return vendcode === vendorCode;
    });
    setClientCode(data ? data?.clientcode : 0);
  }, [vendorCode, vendorArr]);

  const submitUser = React.useCallback(() => {
    if (isEditing && selectedAccount) {
      let obj: Omit<UpdateUserObj, "password"> = {
        username: username,
        usertypeId: `${accountType?.value}`,
        channelId: channel?.value ?? 0,
        vendcode: vendorCode,
        clientcode: clientCode,
        email: email,
        userId: selectedAccount?.id,
        active: editAccountStatus,
        subvendorName: subvendorName,
      };
      let objSecurity: Omit<UpdateUserObj, "password"> = {
        username: username,
        usertypeId: `${accountType?.value}`,
        channelId: channel?.value ?? 0,
        clientcode: clientCode,
        email: email,
        userId: selectedAccount?.id,
        active: editAccountStatus,
      };

      basicAccountValidationSchema
        .validate(accountType?.value === 2 ? obj : objSecurity)
        .then(() => {
          updateUser(accountType?.value === 2 ? obj : objSecurity)
            .unwrap()
            .then(() => {
              setShowModal(false);
              toggleSwitchModal();
              toast("Akun berhasil diubah.", {
                type: "success",
              });
            })
            .catch((e: ErrorMessageBackendDataShape) => {
              toast(
                e
                  ? "Gagal mengubah akun. " + e.data.message
                  : "Gagal mengubah akun.",
                {
                  type: "error",
                }
              );
            });
        })
        .catch((e: yup.ValidationError) => {
          toast(e ? "Error! " + e.errors.join(", ") : "Gagal membuat akun.", {
            type: "error",
          });
        });
    } else {
      let obj: RegistObj = {
        username: username,
        password: password,
        usertypeId: `${accountType?.value}`,
        channelId: channel?.value ?? 0,
        vendcode: vendorCode,
        clientcode: clientCode,
        email: email,
        subvendorName: subvendorName,
      };
      let objSecurity: RegistObj = {
        username: username,
        password: password,
        usertypeId: `${accountType?.value}`,
        channelId: channel?.value ?? 0,
        email: email,
      };
      basicAccountValidationSchema
        .validate(accountType?.value === 2 ? obj : objSecurity)
        .then(() => {
          createUser(accountType?.value === 2 ? obj : objSecurity)
            .unwrap()
            .then(() => {
              setShowModal(false);
              toggleSwitchModal();
              toast("Akun berhasil ditambahkan.", {
                type: "success",
              });
            })
            .catch((e: ErrorMessageBackendDataShape) => {
              toast(e ? "Error! " + e.data.message : "Gagal membuat akun", {
                type: "error",
              });
            });
        })
        .catch((e: yup.ValidationError) => {
          toast(e ? "Error! " + e.errors.join(", ") : "Gagal membuat akun.", {
            type: "error",
          });
        });
    }
  }, [
    accountType,
    username,
    password,
    channel,
    vendorCode,
    subvendorName,
    editAccountStatus,
    clientCode,
    createUser,
    email,
    isEditing,
    selectedAccount,
    setShowModal,
    updateUser,
  ]);

  const submitOfficer = React.useCallback(() => {
    if (isEditing && selectedAccount) {
      let obj: Omit<UpdateUserObj, "password"> = {
        username: username,
        usertypeId: `${accountType?.value}`,
        email: email,
        userId: selectedAccount.id,
        active: editAccountStatus,
      };
      basicAccountValidationSchema
        .validate(obj)
        .then(() => {
          updateUser(obj)
            .unwrap()
            .then(() => {
              setShowModal(false);
              toggleSwitchModal();
              toast("Akun berhasil diubah.", {
                type: "success",
              });
            })
            .catch((e: ErrorMessageBackendDataShape) => {
              toast(
                e
                  ? "Gagal mengubah akun. " + e.data.message
                  : "Gagal mengubah akun.",
                {
                  type: "error",
                }
              );
            });
        })
        .catch((e: yup.ValidationError) => {
          toast(e ? "Error! " + e.errors.join(", ") : "Gagal membuat akun.", {
            type: "error",
          });
        });
    } else {
      let obj: RegistObj = {
        username: username,
        password: password,
        usertypeId: `${accountType?.value}`,
        email: email,
      };
      basicAccountValidationSchema
        .validate(obj)
        .then(() => {
          createUser(obj)
            .unwrap()
            .then(() => {
              setShowModal(false);
              toggleSwitchModal();
              toast("Akun berhasil ditambahkan.", {
                type: "success",
              });
            })
            .catch((e: ErrorMessageBackendDataShape) => {
              toast(e ? "Error! " + e.data.message : "Gagal membuat akun", {
                type: "error",
              });
            });
        })
        .catch((e: yup.ValidationError) => {
          toast(e ? "Error! " + e.errors.join(", ") : "Gagal membuat akun.", {
            type: "error",
          });
        });
    }
  }, [
    username,
    email,
    password,
    accountType,
    editAccountStatus,
    createUser,
    isEditing,
    selectedAccount,
    setShowModal,
    updateUser,
  ]);

  const submitApprover = React.useCallback(() => {
    if (isEditing && selectedAccount) {
      let obj: Omit<UpdateUserObj, "password"> = {
        username: username,
        usertypeId: `${accountType?.value}`,
        email: email,
        userId: selectedAccount?.id,
        active: editAccountStatus,
      };

      basicAccountValidationSchema
        .validate(obj)
        .then(() => {
          updateUser(obj)
            .unwrap()
            .then(() => {
              manageApprover({
                userId: selectedAccount.id,
                channels: channelApproverInputList.map((item) => {
                  return {
                    id: item.id,
                    position: item.position,
                  };
                }),
              })
                .unwrap()
                .then(() => {
                  setTimeout(() => {
                    onSuccess();
                  }, 300);
                  setShowModal(false);
                  toggleSwitchModal();
                  toast("Akun berhasil diubah", {
                    type: "success",
                  });
                })
                .catch((e: ErrorMessageBackendDataShape) => {
                  toast(
                    e
                      ? "Gagal mengubah akun. " + e.data.message
                      : "Gagal mengubah akun.",
                    {
                      type: "error",
                    }
                  );
                });
            })
            .catch((e: ErrorMessageBackendDataShape) => {
              toast(
                e
                  ? "Gagal mengubah akun. " + e.data.message
                  : "Gagal mengubah akun.",
                {
                  type: "error",
                }
              );
            });
        })
        .catch((e: yup.ValidationError) => {
          toast(e ? "Error! " + e.errors.join(", ") : "Gagal membuat akun.", {
            type: "error",
          });
        });
    } else {
      let obj: RegistObj = {
        username: username,
        password: password,
        usertypeId: `${accountType?.value}`,
        email: email,
        channelIds: channelApproverInputList.map((item) => {
          return {
            id: item.id,
            position: item.position,
          };
        }),
      };
      basicAccountValidationSchema
        .validate(obj)
        .then(() => {
          createUser(obj)
            .unwrap()
            .then((res) => {
              setTimeout(() => {
                onSuccess();
              }, 300);
              setShowModal(false);
              toggleSwitchModal();
              toast("Akun berhasil ditambahkan.", {
                type: "success",
              });
            })
            .catch((e: ErrorMessageBackendDataShape) => {
              toast(e ? "Error! " + e.data.message : "Gagal membuat akun", {
                type: "error",
              });
            });
        })
        .catch((e: yup.ValidationError) => {
          toast(e ? "Error! " + e.errors.join(", ") : "Gagal membuat akun.", {
            type: "error",
          });
        });
    }
  }, [
    accountType,
    username,
    email,
    password,
    onSuccess,
    editAccountStatus,
    channelApproverInputList,
    createUser,
    isEditing,
    manageApprover,
    selectedAccount,
    setShowModal,
    updateUser,
  ]);

  const toggleSubmit = () => {
    if (!accountType) return;
    switch (accountType.value) {
      //User, Security
      case 2:
        return submitUser();
      //Officer
      case 3:
        return submitOfficer();
      //Approver
      case 4:
        return submitApprover();
      default:
        return submitUser();
    }
  };

  return (
    <CustomModal
      isOpen={showModal}
      className={modal_classes.modal}
      toggle={toggle}
    >
      <ModalHeader className={modal_classes.modalHeader} toggle={toggle}>
        <span>{isEditing ? "Ubah" : "Buat"} Akun</span>
      </ModalHeader>
      <ModalBody>
        <form
          id={isEditing ? "editaccount" : "createaccount"}
          onSubmit={toggleSubmit}
          className={modal_classes.modalFormWrapper}
        >
          <FormGroup>
            <Label className={classes.textFormTitle}>Tipe Akun</Label>
            <PureInputSelect
              value={accountType}
              options={accountTypeOpts.filter(
                (item) => item.name !== "superadmin"
              )}
              isMulti={false}
              onChange={(val) => setAccountType(val)}
            />
          </FormGroup>
          {accountType?.value === 2 && (
            <>
              <FormGroup>
                <Label className={classes.textFormTitle}>
                  Vendor <span className={classes.mandatoryStar}>*</span>
                </Label>
                <PureInputSelect
                  options={vendorOpt}
                  placeholder={"Pilih Vendor"}
                  isClearable
                  isLargeDataList
                  isSearchable
                  defaultValue={{
                    value: vendorCode,
                    label: selectedAccount?.vendor?.name,
                  }}
                  onChange={(val) => setVendor(val?.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label className={classes.textFormTitle}>Subvendor</Label>
                <Input
                  style={{ height: "40px", borderRadius: "5px" }}
                  onChange={(val) => setSubvendorName(val.target.value)}
                  placeholder="Subvendor..."
                  id="subvendorName"
                  name="subvendorName"
                  value={subvendorName}
                  type="text"
                />
              </FormGroup>
            </>
          )}
          {(accountType?.value === 5 || accountType?.value === 2) && (
            <FormGroup>
              <Label className={classes.textFormTitle}>Channel</Label>
              <PureInputSelect
                defaultValue={channel}
                options={channelList.sort((a, b) =>
                  a.description.localeCompare(b.description)
                )}
                isMulti={false}
                onChange={(val) => setChannel(val)}
              />
            </FormGroup>
          )}
          <FormGroup>
            <Label className={classes.textFormTitle}>Email</Label>
            <Input
              id="email"
              name="email"
              value={email}
              style={{ height: "40px", borderRadius: "5px" }}
              onChange={(val) => setEmail(val.target.value)}
              placeholder="Email Pengguna"
              type="email"
            />
          </FormGroup>
          <FormGroup>
            <Label className={classes.textFormTitle}>Username</Label>
            <Input
              style={{ height: "40px", borderRadius: "5px" }}
              onChange={(val) => setUsername(val.target.value)}
              placeholder="Username"
              id="username"
              name="username"
              value={username}
              onInput={(e) =>
                (e.currentTarget.value =
                  "" + e.currentTarget.value.toLowerCase())
              }
              type="text"
            />
          </FormGroup>
          {!isEditing ? (
            <FormGroup>
              <Label className={classes.textFormTitle}>Password</Label>
              <div style={{ position: "relative" }}>
                <Input
                  style={{ height: "40px", borderRadius: "5px" }}
                  id="password"
                  name="password"
                  autoComplete="new-password"
                  value={password}
                  onChange={(val) => setPassword(val.target.value)}
                  placeholder="Password"
                  type={showPass ? "text" : "password"}
                />
                <div
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    padding: "0 16px",
                    top: 0,
                    right: 0,
                    bottom: 0,
                  }}
                  onClick={() => setShowPass(!showPass)}
                >
                  {showPass ? (
                    <IoIosEye size={16} />
                  ) : (
                    <IoIosEyeOff size={16} />
                  )}
                </div>
              </div>
            </FormGroup>
          ) : (
            <FormGroup>
              <Label className={classes.textFormTitle}>Status</Label>
              <FormGroup tag="fieldset">
                <FormGroup check>
                  <Input
                    name="active"
                    type="radio"
                    value={"true"}
                    checked={editAccountStatus === true}
                    onChange={(val) => handleChangeEditStatus(val.target.value)}
                  />
                  <Label
                    style={{
                      fontFamily: "Raleway",
                      fontSize: "12px",
                    }}
                    check
                  >
                    Aktif
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Input
                    name="active"
                    type="radio"
                    checked={editAccountStatus === false}
                    value={"false"}
                    onChange={(val) => {
                      handleChangeEditStatus(val.target.value);
                    }}
                  />
                  <Label
                    style={{
                      fontFamily: "Raleway",
                      fontSize: "12px",
                    }}
                    check
                  >
                    Tidak Aktif
                  </Label>
                </FormGroup>
              </FormGroup>
            </FormGroup>
          )}
          {accountType?.value === 4 && (
            <>
              {channelApproverInputList.map((item, index) => (
                <div
                  style={{
                    display: "flex",
                    gap: 8,
                    alignItems: "center",
                    width: "100%",
                  }}
                  key={index}
                >
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <Label className={classes.textFormTitle} for="Channel">
                      Channel
                    </Label>
                    <PureInputSelect
                      onChange={(val) =>
                        handleSelectChannelApproverList(
                          val as ChannelOpt,
                          index
                        )
                      }
                      options={approverChannelList}
                      defaultValue={{
                        label: item.name as string,
                        value: item.channel.id,
                      }}
                    />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <Label className={classes.textFormTitle} for="position">
                        Posisi
                      </Label>
                      <PureInputSelect
                        onChange={(val) =>
                          handleSelectPositionApproverList(val, index)
                        }
                        defaultValue={{
                          label: item.positionLabel as string,
                          value: item.position,
                        }}
                        options={populateApprovalCountOptions(
                          item.channel !== undefined
                            ? item.channel?.approvalCount
                            : 3
                        )}
                      />
                    </div>
                    {index > 0 && (
                      <div
                        onClick={() =>
                          handleDeleteApproverRelationChannelList(index)
                        }
                      >
                        <IoIosTrash
                          style={{ marginTop: 32, marginLeft: 8 }}
                          size={20}
                          color={COLORS.red_1}
                          cursor="pointer"
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
              {approverChannelList.length > 0 && (
                <button
                  type="button"
                  className={classes.addApproverButton}
                  disabled={approverChannelList.length === 0}
                  onClick={() => {
                    if (approverChannelList.length) {
                      setChannelApproverInputList((prev) => {
                        const nextChannel =
                          approverChannelList[approverChannelList.length - 1];
                        let obj: AddChannelRelationApproverReqObj = {
                          id: nextChannel.id,
                          channel: nextChannel,
                          name: nextChannel.description,
                          position: 1,
                          positionLabel: "1",
                          // name
                        };
                        return [...prev, obj];
                      });
                    }
                  }}
                >
                  Tambah Channel
                </button>
              )}
            </>
          )}
        </form>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <button
            style={{
              background: COLORS.gradientGray_1,
              width: 120,
              borderRadius: "5px",
              height: "40px",
              border: 0,
              color: "#fff",
              paddingLeft: 16,
              paddingRight: 16,
              fontFamily: "Raleway",
              fontSize: 12,
              fontWeight: 600,
            }}
            onClick={() => {
              setAccountType({ label: "", value: 0, id: 0, name: "" });
              toggle();
            }}
          >
            BATAL
          </button>
          <button
            style={{
              background: COLORS.gradientBlue_3,
              width: 120,
              borderRadius: "5px",
              height: "40px",
              border: 0,
              color: "#fff",
              fontFamily: "Raleway",
              fontSize: "12px",
              paddingLeft: 24,
              paddingRight: 24,
              fontWeight: 600,
            }}
            onClick={() => toggleSubmit()}
          >
            {isEditing ? "SIMPAN" : "BUAT"}
          </button>
        </div>
      </ModalBody>
    </CustomModal>
  );
};

export default AccountFormModal;
