import React, { FormEventHandler, useCallback, useState } from "react";
import { toast } from "react-toastify";
import {
  Form,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import useChangePasswordValidationSchema from "../../../../hooks/auth/useChangePasswordValidationSchema";
import useButtonStyles from "../../../../hooks/styles/useButtonStyles";
import useModalStyles from "../../../../hooks/styles/useModalStyles";
import CustomModal from "../CustomModal";
import * as yup from "yup";
import {
  useUpdateUserMutation,
  useUpdateUserPasswordMutation,
} from "../../../../redux/UserStore/userstoreAPI";
import { AUserData } from "../../../../types/user";
import { ErrorMessageBackendDataShape } from "../../../../types";

type Props = {
  isOpen: boolean;
  toggle: () => void;
  pickedAccount: AUserData;
};

const ChangePasswordModal = (props: Props) => {
  const { isOpen, toggle, pickedAccount } = props;
  const modal_classes = useModalStyles();
  const [newPassword, setNewPassword] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const btn_classes = useButtonStyles();
  const [retypeNewPassword, setRetypeNewPassword] = useState<string>("");
  const [updateUserPassword] = useUpdateUserPasswordMutation();
  const { schema: changePasswordValidationSchema } =
    useChangePasswordValidationSchema();
  const handleSubmitChangePassword: React.FormEventHandler<HTMLFormElement> =
    useCallback(
      (e) => {
        e.preventDefault();
        if (!pickedAccount) return;
        const data = {
          newPassword,
          retypeNewPassword,
        };
        changePasswordValidationSchema
          .validate(data)
          .then(() => {
            updateUserPassword({
              userId: pickedAccount.id,
              password: newPassword,
            })
              .then(() => {
                toggle();
                toast.success("Password berhasil diubah");
              })
              .catch((err: ErrorMessageBackendDataShape) => {
                if (err) {
                  toast.error("Gagal ubah password. " + err.data.message);
                } else {
                  toast.error(
                    "Error ketika ubah password. Silahkan coba kembali."
                  );
                }
              });
          })
          .catch((errors: yup.ValidationError) => {
            if (errors) {
              toast.error(`${errors.errors.join(", ")}`);
            } else {
              toast.error("Error ketika ubah password. Silahkan coba kembali.");
            }
          });
      },
      [newPassword, retypeNewPassword, pickedAccount]
    );
  return (
    <CustomModal
      isOpen={isOpen}
      toggle={toggle}
      className={modal_classes.modal}
    >
      <ModalHeader className={modal_classes.modalHeader} toggle={toggle}>
        <span>Ubah Password</span>
      </ModalHeader>
      <ModalBody>
        <Form id="editreservation" onSubmit={handleSubmitChangePassword}>
          <FormGroup style={{ paddingBottom: 12 }}>
            <Label style={{ fontWeight: 600, fontSize: 12 }}>
              Password Baru
            </Label>
            <Input
              style={{ height: "40px", borderRadius: "5px" }}
              onChange={(val) => setNewPassword(val.target.value)}
              placeholder="Password Baru..."
              id="new-password"
              name="new-password"
              autoComplete="new-password"
              value={newPassword}
              type="password"
            />
          </FormGroup>
          <FormGroup style={{ paddingBottom: 12 }}>
            <Label style={{ fontWeight: 600, fontSize: 12 }}>
              Ketik Ulang Password Baru
            </Label>
            <Input
              style={{ height: "40px", borderRadius: "5px" }}
              onChange={(val) => setRetypeNewPassword(val.target.value)}
              placeholder="Ketik Ulang Password Baru..."
              id="retype-new-password"
              name="retype-new-password"
              autoComplete="new-password"
              value={retypeNewPassword}
              type="password"
            />
          </FormGroup>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <button
              type="button"
              disabled={isSubmitting}
              onClick={() => toggle()}
              className={[btn_classes.button].join(" ")}
            >
              Batal
            </button>
            <button
              form="editreservation"
              type="submit"
              disabled={isSubmitting}
              className={[
                btn_classes.button,
                isSubmitting ? "disabled" : "primary",
              ].join(" ")}
              // onClick={handleSubmitChangePassword}
            >
              {isSubmitting ? "Loading..." : "Simpan"}
            </button>
          </div>
        </Form>
      </ModalBody>
    </CustomModal>
  );
};

export default ChangePasswordModal;
