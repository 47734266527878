import React, { useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BiHistory, BiTask } from "react-icons/bi";
import { BsCalendar2Range, BsThreeDotsVertical } from "react-icons/bs";
import { createUseStyles } from "react-jss";
import { add, endOfDay, format, startOfDay } from "date-fns";
import PageContainer from "../components/PageContainer/PageContainer";
import Ticket from "../components/atoms/Ticket";
import { ROUTES } from "../config/nav";
import useChannelOptions from "../hooks/channels/useChannelOptions";
import { useGetWIReservationListQuery } from "../redux/ReservationStore/walkinReservationStoreAPI";
import { COLORS } from "../assets/theme";
import LoadingItem from "../components/atoms/LoadingContainer/LoadingItem";
import useListContainerStyles from "../hooks/styles/useListContainerStyles";
import Header from "../components/atoms/Header";
import { AWEReservationParams, AWIReservation } from "../types/reservation";
import { useAppSelector } from "../redux";
import _ from "lodash";
import {
  DateRange,
  LocalizationProvider,
  MobileDateRangePicker,
} from "@material-ui/pickers";
import {
  CircularProgress,
  createTheme,
  ThemeProvider,
} from "@material-ui/core";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
} from "reactstrap";
import { IoIosClose, IoMdCloseCircle, IoMdSearch } from "react-icons/io";
import enLocale from "date-fns/locale/en-US";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import { dateIsInRange } from "../tools";
import { ChannelOpt } from "../types/channel";
import useLastElementInfiniteScroll from "../hooks/useLastElementInfiniteScroll";
const { orderBy } = _;

interface Props {}

const useStyles = createUseStyles({
  icon: {
    fontSize: "2em",
    cursor: "pointer",
    marginLeft: "10px",
  },
  searchWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  filteredButton: {
    padding: "0.5rem 0.75rem",
    width: "fit-content",
    marginBottom: 16,
    fontWeight: 600,
    borderRadius: 16,
    fontFamily: "Raleway",
    fontSize: "10px",
    background: COLORS.gradientBlue_3,
    border: 0,
    color: "white",
    // borderStyle: "solid",
    // borderColor: COLORS.gray,
    whiteSpace: "nowrap",
  },
  activeIcon: {
    color: COLORS.blue_1,
  },
  dateContainer: {
    flex: 1,
    display: "flex",
    border: 0,
    color: "black",
    fontFamily: "Raleway",
    fontWeight: 600,
    fontSize: "15px",
  },
  clearButtonDateRange: {
    "&::after": {
      position: "absolute",
      top: "-3px",
      right: "-8px",
    },
  },
});

const datePickertheme = createTheme({
  typography: {
    fontFamily: "Raleway",
    fontSize: 18,
  },
});

const ApproverHomePage: React.FunctionComponent<Props> = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useAppSelector((state) => state.userstore.userData);
  const currentDate = new Date();
  const tommorowDate = add(currentDate, { days: 1 });
  const lastSevenDaysDate = add(currentDate, { days: -7 });
  const [pickOpt, setPickOpt] = React.useState<number>(0);
  const [datePickerOpen, setDatePickerOpen] = React.useState<boolean>(false);
  const [selectedDateRangeDate, setSelectedDateRangeDate] = React.useState<
    DateRange<Date>
  >([currentDate, tommorowDate]);
  const handleDateRangeChange = (date: DateRange<Date>) => {
    const [startDate, endDate] = date;
    searchParams.set(
      "startDate",
      format(startDate !== null ? startDate : new Date(), "yyyy-MM-dd")
    );
    searchParams.set(
      "endDate",
      format(endDate !== null ? endDate : new Date(), "yyyy-MM-dd")
    );
    setSearchParams(searchParams);
    setSelectedDateRangeDate(date);
  };
  const [dropdownIsOpen, setDropdownIsOpen] = React.useState<boolean>(false);
  const [showSearchText, setShowSearchText] = React.useState<boolean>(false);
  const [searchInput, setSearchInput] = React.useState<string>("");
  const classes = useStyles();
  const { relationapproverchannel } =
    useAppSelector((state) => state.userstore.userData) ?? {};
  useEffect(() => {
    if (Number(searchParams?.get("code")) !== 0) {
      setPickOpt(Number(searchParams?.get("code")));
    }
  }, [searchParams]);

  const list_classes = useListContainerStyles();
  const navigate = useNavigate();
  const { arr: channelList } = useChannelOptions({ active: true });
  const [limit, setLimit] = React.useState<number>(10);
  const isHistory: boolean = searchParams.get("history") === "true";
  const startDate: string | null = searchParams.get("startDate");
  const endDate: string | null = searchParams.get("endDate");
  const channelCodeParams = useMemo(() => {
    const id = searchParams.get("code");
    return id ? parseInt(id) : undefined;
  }, [searchParams]);
  const reservationParams = React.useMemo(() => {
    const res: AWEReservationParams = {};
    if (pickOpt !== 0) {
      res.channelId = pickOpt;
    }

    return res;
  }, [pickOpt]);

  useEffect(() => {
    if (user && user.relationapproverchannel?.length === 1) {
      setPickOpt(user.relationapproverchannel[0]?.channelId ?? 0);
    }
  }, [user]);

  const opt = useMemo(() => {
    let ans: ChannelOpt[] = [];
    if (channelList) {
      ans = [
        ...channelList
          ?.sort((a, b) => a.description.localeCompare(b.description))
          .map(({ id, description, ...rest }) => {
            return {
              id,
              description,
              ...rest,
            };
          }),
      ];
      return ans;
    } else {
      return [];
    }
  }, [channelList]);

  const {
    data,
    isFetching: isFetchingReservation,
    isLoading: isLoadingReservation,
  } = useGetWIReservationListQuery(
    startDate && endDate
      ? {
          aftertime: format(
            startOfDay(new Date(startDate)),
            "yyyy-MM-dd hh:mm aaaaa'm"
          ),
          beforetime: format(
            endOfDay(new Date(endDate)),
            "yyyy-MM-dd hh:mm aaaaa'm"
          ),
          ...reservationParams,
          page: 1,
          take: limit,
        }
      : isHistory
      ? { ...reservationParams, page: 1, take: limit, isHistory: true }
      : { ...reservationParams, page: 1, take: limit, isList: true }
  );

  const lastElementRef = useLastElementInfiniteScroll({
    isFetching: isFetchingReservation,
    page: 1,
    meta: data?.meta,
    setLimit,
    limit,
  });

  let isPast = (date: Date | number) =>
    new Date(date).getTime() < startOfDay(new Date()).getTime();

  const reservationList = useMemo<Array<AWIReservation>>(() => {
    if (!data || !data.entities) return [];
    let list = [...data.entities];
    return list;
  }, [data]);

  const setOpenDropdown = () => {
    setDropdownIsOpen(!dropdownIsOpen);
  };

  const isLoading = isFetchingReservation || isLoadingReservation;

  return (
    <>
      <PageContainer
        className={list_classes.container}
        headerNavigationContent={
          <>
            {!showSearchText ? (
              <div className={list_classes.headerContainer}>
                <Header
                  title={`${isHistory ? "Riwayat" : "List"} Reservasi Approval`}
                  showBackButton
                  backButtonOnClick={() => {
                    navigate("/");
                  }}
                  rightButton={
                    <>
                      {isHistory && (
                        <>
                          <BsCalendar2Range
                            cursor={"pointer"}
                            color={COLORS.black_1}
                            size={16}
                            onClick={() => setDatePickerOpen(!datePickerOpen)}
                          />
                          <LocalizationProvider
                            dateAdapter={DateFnsAdapter}
                            locale={enLocale}
                          >
                            <ThemeProvider theme={datePickertheme}>
                              <MobileDateRangePicker
                                value={selectedDateRangeDate}
                                onChange={handleDateRangeChange}
                                open={datePickerOpen}
                                showToolbar={false}
                                renderInput={(props) => <></>}
                                onClose={() => {
                                  setDatePickerOpen(!datePickerOpen);
                                }}
                              />
                            </ThemeProvider>
                          </LocalizationProvider>
                        </>
                      )}
                      <Dropdown
                        style={{ marginLeft: 8 }}
                        isOpen={dropdownIsOpen}
                        toggle={setOpenDropdown}
                      >
                        <DropdownToggle
                          tag="span"
                          onClick={setOpenDropdown}
                          data-toggle="dropdown"
                          aria-expanded={dropdownIsOpen}
                        >
                          <BsThreeDotsVertical
                            size={16}
                            cursor="pointer"
                            color={COLORS.black_1}
                          />
                        </DropdownToggle>
                        <DropdownMenu style={{ marginTop: 6 }} end>
                          <DropdownItem
                            style={{
                              padding: 8,
                              display: "inline-flex",
                              alignItems: "center",
                              gap: 8,
                            }}
                            onClick={() => setShowSearchText(!showSearchText)}
                          >
                            <IoMdSearch size={16} color={COLORS.black_1} />
                            <span>Cari Reservasi</span>
                          </DropdownItem>
                          <DropdownItem
                            style={{
                              padding: 8,
                              display: "inline-flex",
                              alignItems: "center",
                              gap: 8,
                            }}
                            onClick={() => {
                              let current =
                                searchParams.get("history") === "true";
                              searchParams.set("history", "" + !current);
                              setSearchParams(searchParams);
                            }}
                          >
                            {!isHistory ? (
                              <>
                                <BiHistory size={16} color={COLORS.black_1} />
                                <span>Riwayat Reservasi</span>
                              </>
                            ) : (
                              <>
                                <BiTask size={16} color={COLORS.black_1} />
                                <span>List Reservasi</span>
                              </>
                            )}
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </>
                  }
                />
              </div>
            ) : (
              <div className={classes.searchWrapper} style={{ width: "100%" }}>
                <Input
                  id="search-approval-reservation"
                  name="search-approval-reservation"
                  placeholder="Cari tiket berdasarkan nomor kendaraan..."
                  type="text"
                  onChange={(val) => setSearchInput(val.target.value)}
                  style={{
                    height: "40px",
                    width: "100%",
                    border: "none",
                    borderBottom: `1px solid ${COLORS.gray_1}`,
                    boxShadow: "none",
                    borderRadius: 0,
                  }}
                />
                <IoIosClose
                  size={24}
                  cursor="pointer"
                  color={COLORS.black_1}
                  onClick={() => {
                    setSearchInput("");
                    setShowSearchText(!showSearchText);
                  }}
                />
              </div>
            )}
          </>
        }
        headerContent={
          <div className={list_classes.tagContainer}>
            {user &&
              user.relationapproverchannel &&
              user.relationapproverchannel.length > 1 && (
                <button
                  className={[
                    list_classes.tag,
                    pickOpt === 0 ? "active" : undefined,
                  ].join(" ")}
                  onClick={() => {
                    setPickOpt(0);
                    searchParams.delete("code");
                    setSearchParams(searchParams);
                  }}
                >
                  Semua
                </button>
              )}
            {opt
              .filter(
                ({ id }) =>
                  !!relationapproverchannel?.find(
                    ({ channel: relationApproveChannel }) =>
                      relationApproveChannel?.id === id
                  )
              )
              .map(({ id, description }) => {
                return (
                  <button
                    key={id}
                    className={[
                      list_classes.tag,
                      pickOpt === id ? "active" : undefined,
                    ].join(" ")}
                    onClick={() => {
                      setPickOpt(id);
                      if (id > 0) {
                        searchParams.set("code", String(id));
                      }
                      if (id === 0) {
                        searchParams.delete("code");
                      }
                      setSearchParams(searchParams);
                    }}
                  >
                    {description}
                  </button>
                );
              })}
          </div>
        }
        withBackground
      >
        <div
          className={list_classes.listContainer}
          style={{ minHeight: "100vh" }}
        >
          {startDate && endDate && (
            <button
              className={classes.filteredButton}
              onClick={() => {
                searchParams.delete("startDate");
                searchParams.delete("endDate");
                setSearchParams(searchParams);
              }}
            >
              Filtered by Date <IoMdCloseCircle size={16} />
            </button>
          )}
          {/* <LoadingItem isLoading={isFetchingReservation} /> */}
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress
                size={18}
                color="inherit"
                style={{ color: COLORS.blue_1 }}
              />
            </div>
          ) : (
            reservationList &&
            reservationList.map((reservation, idx) => {
              const ticketDate = reservation?.date;
              const { color } =
                channelList.find((val) => {
                  return val.id === reservation.channelId;
                }) ?? {};
              const tags = [
                {
                  desc: reservation.channel.description,
                  color: color ?? COLORS.green,
                },
              ];
              reservation.nopol &&
                tags.push({
                  desc: reservation.nopol,
                  color: COLORS.black_1,
                });
              switch (reservation.status) {
                case "approved":
                  tags.push({
                    color: COLORS.green_2,
                    desc: "Disetujui",
                  });
                  break;
                case "rejected":
                  tags.push({
                    color: COLORS.orange_2,
                    desc: "Ditolak",
                  });
                  break;
                case "pending":
                default:
                  tags.push({
                    color: isPast(new Date(reservation.date))
                      ? COLORS.gray_3
                      : COLORS.red_1,
                    desc: isPast(new Date(reservation.date))
                      ? "Kadaluarsa"
                      : `Menunggu Persetujuan - Approver ${
                          reservation.approverPosition + 1
                        }`,
                  });
                  break;
              }

              return (
                <Ticket
                  key={idx}
                  onClickTicket={() => {
                    navigate(`${ROUTES.approver}/${reservation?.id}`);
                  }}
                  ticketLabel={"" + reservation.qrid}
                  ticketDate={ticketDate}
                  tags={tags}
                />
              );
            })
          )}
          {reservationList?.length === 0 ? (
            <div style={{ textAlign: "center", width: "100%" }}>
              <span>Tidak ada Approval Reservasi</span>
            </div>
          ) : (
            isHistory && (
              <div style={{ textAlign: "center", width: "100%" }}>
                <span style={{ fontStyle: "italic", color: COLORS.gray }}>
                  Riwayat Reservasi Walk-In yang ditampilkan adalah 7 hari
                  terakhir.
                </span>
              </div>
            )
          )}
        </div>
      </PageContainer>
    </>
  );
};

export default ApproverHomePage;
