import { makeStyles } from "@material-ui/core";
import React from "react";
import { createUseStyles } from "react-jss";
import { COLORS } from "../../assets/theme";

const useButtonStyles = makeStyles({
  button: {
    fontWeight: 600,
    padding: "1rem",
    border: 0,
    background: COLORS.gradientGray_1,
    borderRadius: "0.4rem",
    textTransform: "uppercase",
    letterSpacing: "0.02rem",
    flexBasis: "12rem",
    "&.danger": {
      background: COLORS.red_1,
      color: "white",
    },
    "&.success": {
      background: COLORS.green_3,
      color: "white",
    },
    "&.primary": {
      background: COLORS.gradientBlue_2,
      color: "white",
    },
    "&.Mui-disabled": {
      background: COLORS.gradientGray_1,
      color: COLORS.gray_2,
    },
    "&:hover": {
      opacity: 0.7,
      cursor: "pointer",
    },
  },
  uploadPhotosButton: {
    padding: "1.4rem 1.8rem",
    background: "transparent",
    border: `1px solid ${COLORS.black_1}`,
    borderRadius: "0.65rem",
    transition: "all .2s",
    "&:hover": {
      background: COLORS.black_1,
      color: COLORS.white,
    },
  },
});

export default useButtonStyles;
