import { Fragment, useCallback, useEffect, useState } from "react";
import { SetStateAction } from "react";
import { toast } from "react-toastify";
import { FormGroup, Input, Label, ModalBody, ModalHeader } from "reactstrap";
import { COLORS } from "../../../../assets/theme";
import useModalStyles from "../../../../hooks/styles/useModalStyles";
import {
  useCreateChannelMutation,
  useUpdateChannelMutation,
} from "../../../../redux/ChannelStore/channelstoreAPI";
import { ErrorMessageBackendDataShape } from "../../../../types";
import {
  AChannel,
  AChannelValidation,
  ChannelCreateObj,
  ChannelUpdateObj,
} from "../../../../types/channel";
import IndeterminateCheckbox from "../../IndeterminateCheckbox";
import CustomModal from "../CustomModal";

type Props = {
  selectedChannel?: AChannel;
  isOpen: boolean;
  toggle: () => void;
  onSuccess?: () => void;
  isEditing?: boolean;
};

const channelValidationDefaultVal: AChannelValidation = {
  active: false,
  optional: true,
};

const ManageChannelFormModal = (props: Props) => {
  const { isEditing, toggle, isOpen, selectedChannel } = props;
  const modal_classes = useModalStyles();
  const [channelName, setChannelName] = useState<string>(
    selectedChannel?.channel ?? ""
  );
  const [channelDescription, setChannelDescription] = useState<string>(
    selectedChannel?.description ?? ""
  );
  const [approvalCount, setApprovalCount] = useState<string>(
    selectedChannel?.approvalCount.toString() ?? ""
  );

  const [editChannelStatus, SetEditChannelStatus] = useState<boolean>(
    selectedChannel?.active ?? false
  );
  const [statusMaterial, setStatusMaterial] = useState<AChannelValidation>(
    channelValidationDefaultVal
  );
  const [statusPo, setStatusPo] = useState<AChannelValidation>(
    channelValidationDefaultVal
  );
  const [statusPoLine, setStatusPoLine] = useState<AChannelValidation>(
    channelValidationDefaultVal
  );
  const [statusTruckValidation, setStatusTruckValidation] =
    useState<boolean>(false);
  const [statusPoValidation, setStatusPoValidation] = useState<boolean>(false);
  const [statusTruck, setStatusTruck] = useState<AChannelValidation>(
    channelValidationDefaultVal
  );
  const [statusDriverName, setStatusDriverName] = useState<AChannelValidation>(
    channelValidationDefaultVal
  );
  const [statusDriverPhoneNumber, setstatusDriverPhoneNumber] =
    useState<AChannelValidation>(channelValidationDefaultVal);
  const [statusTruckBlacklist, setStatusTruckBlacklist] =
    useState<boolean>(false);
  const [statusContainer, setStatusContainer] = useState<AChannelValidation>(
    channelValidationDefaultVal
  );
  const [statusDeliveryNote, setStatusDeliveryNote] =
    useState<AChannelValidation>(channelValidationDefaultVal);
  const [statusPhoto, setStatusPhoto] = useState<AChannelValidation>(
    channelValidationDefaultVal
  );
  const [statusShipmentNumber, setStatusShipmentNumber] =
    useState<AChannelValidation>(channelValidationDefaultVal);
  const [statusVendor, setStatusVendor] = useState<AChannelValidation>(
    channelValidationDefaultVal
  );

  const handleChangeEditStatus = (e: string) => {
    if (e === "true") {
      SetEditChannelStatus(true);
    } else {
      SetEditChannelStatus(false);
    }
  };

  useEffect(() => {
    if (selectedChannel && isEditing && isOpen) {
      setStatusDriverName(selectedChannel.statusDriverName);
      setStatusTruckBlacklist(selectedChannel.statusTruckBlacklist);
      setStatusVendor(selectedChannel.statusVendor);
      setStatusContainer(selectedChannel.statusContainer);
      setStatusDeliveryNote(selectedChannel.statusDeliveryNote);
      setStatusPo(selectedChannel.statusPo);
      SetEditChannelStatus(selectedChannel.active);
      setStatusPoLine(selectedChannel.statusPo);
      setStatusTruckValidation(
        selectedChannel.statusTruckValidation
          ? selectedChannel.statusTruckValidation
          : false
      );
      setStatusPoValidation(
        selectedChannel.statusPoValidation
          ? selectedChannel.statusPoValidation
          : false
      );
      setStatusShipmentNumber(selectedChannel.statusShipmentNumber);
      setStatusMaterial(selectedChannel.statusMaterial);
      setStatusPhoto(selectedChannel.statusPhoto);
      setstatusDriverPhoneNumber(
        selectedChannel.statusDriverPhoneNumber ?? channelValidationDefaultVal
      );
    } else {
      setStatusPoValidation(false);
      setStatusTruckValidation(false);
      setStatusDriverName(channelValidationDefaultVal);
      setstatusDriverPhoneNumber(channelValidationDefaultVal);
      setStatusTruck(channelValidationDefaultVal);
      setStatusTruckBlacklist(false);
      setStatusVendor(channelValidationDefaultVal);
      setStatusContainer(channelValidationDefaultVal);
      setStatusDeliveryNote(channelValidationDefaultVal);
      setStatusPo(channelValidationDefaultVal);
      setStatusPoLine(channelValidationDefaultVal);
      setStatusMaterial(channelValidationDefaultVal);
      setStatusPhoto(channelValidationDefaultVal);
      setStatusShipmentNumber(channelValidationDefaultVal);
    }
  }, [selectedChannel, isOpen, isEditing]);

  const [createChannel] = useCreateChannelMutation();
  const [updateChannel] = useUpdateChannelMutation();

  const handleManageChannel = useCallback(() => {
    if (!isEditing) {
      const obj: ChannelCreateObj = {
        channel: channelName,
        description: channelDescription,
        active: true,
        approvalCount: Number(approvalCount) === 0 ? 0 : Number(approvalCount),
        statusTruck: {
          active: true,
          optional: false,
        },
        statusTruckBlacklist: statusTruckBlacklist,
        statusDriverName: statusDriverName,
        statusVendor: statusVendor,
        statusMaterial: statusMaterial,
        statusPo: statusPo,
        statusDeliveryNote: statusDeliveryNote,
        statusContainer: statusContainer,
        statusShipmentNumber: statusShipmentNumber,
        statusPhoto: statusPhoto,
        statusTruckValidation: statusTruckValidation,
        statusPoValidation: statusPoValidation,
      };
      createChannel(obj)
        .unwrap()
        .then(() => {
          toggle();
          toast("Channel baru berhasil ditambahkan", { type: "success" });
        })
        .catch((e: ErrorMessageBackendDataShape) => {
          if (e) {
            toast("Error! " + e.data.message, {
              type: "error",
            });
          } else {
            toast(
              "Error saat menambahkan channel. Periksa kembali form anda.",
              {
                type: "error",
              }
            );
          }
        });
    } else {
      const obj: ChannelUpdateObj = {
        id: selectedChannel?.id ?? 0,
        channel: channelName,
        description: channelDescription,
        active: editChannelStatus,
        approvalCount: Number(approvalCount),
        statusTruckBlacklist: statusTruckBlacklist,
        statusDriverPhoneNumber: statusDriverPhoneNumber,
        statusDriverName: statusDriverName,
        statusVendor: statusVendor,
        statusMaterial: statusMaterial,
        statusPo: statusPo,
        statusDeliveryNote: statusDeliveryNote,
        statusContainer: statusContainer,
        statusShipmentNumber: statusShipmentNumber,
        statusPhoto: statusPhoto,
        statusTruckValidation: statusTruckValidation,
        statusPoValidation: statusPoValidation,
        statusTruck: {
          active: true,
          optional: false,
        },
      };
      updateChannel(obj)
        .unwrap()
        .then(() => {
          toggle();
          toast("Channel berhasil diubah", { type: "success" });
        })
        .catch((e: ErrorMessageBackendDataShape) => {
          toast("Error! " + e.data.message, {
            type: "error",
          });
        });
    }
  }, [
    channelName,
    channelDescription,
    statusPo,
    statusPhoto,
    statusShipmentNumber,
    statusContainer,
    statusDeliveryNote,
    statusMaterial,
    statusVendor,
    editChannelStatus,
    statusTruckBlacklist,
    approvalCount,
    createChannel,
    isEditing,
    selectedChannel?.id,
    statusDriverName,
    statusTruck,
    toggle,
    updateChannel,
    statusPoValidation,
    statusDriverPhoneNumber,
    statusTruckValidation,
  ]);

  const stateActionActive: SetStateAction<AChannelValidation> = (prev) => {
    let nextActive = !prev.active;
    return {
      active: nextActive,
      optional: nextActive ? prev.optional : true,
    };
  };

  const stateActionOptional: SetStateAction<AChannelValidation> = ({
    active,
    optional,
  }) => {
    return {
      // active: prev
      active,
      optional: !optional,
    };
  };

  const formId = isEditing ? "editchannel" : "createchannel";

  return (
    <CustomModal
      className={modal_classes.modal}
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalHeader className={modal_classes.modalHeader} toggle={toggle}>
        <span>{isEditing ? "Ubah" : "Buat"} Channel</span>
      </ModalHeader>
      <ModalBody>
        <form
          id={formId}
          className={modal_classes.modalFormWrapper}
          onSubmit={(e) => {
            e.preventDefault();
            handleManageChannel();
          }}
        >
          <Label
            style={{
              fontSize: "12px",
              marginTop: "20px",
              fontWeight: 600,
            }}
          >
            Channel
          </Label>
          <Input
            defaultValue={channelName}
            onChange={(val) => setChannelName(`${val.target.value}`)}
            style={{ height: "40px", borderRadius: "5px" }}
            id="channelName"
            name="channelName"
            placeholder="Nama Channel..."
          />
          <Label
            style={{
              fontSize: "12px",
              marginTop: "20px",
              fontWeight: 600,
            }}
          >
            Deskripsi
          </Label>
          <Input
            defaultValue={channelDescription}
            onChange={(val) => setChannelDescription(val.target.value)}
            style={{ height: "40px", borderRadius: "5px" }}
            id="channelDescription"
            name="channelDescription"
            placeholder="Deskripsi Channel..."
          />
          <Label
            style={{
              fontSize: "12px",
              marginTop: "20px",
              fontWeight: 600,
            }}
          >
            Jumlah Approval
          </Label>
          <Input
            defaultValue={approvalCount}
            onChange={(val) => {
              setApprovalCount(val.target.value);
            }}
            style={{ height: "40px", borderRadius: "5px" }}
            id="approvalCount"
            name="approvalCount"
            pattern="[0-9]*"
            placeholder="Jumlah Approval minimal 1..."
          />
          {isEditing && (
            <Fragment>
              <Label
                style={{
                  fontSize: "12px",
                  marginTop: "20px",
                  fontWeight: 600,
                }}
              >
                Channel Aktif
              </Label>
              <FormGroup
                style={{
                  display: "flex",
                  gap: 32,
                  alignItems: "center",
                }}
                tag="fieldset"
              >
                <FormGroup check>
                  <Input
                    name="active"
                    type="radio"
                    value={"true"}
                    checked={editChannelStatus === true}
                    onChange={(val) => handleChangeEditStatus(val.target.value)}
                  />
                  <Label
                    style={{
                      fontFamily: "Raleway",
                      fontSize: "12px",
                      fontWeight: 600,
                    }}
                    check
                  >
                    Aktif
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Input
                    name="active"
                    type="radio"
                    checked={editChannelStatus === false}
                    value={"false"}
                    onChange={(val) => {
                      handleChangeEditStatus(val.target.value);
                    }}
                  />
                  <Label
                    style={{
                      fontFamily: "Raleway",
                      fontSize: "12px",
                      fontWeight: 600,
                    }}
                    check
                  >
                    Tidak Aktif
                  </Label>
                </FormGroup>
              </FormGroup>
            </Fragment>
          )}
          <Fragment>
            <Label
              style={{
                fontSize: "12px",
                marginTop: isEditing ? 8 : 16,
                fontWeight: 600,
              }}
            >
              Cek Blacklist Truck ?
            </Label>
            <div style={{ display: "flex", gap: 16 }}>
              <IndeterminateCheckbox
                name="blackListTruckActive"
                containerStyle={{ marginRight: 46 * 2 }}
                label="Aktif"
                checked={statusTruckBlacklist}
                defaultChecked={statusTruckBlacklist}
                onChange={() => setStatusTruckBlacklist(!statusTruckBlacklist)}
              />
            </div>
          </Fragment>
          <Fragment>
            <Label
              style={{
                fontSize: "12px",
                marginTop: "20px",
                fontWeight: 600,
              }}
            >
              Cek Validasi Nopol Truk ?
            </Label>
            <div style={{ display: "flex", gap: 16 }}>
              <IndeterminateCheckbox
                name="statusTruckValidationActive"
                containerStyle={{ marginRight: 46 * 2 }}
                label="Aktif"
                checked={statusTruckValidation}
                defaultChecked={statusTruckValidation}
                onChange={() =>
                  setStatusTruckValidation(!statusTruckValidation)
                }
              />
            </div>
          </Fragment>
          <Fragment>
            <Label
              style={{
                fontSize: "12px",
                marginTop: "20px",
                fontWeight: 600,
              }}
            >
              Cek Validasi Nomor PO ?
            </Label>
            <div style={{ display: "flex", gap: 16 }}>
              <IndeterminateCheckbox
                name="statusPoValidationActive"
                containerStyle={{ marginRight: 46 * 2 }}
                label="Aktif"
                checked={statusPoValidation}
                defaultChecked={statusPoValidation}
                onChange={() => setStatusPoValidation(!statusPoValidation)}
              />
            </div>
          </Fragment>
          <Fragment>
            <Label
              style={{
                fontSize: "12px",
                marginTop: "20px",
                fontWeight: 600,
              }}
            >
              Input Nama Supir ?
            </Label>
            <div style={{ display: "flex", gap: 16 }}>
              <IndeterminateCheckbox
                name="driverNameActive"
                containerStyle={{ marginRight: 46 * 2 }}
                label="Aktif"
                checked={statusDriverName.active}
                onChange={() => setStatusDriverName(stateActionActive)}
              />
              <IndeterminateCheckbox
                name="driverNameMandatory"
                label="Wajib diisi"
                checked={!statusDriverName.optional}
                disabled={!statusDriverName.active}
                onChange={() => setStatusDriverName(stateActionOptional)}
              />
            </div>
          </Fragment>
          {statusDriverName.active && (
            <Fragment>
              <Label
                style={{
                  fontSize: "12px",
                  marginTop: "20px",
                  fontWeight: 600,
                }}
              >
                Input Nomor Telepon Supir ?
              </Label>
              <div style={{ display: "flex", gap: 16 }}>
                <IndeterminateCheckbox
                  name="driverNameActive"
                  containerStyle={{ marginRight: 46 * 2 }}
                  label="Aktif"
                  checked={statusDriverPhoneNumber.active}
                  onChange={() => setstatusDriverPhoneNumber(stateActionActive)}
                />
                <IndeterminateCheckbox
                  name="driverNameMandatory"
                  label="Wajib diisi"
                  checked={!statusDriverPhoneNumber.optional}
                  disabled={!statusDriverPhoneNumber.active}
                  onChange={() =>
                    setstatusDriverPhoneNumber(stateActionOptional)
                  }
                />
              </div>
            </Fragment>
          )}
          <Fragment>
            <Label
              style={{
                fontSize: "12px",
                marginTop: "20px",
                fontWeight: 600,
              }}
            >
              Input Vendor ?
            </Label>
            <div style={{ display: "flex", gap: 16 }}>
              <IndeterminateCheckbox
                name="vendorActive"
                containerStyle={{ marginRight: 46 * 2 }}
                label="Aktif"
                checked={statusVendor.active}
                onChange={() => setStatusVendor(stateActionActive)}
              />
              <IndeterminateCheckbox
                name="vendorMandatory"
                label="Wajib diisi"
                checked={!statusVendor.optional}
                disabled={!statusVendor.active}
                onChange={() => setStatusVendor(stateActionOptional)}
              />
            </div>
          </Fragment>
          <Fragment>
            <Label
              style={{
                fontSize: "12px",
                marginTop: "20px",
                fontWeight: 600,
              }}
            >
              Input Jenis Barang ?
            </Label>
            <div style={{ display: "flex", gap: 16 }}>
              <IndeterminateCheckbox
                name="materialActive"
                containerStyle={{ marginRight: 46 * 2 }}
                label="Aktif"
                checked={statusMaterial.active}
                onChange={() => setStatusMaterial(stateActionActive)}
              />
              <IndeterminateCheckbox
                name="materialMandatory"
                label="Wajib diisi"
                checked={!statusMaterial.optional}
                disabled={!statusMaterial.active}
                onChange={() => setStatusMaterial(stateActionOptional)}
              />
            </div>
          </Fragment>
          <Fragment>
            <Label
              style={{
                fontSize: "12px",
                marginTop: "20px",
                fontWeight: 600,
              }}
            >
              Input Nomor PO ?
            </Label>
            <div style={{ display: "flex", gap: 16 }}>
              <IndeterminateCheckbox
                name="poNumberActive"
                containerStyle={{ marginRight: 46 * 2 }}
                label="Aktif"
                checked={statusPo.active}
                onChange={() => setStatusPo(stateActionActive)}
              />
              <IndeterminateCheckbox
                name="poNumberMandatory"
                label="Wajib diisi"
                checked={!statusPo.optional}
                disabled={!statusPo.active}
                onChange={() => setStatusPo(stateActionOptional)}
              />
            </div>
          </Fragment>
          <Fragment>
            <Label
              style={{
                fontSize: "12px",
                marginTop: "20px",
                fontWeight: 600,
              }}
            >
              Input Delivery Note ?
            </Label>
            <div style={{ display: "flex", gap: 16 }}>
              <IndeterminateCheckbox
                name="deliveryNoteActive"
                containerStyle={{ marginRight: 46 * 2 }}
                label="Aktif"
                checked={statusDeliveryNote.active}
                onChange={() => setStatusDeliveryNote(stateActionActive)}
              />
              <IndeterminateCheckbox
                name="deliveryNoteMandatory"
                label="Wajib diisi"
                checked={!statusDeliveryNote.optional}
                disabled={!statusDeliveryNote.active}
                onChange={() => setStatusDeliveryNote(stateActionOptional)}
              />
            </div>
          </Fragment>
          <Fragment>
            <Label
              style={{
                fontSize: "12px",
                marginTop: "20px",
                fontWeight: 600,
              }}
            >
              Input Container ?
            </Label>
            <div style={{ display: "flex", gap: 16 }}>
              <IndeterminateCheckbox
                name="containerActive"
                containerStyle={{ marginRight: 46 * 2 }}
                label="Aktif"
                checked={statusContainer.active}
                onChange={() => setStatusContainer(stateActionActive)}
              />
              <IndeterminateCheckbox
                name="containerMandatory"
                label="Wajib diisi"
                checked={!statusContainer.optional}
                disabled={!statusContainer.active}
                onChange={() => setStatusContainer(stateActionOptional)}
              />
            </div>
          </Fragment>
          <Fragment>
            <Label
              style={{
                fontSize: "12px",
                marginTop: "20px",
                fontWeight: 600,
              }}
            >
              Input Shipment No. ?
            </Label>
            <div style={{ display: "flex", gap: 16 }}>
              <IndeterminateCheckbox
                name="shipmentActive"
                containerStyle={{ marginRight: 46 * 2 }}
                label="Aktif"
                checked={statusShipmentNumber.active}
                onChange={() => setStatusShipmentNumber(stateActionActive)}
              />
              <IndeterminateCheckbox
                name="shipmentMandatory"
                label="Wajib diisi"
                checked={!statusShipmentNumber.optional}
                disabled={!statusShipmentNumber.active}
                onChange={() => setStatusShipmentNumber(stateActionOptional)}
              />
            </div>
          </Fragment>
          <Fragment>
            <Label
              style={{
                fontSize: "12px",
                marginTop: "20px",
                fontWeight: 600,
              }}
            >
              Unggah Foto ?
            </Label>
            <div style={{ display: "flex", gap: 16 }}>
              <IndeterminateCheckbox
                name="photoActive"
                containerStyle={{ marginRight: 46 * 2 }}
                label="Aktif"
                checked={statusPhoto.active}
                onChange={() => setStatusPhoto(stateActionActive)}
              />
              <IndeterminateCheckbox
                name="photoMandatory"
                label="Wajib diisi"
                checked={!statusPhoto.optional}
                disabled={!statusPhoto.active}
                onChange={() => setStatusPhoto(stateActionOptional)}
              />
            </div>
          </Fragment>
        </form>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <button
            style={{
              background: COLORS.gradientGray_1,
              width: 120,
              borderRadius: "5px",
              height: "40px",
              border: 0,
              color: "#fff",
              fontFamily: "Raleway",
              fontSize: "12px",
              fontWeight: 600,
            }}
            onClick={() => {
              toggle();
            }}
          >
            BATAL
          </button>
          <button
            style={{
              background: COLORS.gradientBlue_3,
              width: 120,
              borderRadius: "5px",
              height: "40px",
              border: 0,
              color: "#fff",
              fontFamily: "Raleway",
              fontSize: "12px",
              fontWeight: 600,
            }}
            type="submit"
            form={formId}
            // onClick={() => toggleSubmit()}
          >
            {isEditing ? "SIMPAN" : "BUAT"}
          </button>
        </div>
      </ModalBody>
    </CustomModal>
  );
};

export default ManageChannelFormModal;
