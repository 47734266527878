import React, { useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { COLORS } from "../../../assets/theme";
import PageContainer from "../../../components/PageContainer/PageContainer";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { IoIosArrowRoundBack, IoIosSearch } from "react-icons/io";
import {
  ASchedule,
  SchedulesCreateObj,
  UpdateSchedulesObj,
} from "../../../types/schedule";
import { ModalBody, Label, Input, Row, Col, ModalHeader } from "reactstrap";
import AddButton from "../../../components/atoms/AddButton";
import {
  useCreateScheduleMutation,
  useGetScheduleQuery,
  useUpdateScheduleMutation,
} from "../../../redux/ScheduleStore/schedulestoreAPI";
import { useAdminSchedulePageStyle } from "./style";
import { useGetChannelsQuery } from "../../../redux/ChannelStore/channelstoreAPI";
import SwitchButton from "../../../components/atoms/SwitchButton/SwitchButton";
import { BiPencil, BiTrash } from "react-icons/bi";
import { ButtonBase, CircularProgress } from "@material-ui/core";
import CustomModal from "../../../components/atoms/Modals/CustomModal";
import useModalStyles from "../../../hooks/styles/useModalStyles";
import { ErrorMessageBackendDataShape } from "../../../types";
interface Props {}

/**
 * @todo untuk page yang terdiri dari list, style pagenya tolong disesuaikan. mungkin bisa lihat `useListContainerStyles` untuk classNames
 */
const AdminScheduleListPage: React.FunctionComponent<Props> = () => {
  const [searchParams] = useSearchParams();
  const channelId = searchParams.get("channelId");
  const params = useParams<"schedulegroupId">();
  const { schedulegroupId } = params;
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const [showSearchModal, setShowSearchModal] = React.useState<boolean>(false);
  const [startTime, setStartTime] = React.useState<string>();
  const [endTime, setEndTime] = React.useState<string>();
  const [startTimeSearch, setStartTimeSearch] = React.useState<string>("");
  const [quotaVal, setQuotaVal] = React.useState<number>();
  const [scheduleInputList, setScheduleInputList] = React.useState<
    Omit<ASchedule, "schedulegroupId">[]
  >([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const modal_classes = useModalStyles();
  const navigate = useNavigate();
  const classes = useAdminSchedulePageStyle();
  const [createSchedule] = useCreateScheduleMutation();
  const [updateSchedule] = useUpdateScheduleMutation();
  const { data: channelData } = useGetChannelsQuery({ id: Number(channelId) });
  const { data: scheduleList, isError: isErrorGetSchedule } =
    useGetScheduleQuery({
      schedulegroupId: schedulegroupId,
      channelId: Number(channelId),
    });

  useEffect(() => {
    if (isErrorGetSchedule) {
      navigate("/superadmin/schedule");
    }
  }, [isErrorGetSchedule]);
  useEffect(() => {
    if (scheduleList && showEditModal) {
      if (!scheduleList) return;
      let arr: Omit<ASchedule, "schedulegroupId">[] = [...scheduleList];
      setScheduleInputList(
        arr.map((item) => {
          return {
            id: item.id,
            clientcode: item.clientcode,
            channelId: item.channelId,
            from: item.from,
            to: item.to,
            quota: item.quota,
            active: item.active,
            delete: item.delete === undefined ? false : item.delete,
          };
        })
      );
    } else {
      setScheduleInputList([]);
    }
  }, [scheduleList, showEditModal]);

  function handleInputScheduleItem<
    K extends keyof ASchedule,
    V extends ASchedule[K]
  >(key: K, index: number, value: V) {
    let list = [...scheduleInputList];
    list[index] = { ...list[index], [key]: value };
    setScheduleInputList(list);
  }

  const scheduleDataList = useMemo(() => {
    if (!scheduleList) return;
    const returnVal: ASchedule[] = [];
    scheduleList.forEach((item) => returnVal.push(item));
    const filtered = returnVal.filter(({ from, to }) => {
      const splitHourFrom = `${from.split(":")}`;
      const splitHourTo = `${to.split(":")}`;
      const splitHourStartTimeSearch = `${startTimeSearch.split(":")}`;
      let minsTo = parseInt(splitHourTo[0]) * 60 + parseInt(splitHourTo);
      let minsFrom = parseInt(splitHourFrom[0]) * 60 + parseInt(splitHourFrom);
      let minsStartTimeSearch =
        parseInt(splitHourStartTimeSearch[0]) * 60 +
        parseInt(splitHourStartTimeSearch);
      return minsStartTimeSearch >= minsFrom && minsTo >= minsStartTimeSearch;
    });
    if (startTimeSearch && showSearchModal === false) {
      return filtered;
    } else if (showSearchModal === false && filtered.length === 0) {
      return returnVal;
    } else {
      return returnVal;
    }
  }, [scheduleList, startTimeSearch, showSearchModal]);

  const handleSearchFilterSchedulebyHour = React.useCallback(() => {
    setShowSearchModal(false);
  }, []);

  const handleCreateSchedule = React.useCallback(() => {
    //Create Schedule
    setIsLoading(true);
    const data: SchedulesCreateObj = {
      channelId: Number(channelId),
      from: `${startTime}:00`,
      to: `${endTime}:00`,
      quota: quotaVal ?? 0,
      active: true,
      schedulegroupId: Number(schedulegroupId),
    };
    createSchedule(data)
      .unwrap()
      .then(() => {
        setShowModal(false);
        setIsLoading(false);
        toast("Jadwal berhasil dibuat", {
          type: "success",
        });
      })
      .catch(() => {
        setShowModal(false);
        setIsLoading(false);
        toast("Gagal membuat jadwal karena jam tidak valid.", {
          type: "error",
        });
      });
  }, [
    channelId,
    endTime,
    startTime,
    quotaVal,
    createSchedule,
    schedulegroupId,
  ]);

  const handleUpdateSchedule = React.useCallback(() => {
    //Update Schedule
    setIsLoading(true);
    const data: UpdateSchedulesObj = {
      channelId: Number(channelId),
      schedulegroupId: Number(schedulegroupId),
      schedules: scheduleInputList,
    };
    updateSchedule(data)
      .unwrap()
      .then(() => {
        setShowEditModal(false);
        setIsLoading(false);
        toast("Berhasil mengubah jadwal", {
          type: "success",
        });
      })
      .catch((e: ErrorMessageBackendDataShape) => {
        setIsLoading(false);
        toast("Gagal mengubah jadwal. Jam tidak valid.", {
          type: "error",
        });
      });
  }, [scheduleInputList, updateSchedule, channelId]);

  useEffect(() => {
    if (!showModal) {
      setStartTime(undefined);
      setEndTime(undefined);
      setQuotaVal(undefined);
    }
  }, [showModal]);

  return (
    <PageContainer
      className={classes.container}
      headerNavigationContent={
        <>
          <div className={classes.headerSideContainer}>
            <ButtonBase
              className={classes.backButton}
              onClick={() => navigate("/superadmin/schedule")}
            >
              <IoIosArrowRoundBack size={24} />
            </ButtonBase>
          </div>
          <div className={classes.headerTitleContainer}>
            <p className={classes.textTitle}>Manajemen Jadwal</p>
          </div>
          <div className={classes.headerSideContainer}>
            <IoIosSearch
              fontSize={18}
              style={{ marginRight: 14 }}
              color={COLORS.black_1}
              cursor="pointer"
              onClick={() => setShowSearchModal(!showSearchModal)}
            />
            <BiPencil
              style={{ marginRight: 6 }}
              fontSize={16}
              color={COLORS.black_1}
              cursor="pointer"
              onClick={() => setShowEditModal(!showEditModal)}
            />
          </div>
        </>
      }
      withBackground
    >
      <div
        className={classes.scheduleListContainer}
        style={{ minHeight: "100vh" }}
      >
        {scheduleDataList &&
          scheduleDataList?.map((item) => {
            return (
              <ButtonBase
                className={classes.scheduleCardContainer}
                key={item.id}
                style={{
                  background:
                    item.active === false
                      ? COLORS.white
                      : COLORS.gradientGreen_4,
                  border:
                    item.active === false
                      ? "1px solid " + COLORS.gray_1
                      : "none",
                  color: !!item.active ? COLORS.black_1 : COLORS.gray_2,
                  justifyContent: "center",
                  padding: 16,
                  fontWeight: 600,
                }}
              >
                {item.from} sd {item.to}, Kuota: {item.quota}
              </ButtonBase>
            );
          })}
        {scheduleDataList?.length === 0 && (
          <div style={{ textAlign: "center", width: "100%" }}>
            <span>Tidak ada jadwal yang ditemukan</span>
          </div>
        )}
      </div>
      <AddButton onClick={() => setShowModal(!showModal)} />
      <CustomModal
        isOpen={showSearchModal}
        toggle={() => setShowSearchModal(!showSearchModal)}
        className={modal_classes.modal}
      >
        <ModalHeader
          className={modal_classes.modalHeader}
          toggle={() => setShowSearchModal(!showSearchModal)}
        >
          <p style={{ marginBottom: 0 }}>Cari Jadwal</p>
        </ModalHeader>
        <ModalBody>
          <div>
            <Label
              style={{
                fontFamily: "Raleway",
                fontSize: "12px",
                marginTop: "20px",
                fontWeight: 600,
              }}
            >
              Rentang Waktu
            </Label>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                marginBottom: "20px",
              }}
            >
              <div style={{ flex: 1 }}>
                <Input
                  defaultValue={startTimeSearch}
                  onChange={(val) => setStartTimeSearch(val.target.value)}
                  type={"time"}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "20px",
              justifyContent: "space-evenly",
              display: "flex",
            }}
          >
            <button
              style={{
                background: COLORS.gradientGray_1,
                width: 120,
                borderRadius: "5px",
                height: "40px",
                border: 0,
                color: "#fff",
                fontFamily: "Raleway",
                fontSize: "12px",
                fontWeight: 600,
              }}
              onClick={() => {
                setShowSearchModal(false);
                setStartTimeSearch("");
              }}
            >
              BATAL
            </button>
            <button
              style={{
                background: COLORS.gradientBlue_3,
                width: 120,
                borderRadius: "5px",
                height: "40px",
                border: 0,
                color: "#fff",
                fontFamily: "Raleway",
                fontSize: "12px",
                fontWeight: 600,
              }}
              onClick={handleSearchFilterSchedulebyHour}
            >
              CARI
            </button>
          </div>
        </ModalBody>
      </CustomModal>
      <CustomModal
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
        className={modal_classes.modal}
      >
        <ModalHeader
          className={modal_classes.modalHeader}
          toggle={() => setShowModal(!showModal)}
        >
          <p style={{ marginBottom: 0 }}>Buat Jadwal</p>
        </ModalHeader>
        <ModalBody>
          <div>
            <Label
              style={{
                fontFamily: "Raleway",
                fontSize: "12px",
                marginTop: "20px",
                fontWeight: 600,
              }}
            >
              Rentang Waktu
            </Label>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                marginBottom: "20px",
              }}
            >
              <div style={{ flex: 0.5 }}>
                <Input
                  defaultValue={startTime}
                  onChange={(val) => setStartTime(val.target.value)}
                  type={"time"}
                />
              </div>
              <div
                style={{ flex: 0.1, justifyContent: "center", display: "flex" }}
              >
                <span>sd</span>
              </div>
              <div style={{ flex: 0.5 }}>
                <Input
                  defaultValue={endTime}
                  onChange={(val) => setEndTime(val.target.value)}
                  type={"time"}
                ></Input>
              </div>
            </div>
            <Label
              style={{
                fontFamily: "Raleway",
                fontSize: "12px",
                fontWeight: 600,
              }}
            >
              Kuota
            </Label>
            <Input
              value={quotaVal}
              min={1}
              onChange={(val) => setQuotaVal(parseInt(val.target.value))}
              style={{
                height: "40px",
                borderRadius: "5px",
                marginBottom: "20px",
              }}
              type={"number"}
              id="quota"
              name="quota"
            />
          </div>
          <div
            style={{
              marginTop: "20px",
              justifyContent: "space-evenly",
              display: "flex",
            }}
          >
            <button
              style={{
                background: COLORS.gradientGray_1,
                width: "100px",
                borderRadius: "5px",
                height: "40px",
                border: 0,
                color: "#fff",
                fontFamily: "Raleway",
                fontSize: "12px",
                fontWeight: 600,
              }}
              onClick={() => setShowModal(false)}
            >
              BATAL
            </button>
            <button
              className={classes.saveScheduleButton}
              onClick={handleCreateSchedule}
              disabled={isLoading}
            >
              {isLoading ? "LOADING" : "BUAT"}
            </button>
          </div>
        </ModalBody>
      </CustomModal>
      {scheduleInputList && (
        <CustomModal
          isOpen={showEditModal}
          toggle={() => setShowEditModal(!showEditModal)}
          className={modal_classes.modal}
        >
          <ModalHeader
            className={modal_classes.modalHeader}
            toggle={() => setShowEditModal(!showEditModal)}
          >
            <span style={{ textAlign: "center" }}>
              Ubah Jadwal Channel {channelData && channelData[0].description}
            </span>
          </ModalHeader>
          <ModalBody>
            <div>
              {!scheduleInputList ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress
                    size={18}
                    color="inherit"
                    style={{ color: COLORS.blue_1 }}
                  />
                </div>
              ) : (
                scheduleInputList.map((item, index) => {
                  return (
                    <Row className="align-items-center" key={item.id}>
                      <Col xs={7} sm={6}>
                        <Label
                          style={{
                            fontFamily: "Raleway",
                            fontSize: "12px",
                            marginTop: "20px",
                            fontWeight: 600,
                          }}
                        >
                          Rentang Waktu
                        </Label>
                        <div
                          style={{
                            flexDirection: "row",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                            marginBottom: "20px",
                            gap: 8,
                          }}
                        >
                          <div style={{ flex: 0.5 }}>
                            <Input
                              defaultValue={item.from}
                              onChange={(val) =>
                                handleInputScheduleItem(
                                  "from",
                                  index,
                                  `${val.target.value}:00`
                                )
                              }
                              type={"time"}
                              style={{ height: 40 }}
                            />
                          </div>
                          <div
                            style={{
                              flex: 0.1,
                              justifyContent: "center",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span>sd</span>
                          </div>
                          <div style={{ flex: 0.5 }}>
                            <Input
                              defaultValue={item.to}
                              onChange={(val) =>
                                handleInputScheduleItem(
                                  "to",
                                  index,
                                  `${val.target.value}:00`
                                )
                              }
                              type={"time"}
                              style={{ height: 40 }}
                            ></Input>
                          </div>
                        </div>
                      </Col>
                      <Col xs={2} sm={3}>
                        <Label
                          style={{
                            fontFamily: "Raleway",
                            fontSize: "12px",
                            fontWeight: 600,
                          }}
                        >
                          Kuota
                        </Label>
                        <Input
                          min={1}
                          defaultValue={item.quota.toString()}
                          onChange={(val) =>
                            handleInputScheduleItem(
                              "quota",
                              index,
                              parseInt(val.target.value)
                            )
                          }
                          style={{
                            height: "40px",
                            borderRadius: "5px",
                          }}
                          type={"number"}
                          id="editquota"
                          name="editquota"
                        />
                      </Col>
                      <Col xs={2} sm={3} style={{ height: 64 }}>
                        <Col className="d-flex" style={{ gap: 16 }}>
                          <Col
                            className="justify-content-between"
                            style={{ marginLeft: 6 }}
                          >
                            <Label
                              style={{
                                fontFamily: "Raleway",
                                fontSize: "12px",
                                marginBottom: 0,
                                fontWeight: 600,
                              }}
                            >
                              Status
                            </Label>
                            <div style={{ marginTop: 16 }}>
                              <SwitchButton
                                toggled={item.active}
                                onChange={(val) =>
                                  handleInputScheduleItem(
                                    "active",
                                    index,
                                    val.target.checked
                                  )
                                }
                              />
                            </div>
                          </Col>
                          <Col className="justify-content-between">
                            <Label
                              style={{
                                fontFamily: "Raleway",
                                fontSize: "12px",
                                marginBottom: 0,
                                fontWeight: 600,
                              }}
                            >
                              Aksi
                            </Label>
                            <BiTrash
                              color={
                                item.delete === true
                                  ? COLORS.gray
                                  : COLORS.red_1
                              }
                              fontSize={18}
                              cursor={"pointer"}
                              style={{ marginTop: 18, marginRight: 16 }}
                              onClick={() =>
                                handleInputScheduleItem(
                                  "delete",
                                  index,
                                  !item.delete
                                )
                              }
                            />
                          </Col>
                        </Col>
                      </Col>
                    </Row>
                  );
                })
              )}
              <div
                style={{
                  marginTop: "20px",
                  justifyContent: "space-evenly",
                  display: "flex",
                }}
              >
                <button
                  style={{
                    background: COLORS.gradientGray_1,
                    width: 120,
                    borderRadius: "5px",
                    height: "40px",
                    border: 0,
                    color: "#fff",
                    fontFamily: "Raleway",
                    fontSize: "12px",
                    fontWeight: 600,
                  }}
                  onClick={() => setShowEditModal(false)}
                >
                  BATAL
                </button>
                <button
                  className={classes.saveScheduleButton}
                  onClick={handleUpdateSchedule}
                  disabled={
                    isLoading ||
                    (scheduleInputList && scheduleInputList.length === 0)
                  }
                  style={{
                    background:
                      scheduleInputList && scheduleInputList.length === 0
                        ? COLORS.gray_2
                        : COLORS.gradientBlue_3,
                  }}
                >
                  {isLoading ? "LOADING" : "SIMPAN"}
                </button>
              </div>
            </div>
          </ModalBody>
        </CustomModal>
      )}
    </PageContainer>
  );
};

export default AdminScheduleListPage;
