import { ButtonBase, CircularProgress } from "@material-ui/core";
import { format } from "date-fns";
import { channel } from "diagnostics_channel";
import React, { useEffect, useMemo } from "react";
import { BiPencil } from "react-icons/bi";
import {
  IoIosArrowRoundBack,
  IoIosClose,
  IoIosSearch,
  IoIosTrash,
} from "react-icons/io";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FormGroup, Input, Label, ModalBody, ModalHeader } from "reactstrap";
import { COLORS } from "../../../assets/theme";
import AddButton from "../../../components/atoms/AddButton";
import CustomModal from "../../../components/atoms/Modals/CustomModal";
import PureInputSelect from "../../../components/atoms/PureInputSelect";
import SwitchButton from "../../../components/atoms/SwitchButton/SwitchButton";
import useTagButtonStyles from "../../../components/atoms/TagButton/useTagButtonStyles";
import PageContainer from "../../../components/PageContainer/PageContainer";
import { ROUTES } from "../../../config/nav";
import useChannelOptions from "../../../hooks/channels/useChannelOptions";
import useModalStyles from "../../../hooks/styles/useModalStyles";
import { useGetChannelsQuery } from "../../../redux/ChannelStore/channelstoreAPI";
import {
  useCreateWarehouseMutation,
  useGetWarehouseAllQuery,
  useGetWarehouseQuery,
  useUpdateWarehouseAllMutation,
  useUpdateWarehouseMutation,
} from "../../../redux/ChannelStore/warehouse/warehouseStoreAPI";
import { ChannelOpt } from "../../../types/channel";
import {
  AUpdateWarehouseAllReqObj,
  AWarehouse,
} from "../../../types/channel/warehouse";
import { useAdminAccountPageStyle } from "../ManageAccount/style";
import { useAdminSchedulePageStyle } from "../Schedule/style";

type Props = {};

const ListWarehousePage = (props: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const channelIdParams = searchParams.get("channelId");
  const [inputList, setInputList] = React.useState<Partial<AWarehouse>[]>([
    { name: "" },
  ]);
  const [showSearchText, setShowSearchText] = React.useState<boolean>(false);
  const [searchText, setSearchText] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { arr: channelOpts, isLoading: isLoadingChannelOpts } =
    useChannelOptions({ active: true });
  const { data: channelList } = useGetChannelsQuery(
    {
      // id: channelIdParams ? parseInt(channelIdParams) : 0,
      active: true,
    }
    // { skip: channelIdParams === null }
  );

  const usedChannelOpts = React.useMemo(() => {
    const arr = channelOpts.slice();
    arr.sort((a, b) => a.description.localeCompare(b.description));
    return arr;
  }, [channelOpts]);
  // const { data: warehouseList, isLoading: isLoadingWarehouseList } =
  //   useGetWarehouseQuery(
  //     {
  //       channelId: channelIdParams ? parseInt(channelIdParams) : 0,
  //     },
  //     { skip: channelIdParams === null }
  //   );

  const { data: warehouseListAll, isLoading: isLoadingWarehouseListAll } =
    useGetWarehouseAllQuery({});

  const warehouseMemo = useMemo<AWarehouse[]>(() => {
    if (!warehouseListAll) return [];
    let data = [...warehouseListAll];
    return data.filter((item) => {
      const isInChannel = channelIdParams
        ? item.channel.id === parseInt(channelIdParams)
        : true;
      const isInSearch =
        searchText && searchText.length
          ? item.name.toLowerCase().includes(searchText.toLowerCase())
          : true;
      return isInChannel && isInSearch;
    });
  }, [searchText, channelIdParams, warehouseListAll]);

  const classes = useAdminSchedulePageStyle();
  const accountClasses = useAdminAccountPageStyle();
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const modal_classes = useModalStyles();
  const [channel, setChannel] = React.useState<ChannelOpt | null>();

  function handleInputListItem<
    K extends keyof Partial<AUpdateWarehouseAllReqObj>,
    V extends Partial<AUpdateWarehouseAllReqObj>[K]
  >(key: K, index: number, value: V) {
    let list = [...inputList];
    list[index] = { ...list[index], [key]: value };
    setInputList(list);
  }

  const handleDeleteInputListItem = (index: number) => {
    let list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  React.useEffect(() => {
    if (showEditModal && !isLoadingWarehouseListAll) {
      let warehouses: Partial<AWarehouse>[] = warehouseMemo?.map(
        ({ createdAt, updatedAt, isDeleted, ...rest }) => {
          return {
            ...rest,
            isDeleted: isDeleted === undefined ? false : isDeleted,
          };
        }
      );
      setInputList(warehouses);
    } else {
      setInputList([{ name: "" }]);
    }
  }, [showEditModal, isLoadingWarehouseListAll]);

  React.useEffect(() => {
    if (!showModal) {
      setInputList([{ name: "" }]);
    }
  }, [showModal]);
  const tagClasses = useTagButtonStyles();
  const [createWarehouse] = useCreateWarehouseMutation();
  const [updateWarehouse] = useUpdateWarehouseMutation();
  const [updateAllWarehouse] = useUpdateWarehouseAllMutation();

  const handleCreateWarehouse = React.useCallback(() => {
    setIsLoading(true);
    if (!channel) return;
    const obj = {
      channelId: channel.value,
      data: inputList,
    };
    createWarehouse(obj)
      .unwrap()
      .then(() => {
        setIsLoading(false);
        setShowModal(false);
        toast("Warehouse berhasil dibuat", {
          type: "success",
        });
      })
      .catch(() => {
        setIsLoading(false);
        toast("Gagal membuat Warehouse.", {
          type: "error",
        });
      });
  }, [inputList, channel]);
  const handleUpdateWarehouse = React.useCallback(() => {
    setIsLoading(true);
    if (channelIdParams) {
      const obj = {
        channelId: channelIdParams ? parseInt(channelIdParams) : 0,
        data: inputList,
      };
      updateWarehouse(obj)
        .unwrap()
        .then(() => {
          setIsLoading(false);
          setShowEditModal(false);
          toast("Warehouse berhasil diubah", {
            type: "success",
          });
        });
    } else {
      let list = [...inputList];
      const newDataInput: Partial<AUpdateWarehouseAllReqObj>[] = list.map(
        ({ channel, ...rest }) => {
          return {
            ...rest,
            channelId: channel?.id,
          };
        }
      );
      updateAllWarehouse(newDataInput)
        .unwrap()
        .then(() => {
          setIsLoading(false);
          setShowEditModal(false);
          toast("Warehouse berhasil diubah", {
            type: "success",
          });
        })
        .catch(() => {
          setIsLoading(false);
          toast("Gagal mengubah Warehouse.", {
            type: "error",
          });
        });
    }
  }, [inputList]);

  useEffect(() => {
    if (channelIdParams && !isLoadingChannelOpts) {
      setChannel(
        channelOpts.find((item) => item.value === parseInt(channelIdParams))
      );
    } else {
      setChannel(null);
    }
  }, [isLoadingChannelOpts, channelIdParams]);

  const getChannelWarehousebyId = React.useCallback(
    (id: string) => {
      if (!channelList || channelList.length === 0) return undefined;
      const pickedChannel = channelList.find(
        (item) => item.id === parseInt(id)
      );
      return pickedChannel;
    },
    [channelList, channel]
  );

  const getModalTitle = (id?: string, isEdit?: boolean): string => {
    if (id) {
      const channelName = getChannelWarehousebyId(id)?.description ?? id;
      return `${isEdit ? "Ubah" : "Buat"} Gudang ${channelName}`;
    } else {
      return `${isEdit ? "Ubah" : "Buat"} Gudang`;
    }
  };

  return (
    <div>
      <PageContainer
        headerNavigationContent={
          <>
            {showSearchText ? (
              <>
                <div
                  className={accountClasses.searchWrapper}
                  style={{ width: "100%" }}
                >
                  <Input
                    id="search-warehouse"
                    name="search-warehouse"
                    placeholder="Cari gudang..."
                    type="text"
                    onChange={(val) => setSearchText(val.target.value)}
                    style={{
                      height: "40px",
                      width: "100%",
                      border: "none",
                      borderBottom: `1px solid ${COLORS.gray_1}`,
                      boxShadow: "none",
                      borderRadius: 0,
                    }}
                  />
                  <IoIosClose
                    size={24}
                    color={COLORS.black_1}
                    onClick={() => {
                      setSearchText("");
                      setShowSearchText(false);
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                <div className={classes.headerSideContainer}>
                  <button
                    className={classes.backButton}
                    onClick={() => navigate("/")}
                  >
                    <IoIosArrowRoundBack size={20} />
                  </button>
                </div>
                <div className={classes.headerTitleContainer}>
                  <p className={classes.textTitle}>Manajemen Gudang</p>
                </div>
                <div className={classes.headerSideContainer}>
                  <IoIosSearch
                    fontSize={18}
                    style={{ marginRight: 14 }}
                    color={COLORS.black_1}
                    cursor="pointer"
                    onClick={() => setShowSearchText(!showSearchText)}
                  />
                  {channelIdParams && (
                    <BiPencil
                      style={{ marginRight: 6 }}
                      fontSize={16}
                      color={COLORS.black_1}
                      cursor="pointer"
                      onClick={() => setShowEditModal(!showEditModal)}
                    />
                  )}
                </div>
                {/* <div
                  className={classes.headerSideContainer}
                  onClick={() => {
                    setShowEditModal(!showEditModal);
                  }}
                >
                  <span
                    style={{
                      fontWeight: 700,
                      color: COLORS.blue_1,
                      fontSize: 13,
                      cursor: "pointer",
                    }}
                  >
                    UBAH
                  </span>
                </div> */}
              </>
            )}
          </>
        }
        headerContent={
          <div className={classes.carouselContainer}>
            <ButtonBase
              className={[
                classes.chipsContainer,
                channelIdParams === null ? "pick" : undefined,
              ].join(" ")}
              onClick={() => {
                searchParams.delete("channelId");
                setSearchParams(searchParams);
              }}
            >
              Semua
            </ButtonBase>
            {usedChannelOpts.map((channel) => {
              return (
                <ButtonBase
                  key={channel.id}
                  className={[
                    classes.chipsContainer,
                    channelIdParams && parseInt(channelIdParams) === channel.id
                      ? "pick"
                      : undefined,
                  ].join(" ")}
                  onClick={() => {
                    searchParams.set("channelId", `${channel.id}`);
                    setSearchParams(searchParams);
                  }}
                >
                  {channel.description}
                </ButtonBase>
              );
            })}
          </div>
        }
        className={classes.container}
        withBackground
      >
        <div style={{ minHeight: "100vh" }}>
          {isLoadingWarehouseListAll ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress
                size={18}
                color="inherit"
                style={{ color: COLORS.blue_1 }}
              />
            </div>
          ) : (
            warehouseMemo?.map((item) => {
              const channelId = item.channel
                ? "" + item.channel.id
                : channelIdParams ?? undefined;
              const channel = channelId
                ? getChannelWarehousebyId(channelId)
                : undefined;

              return (
                <ButtonBase
                  className={classes.scheduleCardContainer}
                  key={item.id}
                  style={{
                    background:
                      item.isActive === false
                        ? COLORS.gray_1
                        : COLORS.gradientGreen_4,
                    color:
                      item.isActive === false ? COLORS.gray_2 : COLORS.black_1,

                    padding: 16,
                    fontWeight: 600,
                  }}
                  onClick={() => {
                    if (item.isActive) {
                      if (channelIdParams) {
                        navigate({
                          pathname: `${ROUTES.superadmin}/warehouse/${
                            channelIdParams ?? ""
                          }/dock/${item.id}`,
                        });
                      } else {
                        navigate({
                          pathname: `${ROUTES.superadmin}/warehouse/${item.channel.id}/dock/${item.id}`,
                        });
                      }
                    }
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: 8,
                      flexDirection: "column",
                      alignItems: "start",
                    }}
                  >
                    <span style={{ fontWeight: 700, fontSize: 12 }}>
                      {item.name}
                    </span>
                    {channelIdParams ? (
                      <button
                        className={tagClasses.tag}
                        style={{
                          backgroundColor:
                            item.isActive && channel
                              ? channel.color
                              : COLORS.gray,
                        }}
                      >
                        {channel?.description ?? "-"}
                      </button>
                    ) : (
                      <button
                        className={tagClasses.tag}
                        style={{
                          backgroundColor: item.isActive
                            ? channel?.color
                            : COLORS.gray,
                        }}
                      >
                        {channel?.description}
                      </button>
                    )}
                  </div>
                </ButtonBase>
              );
            })
          )}
          {warehouseMemo?.length === 0 && (
            <div style={{ textAlign: "center", width: "100%" }}>
              <span>Tidak ada gudang yang ditemukan</span>
            </div>
          )}
          <AddButton onClick={() => setShowModal(!showModal)} />
          <CustomModal
            isOpen={showModal}
            toggle={() => setShowModal(!showModal)}
            className={modal_classes.modal}
          >
            <ModalHeader
              className={modal_classes.modalHeader}
              toggle={() => setShowModal(!showModal)}
            >
              <p style={{ marginBottom: 0 }}>
                {getModalTitle(channel?.id.toString() ?? undefined, false)}
              </p>
            </ModalHeader>
            <ModalBody>
              <div>
                <Label className={accountClasses.textFormTitle}>Channel</Label>
                <PureInputSelect
                  defaultValue={channel}
                  options={usedChannelOpts}
                  isMulti={false}
                  onChange={(val) => setChannel(val)}
                />
              </div>
              {inputList.map((item, index) => (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                  key={item.id}
                >
                  <FormGroup style={{ width: "100%" }}>
                    <Label className={accountClasses.textFormTitle}>
                      Nama Gudang
                    </Label>
                    <Input
                      style={{ height: "40px", borderRadius: "5px" }}
                      onChange={(val) =>
                        handleInputListItem("name", index, val.target.value)
                      }
                      placeholder="Nama Gudang"
                      id="warehouse"
                      name="warehouse"
                      value={item.name}
                      type="text"
                    />
                  </FormGroup>
                  {index > 0 && (
                    <IoIosTrash
                      style={{ marginTop: 24, marginLeft: 8 }}
                      size={20}
                      color={COLORS.red_1}
                      onClick={() => handleDeleteInputListItem(index)}
                      cursor="pointer"
                    />
                  )}
                </div>
              ))}

              <button
                type="button"
                className={accountClasses.addApproverButton}
                onClick={() => {
                  setInputList((prev) => {
                    return [...prev, { name: "" }];
                  });
                }}
              >
                Tambah Gudang
              </button>
              <div
                style={{
                  marginTop: "20px",
                  justifyContent: "space-evenly",
                  display: "flex",
                }}
              >
                <button
                  style={{
                    backgroundColor: COLORS.gray_1,
                    width: "100px",
                    borderRadius: "5px",
                    height: "40px",
                    border: 0,
                    color: "#fff",
                    fontFamily: "Raleway",
                    fontSize: "12px",
                    fontWeight: 600,
                  }}
                  onClick={() => setShowModal(false)}
                >
                  BATALKAN
                </button>
                <button
                  className={classes.saveScheduleButton}
                  onClick={handleCreateWarehouse}
                  disabled={isLoading || !channel}
                >
                  {/* BUAT */}
                  {isLoading ? "LOADING" : "BUAT"}
                </button>
              </div>
            </ModalBody>
          </CustomModal>
          <CustomModal
            isOpen={showEditModal}
            toggle={() => setShowEditModal(!showEditModal)}
            className={modal_classes.modal}
          >
            <ModalHeader
              className={modal_classes.modalHeader}
              toggle={() => setShowEditModal(!showEditModal)}
            >
              <p style={{ marginBottom: 0 }}>
                {getModalTitle(channelIdParams ?? undefined, true)}
              </p>
            </ModalHeader>
            <ModalBody>
              {inputList.map((item, index) => (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FormGroup style={{ width: "100%" }}>
                    <Label className={accountClasses.textFormTitle}>
                      Nama Gudang
                    </Label>
                    <Input
                      style={{ height: "40px", borderRadius: "5px" }}
                      onChange={(val) =>
                        handleInputListItem("name", index, val.target.value)
                      }
                      placeholder="Nama Gudang"
                      id="warehouse"
                      name="warehouse"
                      value={item.name}
                      type="text"
                    />
                  </FormGroup>
                  <div style={{ marginTop: 24, marginLeft: 16 }}>
                    <SwitchButton
                      toggled={item.isActive ? item.isActive : false}
                      onChange={(val) =>
                        handleInputListItem(
                          "isActive",
                          index,
                          val.target.checked
                        )
                      }
                    />
                  </div>
                  <span
                    style={{
                      fontWeight: 700,
                      marginLeft: 16,
                      marginTop: 24,
                      cursor: "pointer",
                      color:
                        item.isDeleted === true ? COLORS.gray : COLORS.red_1,
                    }}
                    onClick={() => {
                      handleInputListItem("isDeleted", index, !item.isDeleted);
                    }}
                    // onClick={() =>
                    //   item.id && handleDeleteWarehouse(item.id, index)
                    // }
                  >
                    HAPUS
                  </span>
                </div>
              ))}
              <div
                style={{
                  marginTop: "20px",
                  justifyContent: "space-evenly",
                  display: "flex",
                }}
              >
                <button
                  style={{
                    backgroundColor: COLORS.gray_1,
                    width: "100px",
                    borderRadius: "5px",
                    height: "40px",
                    border: 0,
                    color: "#fff",
                    fontFamily: "Raleway",
                    fontSize: "12px",
                    fontWeight: 600,
                  }}
                  onClick={() => setShowEditModal(false)}
                >
                  BATALKAN
                </button>
                <button
                  className={classes.saveScheduleButton}
                  onClick={handleUpdateWarehouse}
                  disabled={isLoading || (inputList && inputList.length === 0)}
                  style={{
                    background:
                      inputList && inputList.length === 0
                        ? COLORS.gray_2
                        : COLORS.gradientBlue_3,
                  }}
                >
                  {isLoading ? "LOADING" : "SIMPAN"}
                </button>
              </div>
            </ModalBody>
          </CustomModal>
        </div>
      </PageContainer>
    </div>
  );
};

export default ListWarehousePage;
