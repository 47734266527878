import React from "react";
import { createUseStyles } from "react-jss";

type Props = {};

const useModalStyles = createUseStyles({
  modal: {
    "&>*": {
      padding: "2rem",
      borderRadius: 8,
    },
  },

  modalHeader: {
    borderBottom: 0,
    "&>h5": {
      lineHeight: "14.09px",
      fontWeight: 700,
      fontSize: "1.4rem",
      textAlign: "center",
      letterSpacing: 0.65,
      width: "100%",
    },
  },
  modalFormWrapper: {
    paddingBottom: 16,
  },
});

export default useModalStyles;
