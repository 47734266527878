import { Navigate, useLocation } from "react-router-dom";
import useJWT from "../../hooks/auth/useJWT";
import { useDefaultRoute } from "./Redirector";

interface Props {
  children: React.ReactNode;
}

const LoginRedirector: React.FunctionComponent<Props> = ({ children }) => {
  const jwt = useJWT();
  const to = useDefaultRoute();
  const location = useLocation();

  if (jwt && jwt.length && location.pathname !== to) {
    return <Navigate replace to={to} />;
  }
  return <>{children}</>;
};

export default LoginRedirector;
