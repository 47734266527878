import { useGetVendorsQuery } from "../../redux/VendorStore/vendorstoreAPI";
import { AVendor, AVendorOpt } from "../../types/vendor";

type RetVal = {
  arr: AVendorOpt[];
  isLoading: boolean;
};

const useVendorOptions = (arg?: AVendor, opt?: { skip?: boolean }): RetVal => {
  const { data, isLoading, isFetching } = useGetVendorsQuery(arg || {}, {
    skip: opt?.skip,
  });

  return {
    arr:
      data?.map((val) => {
        return {
          label: val.name,
          value: val.clientcode,
          ...val,
        };
      }) || [],
    isLoading: isLoading || isFetching,
  };
};

export default useVendorOptions;
