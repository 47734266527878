import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CookieSetOptions } from "universal-cookie";
import { letUserLogin } from "../../api/user/login";
import { ROUTES } from "../../config/nav";
import { useAppDispatch } from "../../redux";
import { resetChannelstoreAPI } from "../../redux/ChannelStore/channelstoreAPI";
import { resetDockStoreAPI } from "../../redux/ChannelStore/dock/dockStoreAPI";
import { resetWarehouseAPI } from "../../redux/ChannelStore/warehouse/warehouseStoreAPI";
import { resetMaterialstoreAPI } from "../../redux/MaterialStore/materialstoreAPI";
import { resetReservationstoreAPI } from "../../redux/ReservationStore/reservationstoreAPI";
import { resetWIReservationstoreAPI } from "../../redux/ReservationStore/walkinReservationStoreAPI";
import { resetSchedulestoreAPI } from "../../redux/ScheduleStore/schedulestoreAPI";
import { resetTruckstoreAPI } from "../../redux/TruckStore/truckstoreAPI";
import { removeUserData } from "../../redux/UserStore/userstore";
import { resetUserStoreAPI } from "../../redux/UserStore/userstoreAPI";
import { resetVendorstoreAPI } from "../../redux/VendorStore/vendorstoreAPI";
import { postSomethingToRN } from "../../tools";
import CookieMonster, { KnownCookieNames } from "../../tools/CookieMonster";
import { LoginObj } from "../../types/user";

export const useAppCookies = (deps: KnownCookieNames[]) =>
  useCookies<KnownCookieNames>(deps);

export const useClearCache = () => {
  const dispatch = useAppDispatch();
  return () => {
    dispatch(removeUserData());
    dispatch(resetChannelstoreAPI());
    dispatch(resetWarehouseAPI());
    dispatch(resetDockStoreAPI());
    dispatch(resetMaterialstoreAPI());
    dispatch(resetReservationstoreAPI());
    dispatch(resetSchedulestoreAPI());
    dispatch(resetTruckstoreAPI());
    dispatch(resetUserStoreAPI());
    dispatch(resetVendorstoreAPI());
    dispatch(resetWIReservationstoreAPI());
  };
};

export const useLogin = () => {
  return async (obj?: LoginObj) => {
    const {
      data: {
        data: { jwt, ...rest },
      },
    } = await letUserLogin(obj);
    const cookie = new CookieMonster();
    const opt: CookieSetOptions = {
      expires: new Date(rest.exp * 1000),
    };
    let tobeSaved = {
      jwt,
      username: rest.username,
      idUser: rest.id,
      usertypeName: rest.usertypeName,
      channelId: rest.channelId || undefined,
    };

    postSomethingToRN("login");

    cookie.saveAllThisCookies(tobeSaved, opt);
    return rest;
  };
};

export const useLogout = () => {
  const navigate = useNavigate();
  const clearCache = useClearCache();
  return () => {
    const cookie = new CookieMonster();
    clearCache();
    cookie.removeAllthisCookies([
      "jwt",
      "username",
      "idUser",
      "usertypeName",
      "channelId",
    ]);
    postSomethingToRN("logout");
    navigate(ROUTES.loginpage);
  };
};
