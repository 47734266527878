import axios from "axios";
import CookieMonster from "../../tools/CookieMonster";
import { BackendDataShape, CREDLINKS } from "../../types";
import { LoginObj, LoginRes } from "../../types/user";

export {};

// export
export function letUserLogin(obj?: LoginObj) {
  const url = `${CREDLINKS.login}`;
  const cookie = new CookieMonster();
  const payload = obj
    ? obj
    : {
        jwt: cookie.loadCookie("jwt"),
      };
  return axios.post<BackendDataShape<LoginRes>>(url, payload);
}
