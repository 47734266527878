import React from "react";
import useChannelOptions from "./useChannelOptions";

const useSpecificChannel = (channelId: number, opt?: { skip?: boolean }) => {
  const { arr, isLoading } = useChannelOptions({}, { skip: opt?.skip });
  return React.useMemo(() => {
    return {
      isLoading,
      channel: arr.find((channel) => {
        return channel.id === channelId;
      }),
    };
  }, [arr, isLoading, channelId]);
};

export default useSpecificChannel;
