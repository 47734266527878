import { useGetWarehouseQuery } from "../../../redux/ChannelStore/warehouse/warehouseStoreAPI";
import { WarehouseOpt } from "../../../types/channel/warehouse";

type RetVal = {
  arr: WarehouseOpt[];
  isLoading: boolean;
};

const useWarehouseOptions = (
  arg: { channelId: number },
  opt?: { skip?: boolean }
): RetVal => {
  const { data, isLoading, isFetching } = useGetWarehouseQuery(arg, {
    skip: opt?.skip,
  });

  return {
    arr:
      data
        ?.filter((item) => item.isActive === true)
        .map((val) => {
          return {
            label: val.name,
            value: val.id,
            ...val,
          };
        }) || [],
    isLoading: isLoading || isFetching,
  };
};

export default useWarehouseOptions;
