import { createUseStyles } from "react-jss";

const useTagButtonStyles = createUseStyles({
  tagContainer: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    overflowY: "hidden",
    marginBottom: "1.6rem",
    alignItems: "center",
    fontWeight: 600,
    "&::-webkit-scrollbar": {
      width: 0,
      height: 0,
    },
    gap: "1rem",
  },
  tag: {
    padding: "0.4rem 0.8rem",
    borderRadius: "1.6rem",
    border: 0,
    fontWeight: 600,
    color: "white",
    "&:hover": {
      opacity: 0.7,
    },
  },
});

export default useTagButtonStyles;
