import { makeStyles } from "@material-ui/core";
import { COLORS } from "../../assets/theme";

const useListContainerStyles = makeStyles(() => {
  return {
    container: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      padding: 0,
    },
    headerContainer: {
      position: "sticky",
      padding: "0 1rem",
      top: 0,
      left: 0,
      right: 0,
      zIndex: 2,
      backgroundColor: "white",
      width: "100%",
    },
    tagContainer: {
      display: "flex",
      flexWrap: "nowrap",
      width: "100%",
      overflowY: "hidden",
      marginBottom: "1.6rem",
      alignItems: "center",
      "&::-webkit-scrollbar": {
        width: 0,
        height: 0,
      },
      gap: "1rem",
    },
    tag: {
      backgroundColor: "white",
      boxSizing: "border-box",
      border: `1px solid ${COLORS.gray_1}`,
      color: COLORS.black_1,
      whiteSpace: "nowrap",
      padding: "0.64rem 1.4rem",
      fontWeight: 600,
      borderRadius: "1.6rem",
      "&.active": {
        background: COLORS.gradientBlue_2,
        color: "white",
        border: `1px solid ${COLORS.blue_1}`,
      },
    },
    listContainer: {
      padding: "1rem 0",
    },
    icon: {
      fontSize: "2em",
      cursor: "pointer",
    },
    clearButtonDateRange: {
      "&::after": {
        position: "absolute",
        top: "-3px",
        right: "-8px",
      },
    },
    searchWrapper: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      gap: 8,
    },
    filteredButton: {
      padding: "0.5rem 0.75rem",
      width: "fit-content",
      marginBottom: 16,
      fontWeight: 600,
      borderRadius: 16,
      fontFamily: "Raleway",
      fontSize: "10px",
      background: COLORS.gradientBlue_3,
      border: 0,
      color: "white",
      // borderStyle: "solid",
      // borderColor: COLORS.gray,
      whiteSpace: "nowrap",
    },
  };
});

export default useListContainerStyles;
