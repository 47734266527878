// import { widthBreakpoints as breakPoints, mq } from "../assets/theme";

// export const widthBreakpoints = [480, 768, 992, 1024, 1280, 1366, 1920];
export const widthBreakpoints = [320, 375, 425, 768, 1280, 1366, 1920];
export const mq = widthBreakpoints.map((bp) => `@media (min-width: ${bp}px)`);

export const xXLarge = mq[6];

export const xLarge = `@media screen and (max-width:${widthBreakpoints[5]}px)`;

export const large = `@media screen and (max-width:${widthBreakpoints[4]}px)`;

export const medium = `@media screen and (max-width:${widthBreakpoints[3]}px)`;

export const small = `@media screen and (max-width:${widthBreakpoints[1]}px)`;

export const xSmall = `@media screen and (max-width:${widthBreakpoints[0]}px)`;
