import { ButtonBase } from "@material-ui/core";
import React, { useMemo } from "react";
import { BiPencil } from "react-icons/bi";
import {
  IoIosArrowRoundBack,
  IoIosClose,
  IoIosSearch,
  IoIosTrash,
} from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FormGroup, Input, Label, ModalBody, ModalHeader } from "reactstrap";
import { COLORS } from "../../../assets/theme";
import AddButton from "../../../components/atoms/AddButton";
import CustomModal from "../../../components/atoms/Modals/CustomModal";
import SwitchButton from "../../../components/atoms/SwitchButton/SwitchButton";
import PageContainer from "../../../components/PageContainer/PageContainer";
import { ROUTES } from "../../../config/nav";
import useWarehouseOptions from "../../../hooks/channels/warehouse/useWarehouseOptions";
import useModalStyles from "../../../hooks/styles/useModalStyles";
import { useGetChannelsQuery } from "../../../redux/ChannelStore/channelstoreAPI";
import {
  useCreateDockMutation,
  useGetDockQuery,
  useUpdateDockMutation,
} from "../../../redux/ChannelStore/dock/dockStoreAPI";
import { ADock } from "../../../types/channel/dock";
import { WarehouseOpt } from "../../../types/channel/warehouse";
import { useAdminAccountPageStyle } from "../ManageAccount/style";
import { useAdminSchedulePageStyle } from "../Schedule/style";

type Props = {};

const ListDockWarehousePage = (props: Props) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showSearchText, setShowSearchText] = React.useState<boolean>(false);
  const [searchText, setSearchText] = React.useState<string>("");
  const [optPick, setOptPick] = React.useState<WarehouseOpt | null>();
  const classes = useAdminSchedulePageStyle();

  const accountClasses = useAdminAccountPageStyle();
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const [inputList, setInputList] = React.useState<Partial<ADock>[]>([
    { name: "" },
  ]);
  const navigate = useNavigate();
  const { channelId, warehouseId } = useParams<{
    channelId: string;
    warehouseId: string;
  }>();
  const { data: channelList } = useGetChannelsQuery({
    id: channelId ? parseInt(channelId) : 0,
    active: true,
  });
  const { arr: warehouseOpt, isLoading: isLoadingWarehouseOpts } =
    useWarehouseOptions({
      channelId: channelId ? parseInt(channelId) : 0,
    });
  const { data: warehouseDockList, isLoading: isLoadingWarehouseDockList } =
    useGetDockQuery(
      {
        channelId: channelId ? parseInt(channelId) : 0,
        warehouseId: warehouseId ? parseInt(warehouseId) : 0,
      },
      { skip: warehouseId !== undefined && warehouseId === "all" }
    );

  const warehouseDockMemo = useMemo<ADock[]>(() => {
    if (!warehouseDockList) return [];
    let data = [...warehouseDockList];
    if (searchText) {
      let filtered = data.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
      );
      return filtered;
    } else {
      return data;
    }
  }, [warehouseDockList, searchText, warehouseId]);

  function handleInputListItem<
    K extends keyof Partial<ADock>,
    V extends Partial<ADock>[K]
  >(key: K, index: number, value: V) {
    let list = [...inputList];
    list[index] = { ...list[index], [key]: value };
    setInputList(list);
  }

  const handleDeleteInputListItem = (index: number) => {
    let list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  React.useEffect(() => {
    if (showEditModal && !isLoadingWarehouseDockList) {
      let docks: Partial<ADock>[] = warehouseDockMemo?.map(
        ({ createdAt, updatedAt, isDeleted, ...rest }) => {
          return {
            ...rest,
            isDeleted: isDeleted === undefined ? false : isDeleted,
          };
        }
      );
      setInputList(docks);
    } else {
      setInputList([{ name: "" }]);
    }
  }, [showEditModal, isLoadingWarehouseDockList]);
  React.useEffect(() => {
    if (!showModal) {
      setInputList([{ name: "" }]);
    }
  }, [showModal]);

  const [createDock] = useCreateDockMutation();
  const [updateDock] = useUpdateDockMutation();

  const handleCreateDock = React.useCallback(() => {
    setIsLoading(true);
    const obj = {
      warehouseId: warehouseId ? parseInt(warehouseId) : 0,
      channelId: channelId ? parseInt(channelId) : 0,
      data: inputList,
    };
    createDock(obj)
      .unwrap()
      .then(() => {
        setIsLoading(false);
        setShowModal(false);
        toast("Dock berhasil dibuat", {
          type: "success",
        });
      })
      .catch(() => {
        setIsLoading(false);
        toast("Gagal membuat dock.", {
          type: "error",
        });
      });
  }, [inputList]);
  const handleUpdateDock = React.useCallback(() => {
    setIsLoading(true);
    const obj = {
      warehouseId: warehouseId ? parseInt(warehouseId) : 0,
      channelId: channelId ? parseInt(channelId) : 0,
      data: inputList,
    };
    updateDock(obj)
      .unwrap()
      .then(() => {
        setIsLoading(false);
        setShowEditModal(false);
        toast("Dock berhasil diubah", {
          type: "success",
        });
      })
      .catch(() => {
        setIsLoading(false);
        toast("Gagal mengubah dock.", {
          type: "error",
        });
      });
  }, [inputList]);

  React.useEffect(() => {
    if (warehouseId && !isLoadingWarehouseOpts) {
      setOptPick(
        warehouseOpt.find((item) => item.id === parseInt(warehouseId))
      );
    }
  }, [warehouseId, isLoadingWarehouseOpts]);

  const modal_classes = useModalStyles();
  return (
    <PageContainer
      headerNavigationContent={
        <>
          {showSearchText ? (
            <>
              <div
                className={accountClasses.searchWrapper}
                style={{ width: "100%" }}
              >
                <Input
                  id="search-dock"
                  name="search-dock"
                  placeholder="Cari dock..."
                  type="text"
                  onChange={(val) => setSearchText(val.target.value)}
                  style={{
                    height: "40px",
                    width: "100%",
                    border: "none",
                    borderBottom: `1px solid ${COLORS.gray_1}`,
                    boxShadow: "none",
                    borderRadius: 0,
                  }}
                />
                <IoIosClose
                  size={24}
                  color={COLORS.black_1}
                  onClick={() => {
                    setSearchText("");
                    setShowSearchText(false);
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <div className={classes.headerSideContainer}>
                <button
                  className={classes.backButton}
                  onClick={() =>
                    navigate({
                      pathname: `${ROUTES.superadmin}/warehouse`,
                      search: `channelId=${channelId ?? ""}`,
                    })
                  }
                >
                  <IoIosArrowRoundBack size={20} />
                </button>
              </div>
              <div className={classes.headerTitleContainer}>
                <p className={classes.textTitle}>
                  Manajemen Dock -{" "}
                  {channelList ? channelList[0]?.description : ""}
                </p>
              </div>
              <div className={classes.headerSideContainer}>
                <IoIosSearch
                  fontSize={18}
                  style={{ marginRight: 14 }}
                  color={COLORS.black_1}
                  cursor="pointer"
                  onClick={() => setShowSearchText(!showSearchText)}
                />
                <BiPencil
                  style={{ marginRight: 6 }}
                  fontSize={16}
                  color={COLORS.black_1}
                  cursor="pointer"
                  onClick={() => setShowEditModal(!showEditModal)}
                />
              </div>
              {/* <div
                className={classes.headerSideContainer}
                onClick={() => {
                  setShowEditModal(!showEditModal);
                }}
              >
                <span
                  style={{
                    fontWeight: 700,
                    color: COLORS.blue_1,
                    fontSize: 13,
                    cursor: "pointer",
                  }}
                >
                  UBAH
                </span>
              </div> */}
            </>
          )}
        </>
      }
      headerContent={
        <div className={classes.carouselContainer}>
          {warehouseOpt
            ?.sort((a, b) => a.name.localeCompare(b.name))
            ?.map((warehouse) => {
              return (
                <ButtonBase
                  key={warehouse.id}
                  className={[
                    classes.chipsContainer,
                    warehouseId && parseInt(warehouseId) === warehouse.id
                      ? "pick"
                      : undefined,
                  ].join(" ")}
                  onClick={() => {
                    navigate({
                      pathname: `${ROUTES.superadmin}/warehouse/${
                        channelId ?? ""
                      }/dock/${warehouse.id ?? ""}`,
                    });
                  }}
                >
                  {warehouse.name}
                </ButtonBase>
              );
            })}
        </div>
      }
      className={classes.container}
      withBackground
    >
      <div style={{ minHeight: "100vh" }}>
        {warehouseDockMemo?.map((item) => (
          <ButtonBase
            className={classes.scheduleCardContainer}
            key={item.id}
            style={{
              background:
                item.isActive === false
                  ? COLORS.gray_1
                  : COLORS.gradientGreen_4,
              color: item.isActive === false ? COLORS.gray_2 : COLORS.black_1,
              justifyContent: "center",
              padding: 16,
              fontWeight: 600,
            }}
          >
            {item.name}
          </ButtonBase>
        ))}
        {warehouseDockMemo?.length === 0 && (
          <div style={{ textAlign: "center", width: "100%" }}>
            <span>Tidak ada dock yang ditemukan</span>
          </div>
        )}
        <AddButton onClick={() => setShowModal(!showModal)} />
        <CustomModal
          isOpen={showModal}
          toggle={() => setShowModal(!showModal)}
          className={modal_classes.modal}
        >
          <ModalHeader
            className={modal_classes.modalHeader}
            toggle={() => setShowModal(!showModal)}
          >
            <p style={{ marginBottom: 0 }}>
              Buat Dock - {optPick ? optPick.name : ""}
            </p>
          </ModalHeader>
          <ModalBody>
            {inputList.map((item, index) => (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
                key={item.id}
              >
                <FormGroup style={{ width: "100%" }}>
                  <Label className={accountClasses.textFormTitle}>
                    Nama Dock
                  </Label>
                  <Input
                    style={{ height: "40px", borderRadius: "5px" }}
                    onChange={(val) =>
                      handleInputListItem("name", index, val.target.value)
                    }
                    placeholder="Nama Dock"
                    id="dock"
                    name="dock"
                    value={item.name}
                    type="text"
                  />
                </FormGroup>
                {index > 0 && (
                  <IoIosTrash
                    style={{ marginTop: 24, marginLeft: 8 }}
                    size={20}
                    color={COLORS.red_1}
                    onClick={() => handleDeleteInputListItem(index)}
                    cursor="pointer"
                  />
                )}
              </div>
            ))}

            <button
              type="button"
              className={accountClasses.addApproverButton}
              // disabled={approverChannelList.length === 0}
              onClick={() => {
                setInputList((prev) => {
                  return [...prev, { name: "" }];
                });
              }}
            >
              Tambah Dock
            </button>
            <div
              style={{
                marginTop: "20px",
                justifyContent: "space-evenly",
                display: "flex",
              }}
            >
              <button
                style={{
                  backgroundColor: COLORS.gray_1,
                  width: "100px",
                  borderRadius: "5px",
                  height: "40px",
                  border: 0,
                  color: "#fff",
                  fontFamily: "Raleway",
                  fontSize: "12px",
                  fontWeight: 600,
                }}
                onClick={() => setShowModal(false)}
              >
                BATALKAN
              </button>
              <button
                className={classes.saveScheduleButton}
                onClick={handleCreateDock}
                disabled={isLoading}
              >
                {isLoading ? "LOADING" : "BUAT"}
              </button>
              {/* {JSON.stringify(inputList)} */}
            </div>
          </ModalBody>
        </CustomModal>
        <CustomModal
          isOpen={showEditModal}
          toggle={() => setShowEditModal(!showEditModal)}
          className={modal_classes.modal}
        >
          <ModalHeader
            className={modal_classes.modalHeader}
            toggle={() => setShowEditModal(!showEditModal)}
          >
            <p style={{ marginBottom: 0 }}>
              Ubah Dock - {optPick ? optPick.name : ""}
            </p>
          </ModalHeader>
          <ModalBody>
            {inputList.map((item, index) => (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FormGroup style={{ width: "100%" }}>
                  <Label className={accountClasses.textFormTitle}>
                    Nama Dock
                  </Label>
                  <Input
                    style={{ height: "40px", borderRadius: "5px" }}
                    onChange={(val) =>
                      handleInputListItem("name", index, val.target.value)
                    }
                    placeholder="Nama Dock"
                    id="dock"
                    name="dock"
                    value={item.name}
                    type="text"
                  />
                </FormGroup>
                <div style={{ marginTop: 24, marginLeft: 16 }}>
                  <SwitchButton
                    toggled={item.isActive ? item.isActive : false}
                    onChange={(val) =>
                      handleInputListItem("isActive", index, val.target.checked)
                    }
                  />
                </div>
                <span
                  style={{
                    color: item.isDeleted ? COLORS.gray : COLORS.red_1,
                    fontWeight: 700,
                    marginLeft: 16,
                    marginTop: 24,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleInputListItem("isDeleted", index, !item.isDeleted)
                  }
                  // onClick={() => item.id && handleDeleteDock(item.id, index)}
                >
                  HAPUS
                </span>
              </div>
            ))}

            <div
              style={{
                marginTop: "20px",
                justifyContent: "space-evenly",
                display: "flex",
              }}
            >
              <button
                style={{
                  backgroundColor: COLORS.gray_1,
                  width: "100px",
                  borderRadius: "5px",
                  height: "40px",
                  border: 0,
                  color: "#fff",
                  fontFamily: "Raleway",
                  fontSize: "12px",
                  fontWeight: 600,
                }}
                onClick={() => setShowEditModal(false)}
              >
                BATALKAN
              </button>
              <button
                className={classes.saveScheduleButton}
                style={{
                  background:
                    inputList && inputList.length === 0
                      ? COLORS.gray_2
                      : COLORS.gradientBlue_3,
                }}
                onClick={handleUpdateDock}
                disabled={isLoading || (inputList && inputList.length === 0)}
              >
                {/* SIMPAN */}
                {isLoading ? "LOADING" : "SIMPAN"}
              </button>
            </div>
          </ModalBody>
        </CustomModal>
      </div>
    </PageContainer>
  );
};

export default ListDockWarehousePage;
