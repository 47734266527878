import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import useUsertype from "../../hooks/auth/useUsertype";
import NoMatch from "../../pages/NoMatch";
import { KnownUsertype } from "../../types/user";
import LoadingItem from "../atoms/LoadingContainer/LoadingItem";

interface Props {
  allowedUsers: KnownUsertype[];
}

/**
 *  We assume that the jwt was checked before
 */
const UserGate: React.FunctionComponent<Props> = ({ allowedUsers }) => {
  let usertype = useUsertype();
  // const [spinner, setSpinner] = useState(true);
  // // It will be executed before rendering
  // useEffect(() => {
  //   setTimeout(() => setSpinner(false), 1000);
  // }, []);
  const allowedUser = allowedUsers.find((item) => item === usertype);
  // if (spinner) return <LoadingItem isLoading={spinner} />;
  if (usertype && !!allowedUser) return <Outlet />;
  return <NoMatch />;
};

export default UserGate;
