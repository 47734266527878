import { endOfDay, startOfDay } from "date-fns";
import { FormEvent } from "react";

export function convertToEncodedURL(obj: { [key: string]: any }) {
  let formBody = [];
  for (const property in obj) {
    if (typeof obj[property] === "undefined") continue;
    const key = encodeURIComponent(property);
    const value = encodeURIComponent(obj[property]);
    formBody.push(`${key}=${value}`);
  }
  return formBody.join("&");
}

export const postSomethingToRN = (str?: string) => {
  // @ts-ignore
  window.ReactNativeWebView?.postMessage(str);
};

type dateParam<D> = {
  startDate: D;
  endDate: D;
};

export const dateIsInRange = (
  date: Date | string,
  { startDate, endDate }: dateParam<string> | dateParam<Date>
): boolean => {
  let usedDate = new Date(date);
  let usedStart = startOfDay(new Date(startDate));
  let usedEnd = endOfDay(new Date(endDate));

  let dateIsBiggerThanStart = usedStart.getTime() <= usedDate.getTime();
  let dateIsSmallerThanEnd = usedEnd.getTime() >= usedDate.getTime();

  return dateIsBiggerThanStart && dateIsSmallerThanEnd;
};

export const replaceInputTextLicensePlate = (
  e: FormEvent<HTMLInputElement>
) => {
  e.currentTarget.value =
    "" +
    e.currentTarget.value
      .toUpperCase()
      .replace(/[ !@#$%^&*()_+\-=\[\]{};':"~`\\|,.<>\/?]/g, "");
  return e.currentTarget.value;
};
export const replaceInputTextContainer = (e: FormEvent<HTMLInputElement>) => {
  e.currentTarget.value =
    "" +
    e.currentTarget.value
      .toUpperCase()
      .replace(/[!@#$%^&*()_+\-=\[\]{};':"~`\\|,.<>\/?]/g, "");
  return e.currentTarget.value;
};

export const replaceInputTextToNumberOnly = (
  e: FormEvent<HTMLInputElement>
) => {
  e.currentTarget.value = "" + e.currentTarget.value.replace(/\D/g, "");
  return e.currentTarget.value;
};
