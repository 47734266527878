import { COLORS } from "../../../assets/theme";
import { BackendDataShape } from "../../../types";
import {
  AUpdateWarehouseAllReqObj,
  AWarehouse,
} from "../../../types/channel/warehouse";
import { channelstoreAPI } from "../channelstoreAPI";

export const warehouseStoreAPI = channelstoreAPI.injectEndpoints({
  endpoints: (builder) => {
    return {
      getWarehouseAll: builder.query<AWarehouse[], object>({
        query: (obj) => {
          // let params = "";
          // if (obj) {
          //     params = convertToEncodedURL(obj);
          //     params = params.length ? "?" + params : "";
          // }
          return {
            url: `/warehouse/all`,
            method: "GET",
          };
        },
        providesTags: ["WarehouseList"],
        transformResponse: (res: BackendDataShape<AWarehouse[]>) => {
          // const colors = [
          //   COLORS.orange_1,
          //   COLORS.purple,
          //   COLORS.green,
          //   COLORS.brown,
          //   COLORS.green_2,
          //   COLORS.yellow_1_rgb,
          //   COLORS.blue_2_rgb,
          //   COLORS.blue_3_rgb,
          //   COLORS.green_3,
          //   COLORS.purple_1_rgb,
          //   COLORS.green_3_rgb,
          // ];
          return res.data;
        },
      }),
      getWarehouse: builder.query<AWarehouse[], { channelId: number }>({
        query: ({ channelId }) => {
          // let params = "";
          // if (obj) {
          //     params = convertToEncodedURL(obj);
          //     params = params.length ? "?" + params : "";
          // }
          return {
            url: `/${channelId}/warehouse`,
            method: "GET",
          };
        },
        providesTags: ["WarehouseList"],
        transformResponse: (res: BackendDataShape<AWarehouse[]>) => {
          // const colors = [
          //   COLORS.orange_1,
          //   COLORS.purple,
          //   COLORS.green,
          //   COLORS.brown,
          //   COLORS.green_2,
          //   COLORS.yellow_1_rgb,
          //   COLORS.blue_2_rgb,
          //   COLORS.blue_3_rgb,
          //   COLORS.green_3,
          //   COLORS.purple_1_rgb,
          //   COLORS.green_3_rgb,
          // ];
          return res.data;
        },
      }),
      createWarehouse: builder.mutation<
        unknown,
        { channelId: number; data: Array<Partial<AWarehouse>> }
      >({
        query: ({ channelId, data }) => {
          return {
            url: `/${channelId}/warehouse`,
            body: data,
            method: "POST",
          };
        },
        invalidatesTags: ["WarehouseList"],
      }),
      updateWarehouse: builder.mutation<
        unknown,
        { channelId: number; data: Array<Partial<AWarehouse>> }
      >({
        query: ({ channelId, data }) => {
          return {
            url: `/${channelId}/warehouse`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["WarehouseList"],
      }),
      updateWarehouseAll: builder.mutation<
        unknown,
        Partial<AUpdateWarehouseAllReqObj>[]
      >({
        query: (obj) => {
          return {
            url: `/warehouse/all`,
            body: obj,
            method: "PATCH",
          };
        },
        invalidatesTags: ["WarehouseList"],
      }),
      deleteWarehouse: builder.mutation<
        BackendDataShape<string>,
        {
          /**
           * company Id
           */
          channelId: number;
          warehouseId: number;
        }
      >({
        query: ({ channelId, warehouseId }) => {
          return {
            url: `/${channelId}/warehouse/${warehouseId}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["WarehouseList"],
      }),
    };
  },
});

export const {
  useGetWarehouseQuery,
  useGetWarehouseAllQuery,
  useCreateWarehouseMutation,
  useUpdateWarehouseMutation,
  useUpdateWarehouseAllMutation,
  useDeleteWarehouseMutation,
  util: { resetApiState: resetWarehouseAPI },
} = warehouseStoreAPI;
