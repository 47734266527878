import axios from "axios";
import { convertToEncodedURL } from "../../tools";
import CookieMonster from "../../tools/CookieMonster";
import { BackendDataShape, CREDLINKS, SYNCLINKS } from "../../types";
import { LoginObj, LoginRes } from "../../types/user";

// export
export function syncVendor(obj?: object) {
  let params = "";
  if (obj) {
    params = convertToEncodedURL(obj);
    params = params.length ? "?" + params : "";
  }

  const url = `${SYNCLINKS.vendor}`;
  const cookie = new CookieMonster();
  return axios.get<BackendDataShape<string>>(url, {
    headers: {
      Authorization: `Bearer ${cookie.loadCookie("jwt")}`,
    },
  });
}
export function syncTruck(obj?: object) {
  let params = "";
  if (obj) {
    params = convertToEncodedURL(obj);
    params = params.length ? "?" + params : "";
  }

  const url = `${SYNCLINKS.truck}`;
  const cookie = new CookieMonster();
  return axios.get<BackendDataShape<string>>(url, {
    headers: {
      Authorization: `Bearer ${cookie.loadCookie("jwt")}`,
    },
  });
}
export function syncTruckBlackList(obj?: object) {
  let params = "";
  if (obj) {
    params = convertToEncodedURL(obj);
    params = params.length ? "?" + params : "";
  }

  const url = `${SYNCLINKS.truckblacklist}`;
  const cookie = new CookieMonster();
  return axios.get<BackendDataShape<string>>(url, {
    headers: {
      Authorization: `Bearer ${cookie.loadCookie("jwt")}`,
    },
  });
}
export function syncContainer(obj?: object) {
  let params = "";
  if (obj) {
    params = convertToEncodedURL(obj);
    params = params.length ? "?" + params : "";
  }

  const url = `${SYNCLINKS.container}`;
  const cookie = new CookieMonster();
  return axios.get<BackendDataShape<string>>(url, {
    headers: {
      Authorization: `Bearer ${cookie.loadCookie("jwt")}`,
    },
  });
}
export function syncPo(obj?: object) {
  let params = "";
  if (obj) {
    params = convertToEncodedURL(obj);
    params = params.length ? "?" + params : "";
  }

  const url = `${SYNCLINKS.po}`;
  const cookie = new CookieMonster();
  return axios.get<BackendDataShape<string>>(url, {
    headers: {
      Authorization: `Bearer ${cookie.loadCookie("jwt")}`,
    },
  });
}
