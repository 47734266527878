import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppCookies } from ".";
import { ROUTES } from "../../config/nav";
import { KnownCookieNames } from "../../tools/CookieMonster";

const useJWT = (): string | undefined => {
  // const [] = useCookies<KnownCookieNames>(["jwt"])
  const [{ jwt }] = useAppCookies(["jwt"]);
  // const jwt: string | undefined = CookieMonster.loadCookie("jwt");
  return jwt;
};

export const useJWTwithSideEffect = () => {
  const jwt = useJWT();
  const navigate = useNavigate();
  const location = useLocation();
  if (!jwt) {
    navigate(ROUTES.loginpage, {
      state: {
        from: location.pathname,
      },
    });
    return "";
  } else {
    return jwt;
  }
};

export default useJWT;
