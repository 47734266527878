import React, { useMemo } from "react";
import { IoIosCalendar } from "react-icons/io";
import { Card, CardBody } from "reactstrap";
import { format, parse } from "date-fns";
import { useNavigate, useSearchParams } from "react-router-dom";
import { id } from "date-fns/locale";
import { COLORS } from "../assets/theme";
import PageContainer from "../components/PageContainer/PageContainer";
import useUsertype from "../hooks/auth/useUsertype";
import { ROUTES } from "../config/nav";
import Header from "../components/atoms/Header";
import {
  useCheckAvailableQuotabyIdMutation,
  useGetAvailableQuotaQuery,
} from "../redux/ScheduleStore/schedulestoreAPI";
import LoadingItem from "../components/atoms/LoadingContainer/LoadingItem";
import useUserChannelId from "../hooks/auth/useUserChanneld";
import { ReservationPageState } from "../types/reservation/reservationPageState";
import { normalDateFormat } from "../types/dateformat";
import useListContainerStyles from "../hooks/styles/useListContainerStyles";
import useTagButtonStyles from "../components/atoms/TagButton/useTagButtonStyles";
import useChannelOptions from "../hooks/channels/useChannelOptions";
import CookieMonster from "../tools/CookieMonster";
import enLocale from "date-fns/locale/en-US";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import {
  MobileDateTimePicker,
  LocalizationProvider,
} from "@material-ui/pickers";
import {
  ButtonBase,
  createTheme,
  makeStyles,
  ThemeProvider,
  styled,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { ASchedule, AvailableQuotaReqObj } from "../types/schedule";
import { BackendDataShape } from "../types";

interface Props {}

const StyledLineSeparator = styled("div")({
  borderTop: "1px solid " + COLORS.gray_1,
});

const useStyles = makeStyles(() => {
  return {
    container: {
      height: "100vh",

      display: "flex",
      flexDirection: "column",
    },
    headerContainer: {
      width: "100%",
    },
    headerSubContainer: {
      flexDirection: "row",
      display: "flex",
      marginBottom: "5vh",
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
    chipsContainer: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
      height: "25px",
      marginRight: "1rem",
      borderRadius: "15px",
      fontFamily: "Raleway",
      fontSize: "8px",
      "&.pick": {
        backgroundColor: COLORS.blue_1,
        border: 0,
        color: "white",
      },
      "&.unpick": {
        backgroundColor: "transparent",
        border: 1,
        borderStyle: "solid",
        borderColor: COLORS.gray,
        color: "black",
      },
    },
    scheduleListContainer: {
      marginTop: "1.5rem",
      flex: 1,
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
    },
    scheduleBtn: {
      height: 56,
      width: "100%",
      marginBottom: "2vh",
      justifyContent: "center",
      alignItems: "center",
      fontFamily: "Raleway",
      fontSize: 16,
      fontWeight: 600,
      borderRadius: "1rem",
      boxSizing: "border-box",
      background: COLORS.gradientBlue_1,
      // borderWidth: 1,
      // borderStyle: "solid",
      // borderColor: COLORS.gray_1,
      color: COLORS.white,
      "&.Mui-disabled": {
        background: COLORS.gradientGray_1,
        border: 0,
        color: COLORS.gray_2,
        cursor: "not-allowed",
      },
      "&.unpick": {},
      "&:hover": {
        borderWidth: 2,
      },
    },
    pickedDateContainer: {
      borderRadius: "1rem",
      marginBottom: "1rem",
    },
    textTitleHeader: {
      color: "black",
      fontFamily: "Raleway",
      fontWeight: 600,
      marginBottom: 0,
      fontSize: "15px",
    },
    dateContainer: {
      flex: 1,
      display: "flex",
      border: 0,
      color: "black",
      fontFamily: "Raleway",
      fontWeight: 600,
      fontSize: "15px",
    },
  };
});

export const datePickertheme = createTheme({
  typography: {
    fontFamily: "Raleway",
    fontSize: 18,
  },
});

const cookies = new CookieMonster();

const PickSchedulePage: React.FunctionComponent<Props> = () => {
  const classes = useStyles();
  const list_classes = useListContainerStyles();
  const tag_classes = useTagButtonStyles();
  const navigate = useNavigate();
  const [showDateModal, setShowDateModal] = React.useState<boolean>(false);
  const usertype = useUsertype();
  const userChannelId = useUserChannelId();
  const [params, setParams] = useSearchParams();
  const [isLoadingCheckQuota, setIsLoadingCheckQuota] =
    React.useState<boolean>(false);
  const paramId = params.get("code");
  const optId = paramId ? parseInt(paramId) : undefined;
  const paramDate = params.get("date");
  const selectedDate = paramDate
    ? parse(paramDate, "yyyy-MM-dd", new Date())
    : new Date();

  const { arr: channelOpts, isLoading: isLoadingOpt } = useChannelOptions(
    { active: true },
    {
      skip: usertype !== "officer",
    }
  );

  const pickedChannelId = React.useMemo<number | undefined>(() => {
    switch (usertype) {
      case "officer":
        return optId ? optId : channelOpts[0]?.id;
      case "user":
      default:
        return userChannelId;
    }
  }, [userChannelId, usertype, optId, channelOpts]);

  const {
    data: quotaList,
    isLoading: isLoadingList,
    isFetching: isFetchingList,
  } = useGetAvailableQuotaQuery(
    {
      date: format(selectedDate, "yyyy-MM-dd"),
      channelId: pickedChannelId,
    },
    {
      skip: typeof pickedChannelId !== "number",
    }
  );

  const [checkQuotaAvailable] = useCheckAvailableQuotabyIdMutation();

  const handleCheckQuotaAvailable = React.useCallback(
    (scheduleId: number, state: ReservationPageState) => {
      if (!selectedDate) return;
      setIsLoadingCheckQuota(true);
      const selectedDateFormat = format(selectedDate, "yyyy-MM-dd");
      const data: AvailableQuotaReqObj = {
        scheduleId,
        date: selectedDateFormat,
        channelId: pickedChannelId,
      };
      checkQuotaAvailable(data)
        .unwrap()
        .then((res) => {
          setIsLoadingCheckQuota(false);
          // const isResponseTrue =
          //   typeof res.data === "boolean" && res.data === true;
          if (!paramId && usertype === "officer") {
            toast.error("Silahkan pilih channel terlebih dahulu.");
          } else {
            switch (usertype) {
              case "user":
                navigate(`${ROUTES.user}/formreservation`, {
                  state,
                });
                break;
              case "officer":
                navigate(`${ROUTES.officer}/formreservation`, {
                  state,
                });
                break;
              default:
                break;
            }
          }
        })
        .catch(() => {
          setIsLoadingCheckQuota(false);
          console.log("Bad Request");
          toast.error("Kuota sudah penuh. Silahkan pilih jadwal lain.");
        });
    },
    [
      pickedChannelId,
      isLoadingCheckQuota,
      pickedChannelId,
      selectedDate,
      paramId,
    ]
  );

  const isLoadingQuota = isLoadingList || isFetchingList || isLoadingCheckQuota;

  const toggleDateModal = (e: Date) => {
    params.set("date", format(e, "yyyy-MM-dd"));
    setParams(params);
    // setShowDateModal(!showDateModal);
  };

  const quotaData = useMemo(() => {
    const retVal: ASchedule[] = [];
    if (!quotaList) return [];
    quotaList.forEach((item) => retVal.push(item));
    return retVal.filter((item) => item.schedulegroupId !== null);
  }, [quotaList]);

  const dateTimeShow = format(selectedDate, normalDateFormat, {
    locale: id,
  });

  return (
    <PageContainer
      headerNavigationContent={
        <div className={list_classes.headerContainer}>
          <Header
            showBackButton={true}
            backButtonOnClick={() => {
              switch (usertype) {
                case "user":
                  navigate(ROUTES.user);
                  break;
                case "officer":
                  navigate(ROUTES.officer);
                  break;
                default:
                  break;
              }
            }}
            title={"Pilih Jadwal Kedatangan"}
          />
        </div>
      }
      headerContent={
        <>
          <Card
            className={classes.pickedDateContainer}
            style={{ marginBottom: 16 }}
          >
            <CardBody
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexBasis: "20px",
                  marginRight: "1vw",
                }}
              >
                <IoIosCalendar size={20} />
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                }}
              >
                <p className={classes.textTitleHeader}>{dateTimeShow}</p>
              </div>
              {/* need refactor */}
              <button
                style={{
                  flexBasis: "20px",
                  display: "flex",
                  backgroundColor: "transparent",
                  border: 0,
                  color: `${COLORS.blue_1}`,
                  fontWeight: 700,
                  letterSpacing: 0.75,
                }}
                onClick={() => setShowDateModal(!showDateModal)}
              >
                UBAH
              </button>
            </CardBody>
          </Card>
          {usertype === "officer" && (
            <>
              <StyledLineSeparator
                style={{ paddingBottom: 16, paddingTop: 8 }}
              />
              <div
                className={tag_classes.tagContainer}
                style={{ padding: "0 0 1rem 0", flexWrap: "nowrap" }}
              >
                {isLoadingOpt ? (
                  <button
                    disabled
                    className={tag_classes.tag}
                    style={{ backgroundColor: COLORS.light_gray_1 }}
                  >
                    Loading Channel...
                  </button>
                ) : channelOpts.length ? (
                  channelOpts
                    .sort((a, b) => a.description.localeCompare(b.description))
                    .map((val) => {
                      return (
                        <ButtonBase
                          key={val.id}
                          className={[
                            list_classes.tag,
                            pickedChannelId && Number(paramId) === val.id
                              ? "active"
                              : undefined,
                          ].join(" ")}
                          onClick={() => {
                            params.set("code", "" + val.id);
                            cookies.setCookie("channelId", val.id);
                            setParams(params);
                          }}
                        >
                          {val.description}
                        </ButtonBase>
                      );
                    })
                ) : (
                  <button
                    disabled
                    className={tag_classes.tag}
                    style={{ backgroundColor: "wheat", color: "black" }}
                  >
                    No Channel Available
                  </button>
                )}
              </div>
            </>
          )}
        </>
      }
      className={list_classes.container}
      withBackground
    >
      <div
        className={list_classes.listContainer}
        style={{ minHeight: "100vh" }}
      >
        <LoadingItem isLoading={isLoadingQuota} />
        {usertype === "officer" && optId === undefined ? (
          <div style={{ textAlign: "center", width: "100%" }}>
            <span>Silahkan pilih channel terlebih dahulu.</span>
          </div>
        ) : quotaData.length > 0 ? (
          quotaData.map((quota) => {
            let isFull = quota.quota <= 0;
            let maxDate = parse(quota.to, "HH:mm:ss", selectedDate);
            let isQuotaActive = !quota.active;
            let isPassedDate = new Date().getTime() > maxDate.getTime();
            const state: ReservationPageState = {
              quota,
              date: selectedDate,
            };
            return (
              <ButtonBase
                key={quota.id}
                title={
                  isPassedDate
                    ? "Reservasi sudah lewat"
                    : isFull
                    ? "Reservasi sudah penuh"
                    : isQuotaActive
                    ? "Jadwal tidak tersedia."
                    : `Pilih Reservasi pada ${dateTimeShow}, ${quota.from} - ${quota.to}}`
                }
                disabled={
                  isFull || isPassedDate || isLoadingQuota || isQuotaActive
                }
                className={[classes.scheduleBtn].join(" ")}
                onClick={() => {
                  handleCheckQuotaAvailable(quota.id, state);
                }}
              >
                {quota.from} - {quota.to}
              </ButtonBase>
            );
          })
        ) : (
          !isLoadingQuota &&
          quotaData.length === 0 && (
            <div style={{ textAlign: "center", width: "100%" }}>
              <span>
                Jadwal tidak tersedia. Silahkan{" "}
                <span style={{ fontWeight: 600 }}>pilih hari lain</span> atau
                hubungi <span style={{ fontWeight: 600 }}>administrator</span>.
              </span>
            </div>
          )
        )}
      </div>
      <LocalizationProvider dateAdapter={DateFnsAdapter} locale={enLocale}>
        <ThemeProvider theme={datePickertheme}>
          <MobileDateTimePicker
            value={params.get("date")}
            onChange={(e) => toggleDateModal(new Date(e!))}
            open={showDateModal}
            renderInput={() => <></>}
            disablePast
            showToolbar={false}
            onClose={() => {
              setShowDateModal(!showDateModal);
            }}
            views={["month", "year", "date"]}
          />
        </ThemeProvider>
      </LocalizationProvider>
    </PageContainer>
  );
};

export default PickSchedulePage;
