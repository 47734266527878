import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AReservationResultObj } from "../../types/reservation";

export interface ReservationState {
  reservationArr: AReservationResultObj[];
}

const initialState: ReservationState = {
  reservationArr: [],
};

export const reservationSlice = createSlice({
  name: "RESERVATION_SLICE",
  initialState,
  reducers: {
    storeReservationData: (
      state,
      payload: PayloadAction<AReservationResultObj[]>
    ) => {
      state.reservationArr = payload.payload;
    },
    //   setChannelColors: (state, action: PayloadAction<AChannel[]>) => {
    //     const { payload } = action;
    //     state.channelData = payload.map((val) => {
    //       return {
    //         ...val,
    //         color: "blue",
    //       };
    //     });
    //   },
  },
});

export default reservationSlice.reducer;
export const { storeReservationData } = reservationSlice.actions;
